import React, { useEffect, useState } from 'react';

interface SVGBirthCompatibleBackImgProps { sendA: string; sendB: string; sendC: string; sendD: string; sendE: string; sendF: string; sendArrow: string; }

interface NinestarCompatible {
    date: string;
    kyuusei_y: string;
    kyuusei_m: string;
    xxkyuusei: string;
}

type compatibleStarType = Record<number, number>;
export const compatibleStar = {
    "15": 9,
    "14": 8,
    "13": 8,
    "12": 7,
    "11": 7,
    "10": 7,
    "9": 7,
    "8": 6,
    "7": 6,
    "6": 6,
    "5": 6,
    "4": 6,
    "3": 5,
    "2": 5,
    "1": 5,
    "0": 5,
    "-1": 5,
    "-2": 5,
    "-3": 5,
    "-4": 4,
    "-5": 4,
    "-6": 4,
    "-7": 4,
    "-8": 4,
    "-9": 3,
    "-10": 3,
    "-11": 3,
    "-12": 3,
    "-13": 2,
    "-14": 2,
    "-15": 1,
} as compatibleStarType;

type compatibleStarColorType = Record<string, string>;
export const compatibleStarColor = {
    "10": "#5798ff",
    "9": "#5798ff",
    "8": "#5798ff",
    "7": "#5798ff",
    "6": "#5798ff",
    "5": "#5798ff",
    "4": "#ab07de",
    "3": "#ab07de",
    "2": "#ab07de",
    "1": "#ab07de",
    "0": "#ab07de",
} as compatibleStarColorType;

const todayY = new Date().getFullYear();
const todayM = new Date().getMonth() + 1 // JavaScriptの月は0から始まるので、1を足して調整
const todayD = new Date().getDate();

const SvgBCIPointAX = 150;
const SvgBCIPointAY = 40;
const SvgBCIPointBX = 250;
const SvgBCIPointBY = 100;
const SvgBCIPointCX = 250;
const SvgBCIPointCY = 200;
const SvgBCIPointDX = 150;
const SvgBCIPointDY = 260;
const SvgBCIPointEX = 50;
const SvgBCIPointEY = 200;
const SvgBCIPointFX = 50;
const SvgBCIPointFY = 100;



const kyuseiInteractions: { [key: string]: number } = {};

// 一白水星からみた影響数   $var_dat["kyusei_interactions"][0] = array ("3","-5","3",  "3","-5","5",  "5","-5","-3");

kyuseiInteractions["0-0"] = 3;
kyuseiInteractions["0-1"] = -5;
kyuseiInteractions["0-2"] = 3;
kyuseiInteractions["0-3"] = 3;
kyuseiInteractions["0-4"] = -5;
kyuseiInteractions["0-5"] = 5;
kyuseiInteractions["0-6"] = 5;
kyuseiInteractions["0-7"] = -5;
kyuseiInteractions["0-8"] = -3;

// 二黒土星からみた影響数   $var_dat["kyusei_interactions"][1] = array ("-3","3","-5",　"-5","3","3",　"3","3","5");

kyuseiInteractions["1-0"] = -3;
kyuseiInteractions["1-1"] = 3;
kyuseiInteractions["1-2"] = -5;
kyuseiInteractions["1-3"] = -5;
kyuseiInteractions["1-4"] = 3;
kyuseiInteractions["1-5"] = 3;
kyuseiInteractions["1-6"] = 3;
kyuseiInteractions["1-7"] = 3;
kyuseiInteractions["1-8"] = 5;

// 三碧木星からみた影響数   $var_dat["kyusei_interactions"][2] = array ("5","-3","3",　"3","-3","-5",　"-5","-3","3");

kyuseiInteractions["2-0"] = 5;
kyuseiInteractions["2-1"] = -3;
kyuseiInteractions["2-2"] = 3;
kyuseiInteractions["2-3"] = 3;
kyuseiInteractions["2-4"] = -3;
kyuseiInteractions["2-5"] = -5;
kyuseiInteractions["2-6"] = -5;
kyuseiInteractions["2-7"] = -3;
kyuseiInteractions["2-8"] = 3;

// 四緑木星からみた影響数   $var_dat["kyusei_interactions"][3] = array ("5","-3","3",　"3","-3","-5",　"-5","-3","3");

kyuseiInteractions["3-0"] = 5;
kyuseiInteractions["3-1"] = -3;
kyuseiInteractions["3-2"] = 3;
kyuseiInteractions["3-3"] = 3;
kyuseiInteractions["3-4"] = -3;
kyuseiInteractions["3-5"] = -5;
kyuseiInteractions["3-6"] = -5;
kyuseiInteractions["3-7"] = -3;
kyuseiInteractions["3-8"] = 3;

// 五黄土星からみた影響数   $var_dat["kyusei_interactions"][4] = array ("-3","3","-5",　"-5","3","3",　"3","3","5");

kyuseiInteractions["4-0"] = -3;
kyuseiInteractions["4-1"] = 3;
kyuseiInteractions["4-2"] = -5;
kyuseiInteractions["4-3"] = -5;
kyuseiInteractions["4-4"] = 3;
kyuseiInteractions["4-5"] = 3;
kyuseiInteractions["4-6"] = 3;
kyuseiInteractions["4-7"] = 3;
kyuseiInteractions["4-8"] = 5;

// 六白金星からみた影響数   $var_dat["kyusei_interactions"][5] = array ("3","5","-3",　"-3","5","3",　"3","5","-5");

kyuseiInteractions["5-0"] = 3;
kyuseiInteractions["5-1"] = 5;
kyuseiInteractions["5-2"] = -3;
kyuseiInteractions["5-3"] = -3;
kyuseiInteractions["5-4"] = 5;
kyuseiInteractions["5-5"] = 3;
kyuseiInteractions["5-6"] = 3;
kyuseiInteractions["5-7"] = 5;
kyuseiInteractions["5-8"] = -5;

// 七赤金星からみた影響数   $var_dat["kyusei_interactions"][6] = array ("3","5","-3",　"-3","5","3",　"3","5","-5");

kyuseiInteractions["6-0"] = 3;
kyuseiInteractions["6-1"] = 5;
kyuseiInteractions["6-2"] = -3;
kyuseiInteractions["6-3"] = -3;
kyuseiInteractions["6-4"] = 5;
kyuseiInteractions["6-5"] = 3;
kyuseiInteractions["6-6"] = 3;
kyuseiInteractions["6-7"] = 5;
kyuseiInteractions["6-8"] = -5;

// 八白土星からみた影響数   $var_dat["kyusei_interactions"][7] = array ("-3","3","-5",　"-5","3","3",　"3","3","5");

kyuseiInteractions["7-0"] = -3;
kyuseiInteractions["7-1"] = 3;
kyuseiInteractions["7-2"] = -5;
kyuseiInteractions["7-3"] = -5;
kyuseiInteractions["7-4"] = 3;
kyuseiInteractions["7-5"] = 3;
kyuseiInteractions["7-6"] = 3;
kyuseiInteractions["7-7"] = 3;
kyuseiInteractions["7-8"] = 5;

// 九紫火星からみた影響数   $var_dat["kyusei_interactions"][8] = array ("-5","3","5",　"5","3","-3",　"-3","3","3");

kyuseiInteractions["8-0"] = -5;
kyuseiInteractions["8-1"] = 3;
kyuseiInteractions["8-2"] = 5;
kyuseiInteractions["8-3"] = 5;
kyuseiInteractions["8-4"] = 3;
kyuseiInteractions["8-5"] = -3;
kyuseiInteractions["8-6"] = -3;
kyuseiInteractions["8-7"] = 3;
kyuseiInteractions["8-8"] = 3;

// function：年月日から、年齢を算出
function todayAge(birthY: string, birthM: string, birthD: string, todayY: string, todayM: string, todayD: string): string {
    // 年齢を計算
    let age = parseInt(todayY, 10) - parseInt(birthY, 10);
    // 誕生日がまだ来ていない場合は、1引く
    if (parseInt(todayM, 10) < parseInt(birthM, 10) || (parseInt(todayM, 10) === parseInt(birthM, 10) && parseInt(todayD, 10) < parseInt(birthD, 10))) {
        age -= 1;
    }
    // 年齢を文字列として返す
    return String(age);
}


// sendArrow = "in" or "out"

const SVGBirthCompatibleBackImg: React.FC<SVGBirthCompatibleBackImgProps> = ({ sendA, sendB, sendC, sendD, sendE, sendF, sendArrow,
}) => {

    // 変数を配列に入れ直す、nullを避けて、詰める。

    let sends = [sendA, sendB, sendC, sendD, sendE, sendF];                 // すべての変数を配列に入れる
    let filteredSends = sends.filter(s => s !== "");                        // 空文字列でないものだけをフィルタリングして、新しい配列を作る
    while (filteredSends.length < sends.length) { filteredSends.push(""); } // 空文字列で埋めて、元の配列の長さにする
    [sendA, sendB, sendC, sendD, sendE, sendF] = filteredSends;             // 新しい配列の値をもとの変数に再割り当てする

    if (sendA === undefined) { sendA = ""; }
    if (sendB === undefined) { sendB = ""; }
    if (sendC === undefined) { sendC = ""; }
    if (sendD === undefined) { sendD = ""; }
    if (sendE === undefined) { sendE = ""; }
    if (sendF === undefined) { sendF = ""; }

    // 数字以外を削除 1972-01-01 -> 19720101

    sendA = sendA.replace(/\D/g, '');
    sendB = sendB.replace(/\D/g, '');
    sendC = sendC.replace(/\D/g, '');
    sendD = sendD.replace(/\D/g, '');
    sendE = sendE.replace(/\D/g, '');
    sendF = sendF.replace(/\D/g, '');



    // 各日の、日九星・月九星・年日九星の呼び題

    //   { "date": "19010102", "kyuusei_y": "0", "kyuusei_m": "6", "xxkyuusei": "7" },
    const SvgBCI_json_url = "https://db.suureki.net/_db_9star/9star_all.json";
    const [NinestarCompatible_data, setDataNinestarCompatible] = useState<NinestarCompatible[]>([]);
    useEffect(() => {
        fetch(SvgBCI_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(NinestarCompatible_data => setDataNinestarCompatible(NinestarCompatible_data))
            .catch(error => console.error('Error:', error));
    });
    // JSONデータを日付をキーとするオブジェクトに変換します。
    let kyuusei_y: { [date: string]: string } = {};
    let kyuusei_m: { [date: string]: string } = {};
    let kyuusei_d: { [date: string]: string } = {};
    NinestarCompatible_data.forEach(ninestarCompatible => {
        kyuusei_y[ninestarCompatible.date] = ninestarCompatible.kyuusei_y;
        kyuusei_m[ninestarCompatible.date] = ninestarCompatible.kyuusei_m;
        kyuusei_d[ninestarCompatible.date] = ninestarCompatible.xxkyuusei;
    }, [sendA]);

    // console.log(kyuusei_m["20220202"])

    // 有効変数のカウント


    let SvgBCIUserCount = 0;
    if (sendA !== "") { SvgBCIUserCount++; }
    if (sendB !== "") { SvgBCIUserCount++; }
    if (sendC !== "") { SvgBCIUserCount++; }
    if (sendD !== "") { SvgBCIUserCount++; }
    if (sendE !== "") { SvgBCIUserCount++; }
    if (sendF !== "") { SvgBCIUserCount++; }

    // スコア算出

    let compatibleScoreAB = 0;
    let compatibleScoreAC = 0;
    let compatibleScoreAD = 0;
    let compatibleScoreAE = 0;
    let compatibleScoreAF = 0;

    let compatibleScoreBA = 0;
    let compatibleScoreCA = 0;
    let compatibleScoreDA = 0;
    let compatibleScoreEA = 0;
    let compatibleScoreFA = 0;

    let compatibleStarAB = 0;
    let compatibleStarAC = 0;
    let compatibleStarAD = 0;
    let compatibleStarAE = 0;
    let compatibleStarAF = 0;

    let compatibleStarBA = 0;
    let compatibleStarCA = 0;
    let compatibleStarDA = 0;
    let compatibleStarEA = 0;
    let compatibleStarFA = 0;

    let tmpCompatibleKey = "";

    if (sendA !== "" && sendB !== "") {
        // sendA -> sendB　：年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendA] + "-" + kyuusei_y[sendB]; compatibleScoreAB += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendA] + "-" + kyuusei_m[sendB]; compatibleScoreAB += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendA] + "-" + kyuusei_d[sendB]; compatibleScoreAB += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarAB = compatibleStar[compatibleScoreAB];

        // sendB -> sendA　：年年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendB] + "-" + kyuusei_y[sendA]; compatibleScoreBA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendB] + "-" + kyuusei_m[sendA]; compatibleScoreBA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendB] + "-" + kyuusei_d[sendA]; compatibleScoreBA += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarBA = compatibleStar[compatibleScoreBA];
    }

    if (sendA !== "" && sendC !== "") {
        // sendA -> sendC　：年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendA] + "-" + kyuusei_y[sendC]; compatibleScoreAC += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendA] + "-" + kyuusei_m[sendC]; compatibleScoreAC += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendA] + "-" + kyuusei_d[sendC]; compatibleScoreAC += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarAC = compatibleStar[compatibleScoreAC];

        // sendC -> sendA　：年年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendC] + "-" + kyuusei_y[sendA]; compatibleScoreCA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendC] + "-" + kyuusei_m[sendA]; compatibleScoreCA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendC] + "-" + kyuusei_d[sendA]; compatibleScoreCA += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarCA = compatibleStar[compatibleScoreCA];
    }

    if (sendA !== "" && sendD !== "") {
        // sendA -> sendD　：年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendA] + "-" + kyuusei_y[sendD]; compatibleScoreAD += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendA] + "-" + kyuusei_m[sendD]; compatibleScoreAD += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendA] + "-" + kyuusei_d[sendD]; compatibleScoreAD += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarAD = compatibleStar[compatibleScoreAD];

        // sendD -> sendA　：年年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendD] + "-" + kyuusei_y[sendA]; compatibleScoreDA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendD] + "-" + kyuusei_m[sendA]; compatibleScoreDA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendD] + "-" + kyuusei_d[sendA]; compatibleScoreDA += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarDA = compatibleStar[compatibleScoreDA];
    }

    if (sendA !== "" && sendE !== "") {
        // sendA -> send　：年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendA] + "-" + kyuusei_y[sendE]; compatibleScoreAE += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendA] + "-" + kyuusei_m[sendE]; compatibleScoreAE += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendA] + "-" + kyuusei_d[sendE]; compatibleScoreAE += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarAE = compatibleStar[compatibleScoreAE];

        // sendE -> sendA　：年年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendE] + "-" + kyuusei_y[sendA]; compatibleScoreEA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendE] + "-" + kyuusei_m[sendA]; compatibleScoreEA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendE] + "-" + kyuusei_d[sendA]; compatibleScoreEA += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarEA = compatibleStar[compatibleScoreEA];
    }

    if (sendA !== "" && sendF !== "") {
        // sendA -> sendF　：年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendA] + "-" + kyuusei_y[sendF]; compatibleScoreAF += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendA] + "-" + kyuusei_m[sendF]; compatibleScoreAF += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendA] + "-" + kyuusei_d[sendF]; compatibleScoreAF += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarAF = compatibleStar[compatibleScoreAF];

        // sendF -> sendA　：年年暦・月暦・日暦
        tmpCompatibleKey = kyuusei_y[sendF] + "-" + kyuusei_y[sendA]; compatibleScoreFA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_m[sendF] + "-" + kyuusei_m[sendA]; compatibleScoreFA += kyuseiInteractions[tmpCompatibleKey];
        tmpCompatibleKey = kyuusei_d[sendF] + "-" + kyuusei_d[sendA]; compatibleScoreFA += kyuseiInteractions[tmpCompatibleKey];
        compatibleStarFA = compatibleStar[compatibleScoreFA];
    }



    // エリア毎に、生年月日の割当

    let SvgBCIAreaUserA = sendA;
    let SvgBCIAreaUserB = "";
    let SvgBCIAreaUserC = "";
    let SvgBCIAreaUserD = "";
    let SvgBCIAreaUserE = "";
    let SvgBCIAreaUserF = "";

    let SvgBCIAreaLineAB = "-";
    let SvgBCIAreaLineAC = "-";
    let SvgBCIAreaLineAD = "-";
    let SvgBCIAreaLineAE = "-";
    let SvgBCIAreaLineAF = "-";

    let SvgBCIAreaLineBA = "-";
    let SvgBCIAreaLineCA = "-";
    let SvgBCIAreaLineDA = "-";
    let SvgBCIAreaLineEA = "-";
    let SvgBCIAreaLineFA = "-";

    if (SvgBCIUserCount === 2) {
        SvgBCIAreaUserD = sendB;
        SvgBCIAreaLineAD = String(compatibleStarAB);    // sendA -> sendB
        SvgBCIAreaLineDA = String(compatibleStarBA);    // sendB -> sendA
    }
    if (SvgBCIUserCount === 3) {
        SvgBCIAreaUserC = sendB;
        SvgBCIAreaUserE = sendC;
        SvgBCIAreaLineAC = String(compatibleStarAB);    // sendA -> sendB
        SvgBCIAreaLineAE = String(compatibleStarAC);    // sendA -> sendC
        SvgBCIAreaLineCA = String(compatibleStarBA);    // sendB -> sendA
        SvgBCIAreaLineEA = String(compatibleStarCA);    // sendC -> sendA
    }
    if (SvgBCIUserCount === 4) {
        SvgBCIAreaUserC = sendB;
        SvgBCIAreaUserD = sendC;
        SvgBCIAreaUserE = sendD;
        SvgBCIAreaLineAC = String(compatibleStarAB);    // sendA -> sendB;
        SvgBCIAreaLineAD = String(compatibleStarAC);    // sendA -> sendC;
        SvgBCIAreaLineAE = String(compatibleStarAD);    // sendA -> sendD;
        SvgBCIAreaLineCA = String(compatibleStarBA);    // sendB -> sendA;
        SvgBCIAreaLineDA = String(compatibleStarCA);    // sendC -> sendA;
        SvgBCIAreaLineEA = String(compatibleStarDA);    // sendD -> sendA;
    }
    if (SvgBCIUserCount === 5) {
        SvgBCIAreaUserB = sendB;
        SvgBCIAreaUserC = sendC;
        SvgBCIAreaUserD = sendD;
        SvgBCIAreaUserE = sendE;
        SvgBCIAreaLineAB = String(compatibleStarAB);    // sendA -> sendB;
        SvgBCIAreaLineAC = String(compatibleStarAC);    // sendA -> sendC;
        SvgBCIAreaLineAD = String(compatibleStarAD);    // sendA -> sendD;
        SvgBCIAreaLineAE = String(compatibleStarAE);    // sendA -> sendE;
        SvgBCIAreaLineBA = String(compatibleStarBA);    // sendB -> sendA;
        SvgBCIAreaLineCA = String(compatibleStarCA);    // sendC -> sendA;
        SvgBCIAreaLineDA = String(compatibleStarDA);    // sendD -> sendA;
        SvgBCIAreaLineEA = String(compatibleStarEA);    // sendE -> sendA;
    }
    if (SvgBCIUserCount === 6) {
        SvgBCIAreaUserB = sendB;
        SvgBCIAreaUserC = sendC;
        SvgBCIAreaUserD = sendD;
        SvgBCIAreaUserE = sendE;
        SvgBCIAreaUserF = sendF;
        SvgBCIAreaLineAB = String(compatibleStarAB);    // sendA -> sendB;
        SvgBCIAreaLineAC = String(compatibleStarAC);    // sendA -> sendC;
        SvgBCIAreaLineAD = String(compatibleStarAD);    // sendA -> sendD;
        SvgBCIAreaLineAE = String(compatibleStarAE);    // sendA -> sendE;
        SvgBCIAreaLineAF = String(compatibleStarAF);    // sendA -> sendF;
        SvgBCIAreaLineBA = String(compatibleStarBA);    // sendB -> sendA;
        SvgBCIAreaLineCA = String(compatibleStarCA);    // sendC -> sendA;
        SvgBCIAreaLineDA = String(compatibleStarDA);    // sendD -> sendA;
        SvgBCIAreaLineEA = String(compatibleStarEA);    // sendE -> sendA;
        SvgBCIAreaLineFA = String(compatibleStarFA);    // sendF -> sendA;
    }

    let SvgBCIAreaLineEchoBBB = "";
    let SvgBCIAreaLineEchoCCC = "";
    let SvgBCIAreaLineEchoDDD = "";
    let SvgBCIAreaLineEchoEEE = "";
    let SvgBCIAreaLineEchoFFF = "";


    if (sendArrow === "in") {
        SvgBCIAreaLineEchoBBB = SvgBCIAreaLineBA;
        SvgBCIAreaLineEchoCCC = SvgBCIAreaLineCA;
        SvgBCIAreaLineEchoDDD = SvgBCIAreaLineDA;
        SvgBCIAreaLineEchoEEE = SvgBCIAreaLineEA;
        SvgBCIAreaLineEchoFFF = SvgBCIAreaLineFA;
    } else {
        SvgBCIAreaLineEchoBBB = SvgBCIAreaLineAB;
        SvgBCIAreaLineEchoCCC = SvgBCIAreaLineAC;
        SvgBCIAreaLineEchoDDD = SvgBCIAreaLineAD;
        SvgBCIAreaLineEchoEEE = SvgBCIAreaLineAE;
        SvgBCIAreaLineEchoFFF = SvgBCIAreaLineAF;
    }

    let SvgBCIPointEchoABx1 = SvgBCIPointAX;
    let SvgBCIPointEchoABy1 = SvgBCIPointAY;
    let SvgBCIPointEchoABx2 = SvgBCIPointBX;
    let SvgBCIPointEchoABy2 = SvgBCIPointBY;

    let SvgBCIPointEchoACx1 = SvgBCIPointAX;
    let SvgBCIPointEchoACy1 = SvgBCIPointAY;
    let SvgBCIPointEchoACx2 = SvgBCIPointCX;
    let SvgBCIPointEchoACy2 = SvgBCIPointCY;

    let SvgBCIPointEchoADx1 = SvgBCIPointAX;
    let SvgBCIPointEchoADy1 = SvgBCIPointAY;
    let SvgBCIPointEchoADx2 = SvgBCIPointDX;
    let SvgBCIPointEchoADy2 = SvgBCIPointDY;

    let SvgBCIPointEchoAEx1 = SvgBCIPointAX;
    let SvgBCIPointEchoAEy1 = SvgBCIPointAY;
    let SvgBCIPointEchoAEx2 = SvgBCIPointEX;
    let SvgBCIPointEchoAEy2 = SvgBCIPointEY;

    let SvgBCIPointEchoAFx1 = SvgBCIPointAX;
    let SvgBCIPointEchoAFy1 = SvgBCIPointAY;
    let SvgBCIPointEchoAFx2 = SvgBCIPointFX;
    let SvgBCIPointEchoAFy2 = SvgBCIPointFY;

    if (sendArrow === "in") {
        SvgBCIPointEchoABx1 = SvgBCIPointBX;
        SvgBCIPointEchoABy1 = SvgBCIPointBY;
        SvgBCIPointEchoABx2 = SvgBCIPointAX;
        SvgBCIPointEchoABy2 = SvgBCIPointAY;

        SvgBCIPointEchoACx1 = SvgBCIPointCX;
        SvgBCIPointEchoACy1 = SvgBCIPointCY;
        SvgBCIPointEchoACx2 = SvgBCIPointAX;
        SvgBCIPointEchoACy2 = SvgBCIPointAY;

        SvgBCIPointEchoADx1 = SvgBCIPointDX;
        SvgBCIPointEchoADy1 = SvgBCIPointDY;
        SvgBCIPointEchoADx2 = SvgBCIPointAX;
        SvgBCIPointEchoADy2 = SvgBCIPointAY;

        SvgBCIPointEchoAEx1 = SvgBCIPointEX;
        SvgBCIPointEchoAEy1 = SvgBCIPointEY;
        SvgBCIPointEchoAEx2 = SvgBCIPointAX;
        SvgBCIPointEchoAEy2 = SvgBCIPointAY;

        SvgBCIPointEchoAFx1 = SvgBCIPointFX;
        SvgBCIPointEchoAFy1 = SvgBCIPointFY;
        SvgBCIPointEchoAFx2 = SvgBCIPointAX;
        SvgBCIPointEchoAFy2 = SvgBCIPointAY;
    }



    // エリア毎に割当てた、生年月日のテキスト化

    let SvgBCIUserATxt1 = "";
    let SvgBCIUserATxt2 = "";
    let SvgBCIUserATxt3 = "";
    let SvgBCIUserBTxt1 = "";
    let SvgBCIUserBTxt2 = "";
    let SvgBCIUserBTxt3 = "";
    let SvgBCIUserCTxt1 = "";
    let SvgBCIUserCTxt2 = "";
    let SvgBCIUserCTxt3 = "";
    let SvgBCIUserDTxt1 = "";
    let SvgBCIUserDTxt2 = "";
    let SvgBCIUserDTxt3 = "";
    let SvgBCIUserETxt1 = "";
    let SvgBCIUserETxt2 = "";
    let SvgBCIUserETxt3 = "";
    let SvgBCIUserFTxt1 = "";
    let SvgBCIUserFTxt2 = "";
    let SvgBCIUserFTxt3 = "";

    // ${todayAge(String(birthY), birthM, birthD, todayY, todayM, todayD)}
    let SvgBCIUserAY = "";
    let SvgBCIUserAM = "";
    let SvgBCIUserAD = "";
    if (SvgBCIAreaUserA !== "") {
        SvgBCIUserAY = SvgBCIAreaUserA.substring(0, 4);
        SvgBCIUserAM = SvgBCIAreaUserA.substring(4, 6);
        SvgBCIUserAD = SvgBCIAreaUserA.substring(6, 8);
        SvgBCIUserATxt1 = SvgBCIUserAY + "年";
        SvgBCIUserATxt2 = parseInt(SvgBCIUserAM, 10) + "月" + parseInt(SvgBCIUserAD, 10) + "日生";
        SvgBCIUserATxt3 = todayAge(SvgBCIUserAY, SvgBCIUserAM, SvgBCIUserAD, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    let SvgBCIUserBY = "";
    let SvgBCIUserBM = "";
    let SvgBCIUserBD = "";
    if (SvgBCIAreaUserB !== "") {
        SvgBCIUserBY = SvgBCIAreaUserB.substring(0, 4);
        SvgBCIUserBM = SvgBCIAreaUserB.substring(4, 6);
        SvgBCIUserBD = SvgBCIAreaUserB.substring(6, 8);
        SvgBCIUserBTxt1 = SvgBCIUserBY + "年";
        SvgBCIUserBTxt2 = parseInt(SvgBCIUserBM, 10) + "月" + parseInt(SvgBCIUserBD, 10) + "日生";
        SvgBCIUserBTxt3 = todayAge(SvgBCIUserBY, SvgBCIUserBM, SvgBCIUserBD, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    let SvgBCIUserCY = "";
    let SvgBCIUserCM = "";
    let SvgBCIUserCD = "";
    if (SvgBCIAreaUserC !== "") {
        SvgBCIUserCY = SvgBCIAreaUserC.substring(0, 4);
        SvgBCIUserCM = SvgBCIAreaUserC.substring(4, 6);
        SvgBCIUserCD = SvgBCIAreaUserC.substring(6, 8);
        SvgBCIUserCTxt1 = SvgBCIUserCY + "年";
        SvgBCIUserCTxt2 = parseInt(SvgBCIUserCM, 10) + "月" + parseInt(SvgBCIUserCD, 10) + "日生";
        SvgBCIUserCTxt3 = todayAge(SvgBCIUserCY, SvgBCIUserCM, SvgBCIUserCD, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    let SvgBCIUserDY = "";
    let SvgBCIUserDM = "";
    let SvgBCIUserDD = "";
    if (SvgBCIAreaUserD !== "") {
        SvgBCIUserDY = SvgBCIAreaUserD.substring(0, 4);
        SvgBCIUserDM = SvgBCIAreaUserD.substring(4, 6);
        SvgBCIUserDD = SvgBCIAreaUserD.substring(6, 8);
        SvgBCIUserDTxt1 = SvgBCIUserDY + "年";
        SvgBCIUserDTxt2 = parseInt(SvgBCIUserDM, 10) + "月" + parseInt(SvgBCIUserDD, 10) + "日生";
        SvgBCIUserDTxt3 = todayAge(SvgBCIUserDY, SvgBCIUserDM, SvgBCIUserDD, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    let SvgBCIUserEY = "";
    let SvgBCIUserEM = "";
    let SvgBCIUserED = "";
    if (SvgBCIAreaUserE !== "") {
        SvgBCIUserEY = SvgBCIAreaUserE.substring(0, 4);
        SvgBCIUserEM = SvgBCIAreaUserE.substring(4, 6);
        SvgBCIUserED = SvgBCIAreaUserE.substring(6, 8);
        SvgBCIUserETxt1 = SvgBCIUserEY + "年";
        SvgBCIUserETxt2 = parseInt(SvgBCIUserEM, 10) + "月" + parseInt(SvgBCIUserED, 10) + "日生";
        SvgBCIUserETxt3 = todayAge(SvgBCIUserEY, SvgBCIUserEM, SvgBCIUserED, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    let SvgBCIUserFY = "";
    let SvgBCIUserFM = "";
    let SvgBCIUserFD = "";
    if (SvgBCIAreaUserF !== "") {
        SvgBCIUserFY = SvgBCIAreaUserF.substring(0, 4);
        SvgBCIUserFM = SvgBCIAreaUserF.substring(4, 6);
        SvgBCIUserFD = SvgBCIAreaUserF.substring(6, 8);
        SvgBCIUserFTxt1 = SvgBCIUserFY + "年";
        SvgBCIUserFTxt2 = parseInt(SvgBCIUserFM, 10) + "月" + parseInt(SvgBCIUserFD, 10) + "日生";
        SvgBCIUserFTxt3 = todayAge(SvgBCIUserFY, SvgBCIUserFM, SvgBCIUserFD, String(todayY), String(todayM), String(todayD)) + "歳";
    }

    // エリア毎（各枠）の、表示と非表示（透明化）

    let SvgBCIAreaOpacityA = 1;
    let SvgBCIAreaOpacityB = 1;
    let SvgBCIAreaOpacityC = 1;
    let SvgBCIAreaOpacityD = 1;
    let SvgBCIAreaOpacityE = 1;
    let SvgBCIAreaOpacityF = 1;

    let SvgBCIAreaOpacityTxtA = "url(#gradientSmall)";
    let SvgBCIAreaOpacityTxtB = "url(#gradientSmall)";
    let SvgBCIAreaOpacityTxtC = "url(#gradientSmall)";
    let SvgBCIAreaOpacityTxtD = "url(#gradientSmall)";
    let SvgBCIAreaOpacityTxtE = "url(#gradientSmall)";
    let SvgBCIAreaOpacityTxtF = "url(#gradientSmall)";

    if (SvgBCIAreaUserA === "") { SvgBCIAreaOpacityA = 0; SvgBCIAreaOpacityTxtA = "url(#gradientSmallClear)"; }
    if (SvgBCIAreaUserB === "") { SvgBCIAreaOpacityB = 0; SvgBCIAreaOpacityTxtB = "url(#gradientSmallClear)"; }
    if (SvgBCIAreaUserC === "") { SvgBCIAreaOpacityC = 0; SvgBCIAreaOpacityTxtC = "url(#gradientSmallClear)"; }
    if (SvgBCIAreaUserD === "") { SvgBCIAreaOpacityD = 0; SvgBCIAreaOpacityTxtD = "url(#gradientSmallClear)"; }
    if (SvgBCIAreaUserE === "") { SvgBCIAreaOpacityE = 0; SvgBCIAreaOpacityTxtE = "url(#gradientSmallClear)"; }
    if (SvgBCIAreaUserF === "") { SvgBCIAreaOpacityF = 0; SvgBCIAreaOpacityTxtF = "url(#gradientSmallClear)"; }

    // A基準：Aエリアと他エリアの線の、表示と非表示（透明化）

    let SvgBCIAreaOpacityAB = 1;
    let SvgBCIAreaOpacityAC = 1;
    let SvgBCIAreaOpacityAD = 1;
    let SvgBCIAreaOpacityAE = 1;
    let SvgBCIAreaOpacityAF = 1;

    let SvgBCIAreaOpacityLineAB = "";
    let SvgBCIAreaOpacityLineAC = "";
    let SvgBCIAreaOpacityLineAD = "";
    let SvgBCIAreaOpacityLineAE = "";
    let SvgBCIAreaOpacityLineAF = "";

    // エリア：A -> B
    if (SvgBCIAreaLineEchoBBB === undefined) {
        SvgBCIAreaOpacityAB = 0;
        SvgBCIAreaOpacityLineAB = "url(#arrowBlueClear)";
    } else if (SvgBCIAreaUserA === "" || SvgBCIAreaUserB === "") {
        SvgBCIAreaOpacityAB = 0;
        SvgBCIAreaOpacityLineAB = "url(#arrowBlueClear)";
    } else if (Number(SvgBCIAreaLineEchoBBB) < 5) {
        SvgBCIAreaOpacityLineAB = "url(#arrowRed)";
    } else {
        SvgBCIAreaOpacityLineAB = "url(#arrowBlue)";
    }
    // エリア：A -> C
    if (SvgBCIAreaLineEchoCCC === undefined) {
        SvgBCIAreaOpacityAC = 0;
        SvgBCIAreaOpacityLineAC = "url(#arrowBlueClear)";
    } else if (SvgBCIAreaUserA === "" || SvgBCIAreaUserC === "") {
        SvgBCIAreaOpacityAC = 0;
        SvgBCIAreaOpacityLineAC = "url(#arrowBlueClear)";
    } else if (Number(SvgBCIAreaLineEchoCCC) < 5) {
        // console.log(SvgBCIAreaLineEchoCCC + ":arrowRed")
        SvgBCIAreaOpacityLineAC = "url(#arrowRed)";
    } else {
        // console.log(SvgBCIAreaLineEchoCCC + ":arrowBlue")
        SvgBCIAreaOpacityLineAC = "url(#arrowBlue)";
    }
    // エリア：A -> D
    if (SvgBCIAreaLineEchoDDD === undefined) {
        SvgBCIAreaOpacityAD = 0;
        SvgBCIAreaOpacityLineAD = "url(#arrowBlueClear)";
    } else if (SvgBCIAreaUserA === "" || SvgBCIAreaUserD === "") {
        SvgBCIAreaOpacityAD = 0;
        SvgBCIAreaOpacityLineAD = "url(#arrowBlueClear)";
    } else if (Number(SvgBCIAreaLineEchoDDD) < 5) {
        SvgBCIAreaOpacityLineAD = "url(#arrowRed)";
    } else {
        SvgBCIAreaOpacityLineAD = "url(#arrowBlue)";
    }
    // エリア：A -> E
    if (SvgBCIAreaLineEchoEEE === undefined) {
        SvgBCIAreaOpacityAE = 0;
        SvgBCIAreaOpacityLineAE = "url(#arrowBlueClear)";
    } else if (SvgBCIAreaUserA === "" || SvgBCIAreaUserE === "") {
        SvgBCIAreaOpacityAE = 0;
        SvgBCIAreaOpacityLineAE = "url(#arrowBlueClear)";
    } else if (Number(SvgBCIAreaLineEchoEEE) < 5) {
        SvgBCIAreaOpacityLineAE = "url(#arrowRed)";
    } else {
        SvgBCIAreaOpacityLineAE = "url(#arrowBlue)";
    }
    // エリア：A -> F
    if (SvgBCIAreaLineEchoFFF === undefined) {
        SvgBCIAreaOpacityAF = 0;
        SvgBCIAreaOpacityLineAF = "url(#arrowBlueClear)";
    } else if (SvgBCIAreaUserA === "" || SvgBCIAreaUserF === "") {
        SvgBCIAreaOpacityAF = 0;
        SvgBCIAreaOpacityLineAF = "url(#arrowBlueClear)";
    } else if (Number(SvgBCIAreaLineEchoFFF) < 5) {
        SvgBCIAreaOpacityLineAF = "url(#arrowRed)";
    } else {
        SvgBCIAreaOpacityLineAF = "url(#arrowBlue)";
    }

    return (
        <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="gradientSmall" x1="10%" y1="0%" x2="90%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#f2e0c1', stopOpacity: 1 }} />
                    <stop offset="20%" style={{ stopColor: '#f2e0c1', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
                </linearGradient>
                <linearGradient id="gradientSmallClear" x1="10%" y1="0%" x2="90%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#f2e0c1', stopOpacity: 0 }} />
                    <stop offset="20%" style={{ stopColor: '#f2e0c1', stopOpacity: 0 }} />
                    <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 0 }} />
                </linearGradient>
                <linearGradient id="gradientBig" x1="10%" y1="0%" x2="90%" y2="100%">
                    <stop offset="0%" style={{ stopColor: '#eee', stopOpacity: 1 }} />
                    <stop offset="20%" style={{ stopColor: '#eee', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'white', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <marker id="arrowBlue" viewBox="-5 -5 10 10" orient="auto" refX="25" refY="0">
                <polygon points="-5,-5 5,0 -5,5" fill="#5798ff" stroke="none" opacity="1" />
            </marker>
            <marker id="arrowBlueClear" viewBox="-5 -5 10 10" orient="auto" refX="25" refY="0">
                <polygon points="-5,-5 5,0 -5,5" fill="#5798ff" stroke="none" opacity="0" />
            </marker>
            <marker id="arrowRed" viewBox="-5 -5 10 10" orient="auto" refX="25" refY="0">
                <polygon points="-5,-5 5,0 -5,5" fill="#ab07de" stroke="none" opacity="1" />
            </marker>
            <marker id="arrowRedClear" viewBox="-5 -5 10 10" orient="auto" refX="25" refY="0">
                <polygon points="-5,-5 5,0 -5,5" fill="#ab07de" stroke="none" opacity="0" />
            </marker>
            <circle cx="150" cy="150" r="150" stroke="white" strokeWidth="1" fill="url(#gradientBig)"></circle>

            {/* A-B Line */}<line key={SvgBCIAreaOpacityLineAB + "-B"} x1={SvgBCIPointEchoABx1} y1={SvgBCIPointEchoABy1} x2={SvgBCIPointEchoABx2} y2={SvgBCIPointEchoABy2} stroke={compatibleStarColor[Number(SvgBCIAreaLineEchoBBB)]} strokeOpacity={SvgBCIAreaOpacityB} strokeWidth="5" markerEnd={SvgBCIAreaOpacityLineAB} />
            {/* A-C Line */}<line key={SvgBCIAreaOpacityLineAC + "-C"} x1={SvgBCIPointEchoACx1} y1={SvgBCIPointEchoACy1} x2={SvgBCIPointEchoACx2} y2={SvgBCIPointEchoACy2} stroke={compatibleStarColor[Number(SvgBCIAreaLineEchoCCC)]} strokeOpacity={SvgBCIAreaOpacityC} strokeWidth="5" markerEnd={SvgBCIAreaOpacityLineAC} />
            {/* A-D Line */}<line key={SvgBCIAreaOpacityLineAD + "-D"} x1={SvgBCIPointEchoADx1} y1={SvgBCIPointEchoADy1} x2={SvgBCIPointEchoADx2} y2={SvgBCIPointEchoADy2} stroke={compatibleStarColor[Number(SvgBCIAreaLineEchoDDD)]} strokeOpacity={SvgBCIAreaOpacityD} strokeWidth="5" markerEnd={SvgBCIAreaOpacityLineAD} />
            {/* A-E Line */}<line key={SvgBCIAreaOpacityLineAE + "-E"} x1={SvgBCIPointEchoAEx1} y1={SvgBCIPointEchoAEy1} x2={SvgBCIPointEchoAEx2} y2={SvgBCIPointEchoAEy2} stroke={compatibleStarColor[Number(SvgBCIAreaLineEchoEEE)]} strokeOpacity={SvgBCIAreaOpacityE} strokeWidth="5" markerEnd={SvgBCIAreaOpacityLineAE} />
            {/* A-F Line */}<line key={SvgBCIAreaOpacityLineAF + "-F"} x1={SvgBCIPointEchoAFx1} y1={SvgBCIPointEchoAFy1} x2={SvgBCIPointEchoAFx2} y2={SvgBCIPointEchoAFy2} stroke={compatibleStarColor[Number(SvgBCIAreaLineEchoFFF)]} strokeOpacity={SvgBCIAreaOpacityF} strokeWidth="5" markerEnd={SvgBCIAreaOpacityLineAF} />

            {/* A circle */}<circle cx={SvgBCIPointAX} cy={SvgBCIPointAY} r="35" stroke="#aaa" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityA} fill={SvgBCIAreaOpacityTxtA}></circle>
            {/* B circle */}<circle cx={SvgBCIPointBX} cy={SvgBCIPointBY} r="35" stroke="#ddd" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityB} fill={SvgBCIAreaOpacityTxtB}></circle>
            {/* C circle */}<circle cx={SvgBCIPointCX} cy={SvgBCIPointCY} r="35" stroke="#ddd" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityC} fill={SvgBCIAreaOpacityTxtC}></circle>
            {/* D circle */}<circle cx={SvgBCIPointDX} cy={SvgBCIPointDY} r="35" stroke="#ddd" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityD} fill={SvgBCIAreaOpacityTxtD}></circle>
            {/* E circle */}<circle cx={SvgBCIPointEX} cy={SvgBCIPointEY} r="35" stroke="#ddd" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityE} fill={SvgBCIAreaOpacityTxtE}></circle>
            {/* F circle */}<circle cx={SvgBCIPointFX} cy={SvgBCIPointFY} r="35" stroke="#ddd" strokeWidth="2" strokeOpacity={SvgBCIAreaOpacityF} fill={SvgBCIAreaOpacityTxtF}></circle>

            {/* A UserName */}
            <text fontFamily="sans-serif" x="150" y="40" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="150" dy="-10">{SvgBCIUserATxt1}</tspan>
                <tspan x="150" dy="16">{SvgBCIUserATxt2}</tspan>
                <tspan x="150" dy="18">{SvgBCIUserATxt3}</tspan>
            </text>
            {/* B UserName */}
            <text fontFamily="sans-serif" x="250" y="100" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="250" dy="-10">{SvgBCIUserBTxt1}</tspan>
                <tspan x="250" dy="16">{SvgBCIUserBTxt2}</tspan>
                <tspan x="250" dy="18">{SvgBCIUserBTxt3}</tspan>
            </text>
            {/* C UserName */}
            <text fontFamily="sans-serif" x="250" y="200" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="250" dy="-10">{SvgBCIUserCTxt1}</tspan>
                <tspan x="250" dy="16">{SvgBCIUserCTxt2}</tspan>
                <tspan x="250" dy="18">{SvgBCIUserCTxt3}</tspan>
            </text>
            {/* D UserName */}
            <text fontFamily="sans-serif" x="150" y="260" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="150" dy="-10">{SvgBCIUserDTxt1}</tspan>
                <tspan x="150" dy="16">{SvgBCIUserDTxt2}</tspan>
                <tspan x="150" dy="18">{SvgBCIUserDTxt3}</tspan>
            </text>
            {/* E UserName */}
            <text fontFamily="sans-serif" x="50" y="200" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="50" dy="-10">{SvgBCIUserETxt1}</tspan>
                <tspan x="50" dy="16">{SvgBCIUserETxt2}</tspan>
                <tspan x="50" dy="18">{SvgBCIUserETxt3}</tspan>
            </text>
            {/* F UserName */}
            <text fontFamily="sans-serif" x="50" y="100" fontSize="12" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">
                <tspan x="50" dy="-10">{SvgBCIUserFTxt1}</tspan>
                <tspan x="50" dy="16">{SvgBCIUserFTxt2}</tspan>
                <tspan x="50" dy="18">{SvgBCIUserFTxt3}</tspan>
            </text>

            {/* A-B-circle */}
            <circle cx="200" cy="69" r="10" fill={compatibleStarColor[Number(SvgBCIAreaLineEchoBBB)]} opacity={SvgBCIAreaOpacityAB}></circle>
            <text fontFamily="sans-serif" fontWeight="bold" x="200" y="72" fontSize="14" fill="white" opacity={SvgBCIAreaOpacityAB} textAnchor="middle" dominantBaseline="middle">{SvgBCIAreaLineEchoBBB}</text>

            {/* A-C-circle */}
            <circle cx="200" cy="119" r="10" fill={compatibleStarColor[Number(SvgBCIAreaLineEchoCCC)]} opacity={SvgBCIAreaOpacityAC}></circle>
            <text fontFamily="sans-serif" fontWeight="bold" x="200" y="122" fontSize="14" fill="white" opacity={SvgBCIAreaOpacityAC} textAnchor="middle" dominantBaseline="middle">{SvgBCIAreaLineEchoCCC}</text>

            {/* A-D-circle */}
            <circle cx="150" cy="149" r="10" fill={compatibleStarColor[Number(SvgBCIAreaLineEchoDDD)]} opacity={SvgBCIAreaOpacityAD}></circle>
            <text fontFamily="sans-serif" fontWeight="bold" x="150" y="152" fontSize="14" fill="white" opacity={SvgBCIAreaOpacityAD} textAnchor="middle" dominantBaseline="middle">{SvgBCIAreaLineEchoDDD}</text>

            {/* A-E-circle */}
            <circle cx="100" cy="119" r="10" fill={compatibleStarColor[Number(SvgBCIAreaLineEchoEEE)]} opacity={SvgBCIAreaOpacityAE}></circle>
            <text fontFamily="sans-serif" fontWeight="bold" x="100" y="122" fontSize="14" fill="white" opacity={SvgBCIAreaOpacityAE} textAnchor="middle" dominantBaseline="middle">{SvgBCIAreaLineEchoEEE}</text>

            {/* A-F-circle */}
            <circle cx="100" cy="69" r="10" fill={compatibleStarColor[Number(SvgBCIAreaLineEchoFFF)]} opacity={SvgBCIAreaOpacityAF}></circle>
            <text fontFamily="sans-serif" fontWeight="bold" x="100" y="72" fontSize="14" fill="white" opacity={SvgBCIAreaOpacityAF} textAnchor="middle" dominantBaseline="middle">{SvgBCIAreaLineEchoFFF}</text>
        </svg>
    );
}

export default SVGBirthCompatibleBackImg;
