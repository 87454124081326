import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AuthProvider } from '../contexts/AuthContext';
import { GoogleSignIn } from '../components/GoogleSignIn';
import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
const AppContent = () => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="container mx-auto max-w-md mt-10">
            {user ? <UserProfile /> : <GoogleSignIn />}
        </div>
    );
};

const useUserName = () => {
    const { userInfo } = useAuth();
    return userInfo?.displayName || 'none';
};
const useUserEmail = () => {
    const { userInfo } = useAuth();
    return userInfo?.email || 'none';
};
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

// 他のコンポーネントでの使用
const UserNameTxt = () => {
    const userEmail = useUserEmail();
    const userUid = useUserUid();

    if (userUid === 'none') {
        return null;
    }

    return (
        <div>
            <div>{userEmail}さんのマイページ</div>
            <div>userID: {userUid}</div>
        </div>
    );
};

const getdata = "愛";
const pageTitle = `マイページ`


const Mypage: React.FC = React.memo(() => {
    const userUid = useUserUid();

    useEffect(() => {
        console.log('userUid:', userUid);

        if (getdata) {
            const search_log_url = `https://useruser.suureki.net/_search_log/_write_log.php`;

            fetch(search_log_url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ p: getdata, uid: userUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Search log saved:', data);
                })
                .catch(error => {
                    console.error('Error saving search log:', error);
                });
        }
    }, [getdata, userUid]); // userUidを依存配列に追加

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>情報表示</h2>
                <AuthProvider>
                    <AppContent />
                    <UserNameTxt />
                </AuthProvider>
            </Box>
            {/* ここに AdSenseBanner を挿入 */}
            <AdSenseBanner />
            {/* ここまで */}
            <div>test</div>
            {/* ここに AdSenseBanner を挿入 */}
            <AdSenseBanner />
            {/* ここまで */}
        </div>
    ];

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ flex: 1 }}>

                    {/* ***** メインコンテンツ ******************************************************************* */}
                    <div className='topspace_height'></div>
                    <div className='main_space'>
                        <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{pageTitle}</span>
                        </h1>
                        <div>{mainContents}</div>
                    </div>
                </div>
            </div>
        </>
    );
});

const App = () => (
    <AuthProvider>
        <Mypage />
    </AuthProvider>
);

export default App;
