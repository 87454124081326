import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
    pagelogReadUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

// const today = new Date();
// const todayY = today.getFullYear();

const formatDateToYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}${month}${day}`;
};
const getHalfYearBeforeAndAfter = (): { todayYMD: string, todayYMDbefore: string, todayYMDafter: string } => {
    const today = new Date();
    const todayYMD = new Date(today);
    // 半年前の日
    const todayYMDbefore = new Date(today);
    todayYMDbefore.setMonth(today.getMonth() - 6);
    // 半年後の日
    const todayYMDafter = new Date(today);
    todayYMDafter.setMonth(today.getMonth() + 6);

    return {
        todayYMD: formatDateToYYYYMMDD(todayYMD),
        todayYMDbefore: formatDateToYYYYMMDD(todayYMDbefore),
        todayYMDafter: formatDateToYYYYMMDD(todayYMDafter)
    };
};
const { todayYMD, todayYMDbefore, todayYMDafter } = getHalfYearBeforeAndAfter();

interface MoonState {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    mooninfo: string; /* 新月・満月・下弦・上弦 */
}


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `半年前~半年後迄の満月と新月の日の日時`
const pageTitleS = `満月/新月一覧` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    /*
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    */


    // 今年の月（新月・満月・下弦・上弦）状態jsonの呼び出し //////////////////////////////////////////////////////////////////////
    const moonstate_json_url = "https://db.suureki.net/_db_moon/moonstate_s_all.json";
    const [moonstate_data, setDataMoonState] = useState<MoonState[]>([]);
    useEffect(() => {
        fetch(moonstate_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(moonstate_data => setDataMoonState(moonstate_data))
            .catch(error => console.error('Error:', error));
    }, [moonstate_json_url]);

    let moonstatetime: { [date: string]: string } = {};
    let moonstateinfo: { [date: string]: string } = {};
    moonstate_data.forEach(moon => {
        moonstatetime[moon.date] = moon.time;
        moonstateinfo[moon.date] = moon.mooninfo;
    });


    // 半年前~半年後迄の、満月と新月の日 //////////////////////////////////////////////////////////////////////

    const moonContents = [
        <div key='moonContentskey'>
            {moonstate_data.filter(moon => moon.date >= todayYMDbefore && moon.date <= todayYMDafter).map(moon => {

                let moonImg: string;
                let todayAfterColor: string;
                todayAfterColor = 'gray';

                if (moonstateinfo[moon.date] === "newxxx-000") {
                    moonImg = "https://suureki.net/moon_img/moon_000.svg";
                } else if (moonstateinfo[moon.date] === "fullxx-180") {
                    moonImg = "https://suureki.net/moon_img/moon_180.svg";
                } else {
                    moonImg = "";
                }
                if (moon.date >= todayYMD) {
                    todayAfterColor = 'green';
                }

                /*
                満月と新月の日の一覧、曜日の表示
                let dateObj = new Date(dateY, dateM - 1, dateD); // dateM - 1 は、Dateオブジェクトが月を0から始まると考えるためです
                dateW = dateObj.getDay().toString();
                
                dateW = dateObj.getDay().toString();

                */
                let dateObj = new Date(Number(moon.date.slice(0, 4)), Number(moon.date.slice(4, 6)) - 1, Number(moon.date.slice(6, 8)));
                let tmpW = dateObj.getDay().toString();


                return (
                    <Box key={moon.date} sx={{ textAlign: 'left' }}>
                        <div style={{ borderBottom: '1px solid #ddd', letterSpacing: '0.5px', paddingTop: '0.3rem', paddingBottom: '0.3rem' }}>
                            ○
                            <span style={{ width: '320px' }}>
                                <span style={{ marginRight: '0rem' }}>
                                    （
                                    <Link
                                        component={RouterLink}
                                        underline="hover"
                                        key="1"
                                        color="primary"
                                        to={`/calendar/${[moon.date]}`}
                                        style={{ textDecoration: 'none', borderBottom: '1px dotted', fontSize: '1rem' }}
                                        onClick={handleClick}
                                    >
                                        日暦
                                    </Link>
                                    ）
                                </span>
                                <span style={{ color: todayAfterColor }}>
                                    {moon.date.slice(0, 4)}年
                                    {moon.date.slice(4, 6)}月
                                    {moon.date.slice(6, 8)}日
                                </span>
                                <span style={{ color: todayAfterColor, marginLeft: '0rem' }}>
                                    （{WeekName[tmpW]}）
                                </span>
                                <span style={{ color: todayAfterColor, marginLeft: '0rem' }}>
                                    {moonstatetime[moon.date].slice(0, 2)}時
                                    {moonstatetime[moon.date].slice(2, 4)}分
                                </span>
                            </span>
                            <img width='30px' src={moonImg} style={{ verticalAlign: 'middle' }} alt='' />
                        </div>
                    </Box>
                )

            })}

        </div>
    ];

    // //////////////////////////////////////////////////////////////////////


    const mainContents = [
        <div key='maincontentskey'>
            <Alert severity="info" sx={{ marginTop: '1.5rem' }}>
                <AlertTitle sx={{ fontSize: '0.9rem' }}>算出は、崇暦独自の計算方法によるものです。</AlertTitle>
                <span style={{ marginTop: '0.5rem' }}>日時の文字色は、<span style={{ color: 'gray' }}>本日以前は灰色</span>、<span style={{ color: 'green' }}>本日以降は緑</span>で表示しております。</span><br />
            </Alert>
            <Alert severity="info" sx={{ marginTop: '1.5rem' }}>
                <AlertTitle sx={{ fontSize: '0.9rem' }}>他メディアの満月/新月の情報と、日が異なる可能性があります。</AlertTitle>
                <span style={{ marginTop: '0.5rem' }}>例として、崇暦では 1日23時59分、他メディアでは2日0時0分の場合等です。</span><br />
            </Alert>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>半年前~半年後迄（含･時間）</h2>
                <div>{moonContents}</div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>1901年から2100年迄の新月と満月</h2>
                <Link component={RouterLink} underline="always" key="db_moon_1901" color="primary" to="/archives/db_moon/1901" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1901~1910年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1911" color="primary" to="/archives/db_moon/1911" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1911~1920年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1921" color="primary" to="/archives/db_moon/1921" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1921~1930年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1931" color="primary" to="/archives/db_moon/1931" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1931~1940年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1941" color="primary" to="/archives/db_moon/1941" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1941~1950年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1951" color="primary" to="/archives/db_moon/1951" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1951~1960年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1961" color="primary" to="/archives/db_moon/1961" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1961~1970年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1971" color="primary" to="/archives/db_moon/1971" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1971~1980年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1981" color="primary" to="/archives/db_moon/1981" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1981~1990年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_1991" color="primary" to="/archives/db_moon/1991" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>1991~2000年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2001" color="primary" to="/archives/db_moon/2001" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2001~2010年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2011" color="primary" to="/archives/db_moon/2011" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2011~2020年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2021" color="primary" to="/archives/db_moon/2021" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2021~2030年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2031" color="primary" to="/archives/db_moon/2031" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2031~2040年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2041" color="primary" to="/archives/db_moon/2041" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2041~2050年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2051" color="primary" to="/archives/db_moon/2051" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2051~2060年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2061" color="primary" to="/archives/db_moon/2061" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2061~2070年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2071" color="primary" to="/archives/db_moon/2071" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2071~2080年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2081" color="primary" to="/archives/db_moon/2081" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2081~2090年迄</Link>
                <Link component={RouterLink} underline="always" key="db_moon_2091" color="primary" to="/archives/db_moon/2091" onClick={handleClick} style={{ marginRight: '1rem', whiteSpace: 'nowrap' }}>2091~2100年迄</Link>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];

    const pageKey = "DBmoonIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
//};

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;