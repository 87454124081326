import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Etcday {
    date: string;           /* 年月日 */
    etcday_dat: string;    /* 祝日の該当数字 */
}

/*

// 七草の節句 1月7日
// バレンタインデー 2月14日
// ひなまつり、桃の節句 3月3日
// ホワイトデー 3月14日
// エイプリルフール 4月1日
// メーデー 5月1日
// 端午の節句 5月5日
// 母の日は、5月第2日曜日
// 父の日は、6月第3日曜日
// 七夕 7月7日
// 菊の節句 9月9日
// ハロウィン 10月31日
// クリスマスイヴ 12月24日
// クリスマス 12月25日


{ "date": "19490101", "holiday_dat": "1" },
{ "date": "19490115", "holiday_dat": "2" },
{ "date": "19490321", "holiday_dat": "4" },
{ "date": "19490429", "holiday_dat": "17" },
{ "date": "19490503", "holiday_dat": "6" },

{ "date": "20170107", "etcday_dat": "七草の節句" },
{ "date": "20170214", "etcday_dat": "バレンタインデー" },
{ "date": "20170303", "etcday_dat": "ひなまつり、桃の節句" },
{ "date": "20170314", "etcday_dat": "ホワイトデー" },
{ "date": "20170401", "etcday_dat": "エイプリルフール" },
*/

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `祝日ではない日と風習の一覧（2017年以降）`
const pageTitleS = `風習等の一覧` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const etcday_json_url = "https://db.suureki.net/_db_horiday/etcday_all.json";

    const [etcday_data, setDataEtcday] = useState<Etcday[]>([]);
    useEffect(() => {
        fetch(etcday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(etcday_data => setDataEtcday(etcday_data))
            .catch(error => console.error('Error:', error));
    }, [etcday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let etcdayMap: { [date: string]: string } = {};
    etcday_data.forEach(etcday => {
        etcdayMap[etcday.date] = etcday.etcday_dat;
    });

    let holidayData: { [year: number]: JSX.Element[] } = {};

    for (let dateYMD in etcdayMap) {
        let dateY = Number(dateYMD.slice(0, 4));
        let dateM = Number(dateYMD.slice(4, 6));
        let dateD = Number(dateYMD.slice(6, 8));
        let dateObj = new Date(dateY, dateM - 1, dateD); // dateM - 1 は、Dateオブジェクトが月を0から始まると考えるためです
        let dateW = dateObj.getDay().toString();


        if (!holidayData[dateY]) {
            holidayData[dateY] = [];
        }

        holidayData[dateY].push(
            <div key={dateYMD}>
                {dateY}年{("00" + dateM).slice(-2)}月{("00" + dateD).slice(-2)}日（{WeekName[dateW]}）： {etcdayMap[dateYMD]}
            </div>
        );
    }


    // //////////////////////////////////////////////////////////////////////



    let todayY = new Date().getFullYear();
    let tmpY = new Date().getFullYear() + 2;
    let iEnd = tmpY - 2017; // 2017年度以降のみ表示のため、2017を引く
    let yearMark = null;
    let tmpI = 0;

    const mainContents = [
        <div key='maincontentskey'>
            {Array.from({ length: iEnd }, (_, i) => {
                tmpY--;
                tmpI = todayY - tmpY;
                if (todayY === tmpY) {
                    yearMark = "今年";
                } else if (todayY === tmpY - 1) {
                    yearMark = "来年";
                } else if (todayY === tmpY + 1) {
                    yearMark = "去年";
                } else {
                    yearMark = tmpI + "年前";
                }
                return (
                    <Box key={`inner-${tmpY}`} className='content_subbox_txt'>
                        <h2 className='content_subtitle'>{tmpY}年<span style={{ fontSize: '0.9rem', color: 'white', backgroundColor: '#666', margin: '0rem 0rem 0rem 1rem', padding: '0rem 0.5rem 0rem 0.5rem' }}>{yearMark}</span></h2>
                        <ul>
                            <li>{holidayData[tmpY]}</li>
                        </ul>
                    </Box>
                );
            })}
        </div>
    ];

    const pageKey = "DBetcday";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseMultiplex />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;