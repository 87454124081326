import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

// web-app
const firebaseConfig = {
    apiKey: "AIzaSyAi0C39A6kxG3BgdT5dl7aXm_kQiNBxNSo",
    authDomain: "suureki.firebaseapp.com",
    projectId: "suureki",
    storageBucket: "suureki.appspot.com",
    messagingSenderId: "414655015137",
    appId: "1:414655015137:web:3b665ad84fdd315ec42fa0",
    measurementId: "G-KWNDG681EF"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = getAuth(app);