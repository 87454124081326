

export function cabara(birthYMD: string, todayY: string): { cabaraAnswer: string, cabaraAnswerF: string, cabaraAnswerT: string, cabaraAnswerTF: string } {

    ///////////////////////////////////////////////////////////
    // 誕生数（運命数）

    let cabaraCalcFormula = "（計算式：";
    let cabaraCalcTmp = 0;
    let cabaraCalc;

    for (let cabara_i = 0; cabara_i < 8; cabara_i++) {
        cabaraCalcFormula += birthYMD.substring(cabara_i, cabara_i + 1);
        cabaraCalcTmp = cabaraCalcTmp + Number(birthYMD.substring(cabara_i, cabara_i + 1));
        if (cabara_i === 7) {
            cabaraCalcFormula += "=" + cabaraCalcTmp;
        } else {
            cabaraCalcFormula += "+";
        }
    }

    if (cabaraCalcTmp === 11 || cabaraCalcTmp === 22 || cabaraCalcTmp === 33) {
        cabaraCalcFormula += "）";
        cabaraCalc = cabaraCalcTmp;
    } else {
        cabaraCalcFormula += "、" + String(cabaraCalcTmp).substring(0, 1) + "+";
        cabaraCalcFormula += String(cabaraCalcTmp).substring(1, 2) + "=";
        cabaraCalcTmp = Number(String(cabaraCalcTmp).substring(0, 1)) + Number(String(cabaraCalcTmp).substring(1, 2))

        if (cabaraCalcTmp === 10) {
            cabaraCalcTmp = 1;
            cabaraCalcFormula += "10、1+0=";
        }

        cabaraCalc = cabaraCalcTmp;
        cabaraCalcFormula += cabaraCalcTmp + "）";
    }

    // console.log(cabaraCalcTmp)
    // console.log(cabaraCalcFormula)


    ///////////////////////////////////////////////////////////
    // 年毎の特徴：
    // 西暦と、生まれた月日を一桁ずつ足す。

    let cabaraYearYMD = todayY + birthYMD.substring(4, 8);
    let cabaraCalcTodayYFormula = "（計算式：";
    let cabaraCalcTodayYTmp = 0;
    let cabaraCalcTodayY;

    for (let cabara_i = 0; cabara_i < 8; cabara_i++) {
        cabaraCalcTodayYFormula += cabaraYearYMD.substring(cabara_i, cabara_i + 1);
        cabaraCalcTodayYTmp = cabaraCalcTodayYTmp + Number(cabaraYearYMD.substring(cabara_i, cabara_i + 1));
        if (cabara_i === 7) {
            cabaraCalcTodayYFormula += "=" + cabaraCalcTodayYTmp;
        } else {
            cabaraCalcTodayYFormula += "+";
        }
    }

    cabaraCalcTodayYFormula += "、" + String(cabaraCalcTodayYTmp).substring(0, 1) + "+";
    cabaraCalcTodayYFormula += String(cabaraCalcTodayYTmp).substring(1, 2) + "=";
    cabaraCalcTodayYTmp = Number(String(cabaraCalcTodayYTmp).substring(0, 1)) + Number(String(cabaraCalcTodayYTmp).substring(1, 2))

    if (cabaraCalcTodayYTmp === 10) {
        cabaraCalcTodayYTmp = 1;
        cabaraCalcTodayYFormula += "10、1+0=";
    }

    cabaraCalcTodayY = cabaraCalcTodayYTmp;
    cabaraCalcTodayYFormula += cabaraCalcTodayYTmp + "）";

    // console.log(cabaraCalcTodayYTmp)
    // console.log(cabaraCalcTodayYFormula)

    const cabaraAnswer = String(cabaraCalc);
    const cabaraAnswerF = cabaraCalcFormula;
    const cabaraAnswerT = String(cabaraCalcTodayY);
    const cabaraAnswerTF = cabaraCalcTodayYFormula;

    return { cabaraAnswer, cabaraAnswerF, cabaraAnswerT, cabaraAnswerTF };
}