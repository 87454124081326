import React, { useEffect } from 'react';

declare global {
    interface Window {
        adsbygoogle: any;
    }
}

// <!-- 300x250、レクタングル -->

function AdSenseBanner() {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
            console.error("AdSense 広告の読み込みエラー:", error);
        }
    }, []);

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{ display: 'inline-block', width: '300px', height: '250px', border: '1px solid gray' }}
                data-ad-client="ca-pub-2162909351269073"
                data-ad-slot="7638748509"
            ></ins>
        </div>
    );
}

export default AdSenseBanner;
