export function biorhythm(
    birthY: string, birthM: string, birthD: string,
    todayY: string, todayM: string, todayD: string
): {
    p: {
        p01: number, // -7
        p02: number, // -6
        p03: number, // -5
        p04: number, // -4
        p05: number, // -3
        p06: number, // -2
        p07: number, // -1
        p08: number, // 当日
        p09: number, // +1
        p10: number, // +2
        p11: number, // +3
        p12: number, // +4
        p13: number, // +5
        p14: number, // +6
        p15: number, // +7
        p16: number, // +8
        p17: number, // +9
        p18: number, // +10
        p19: number, // +11
        p20: number, // +12
        p21: number, // +13
        p22: number, // +14
    }, s: {
        s01: number, // -7
        s02: number, // -6
        s03: number, // -5
        s04: number, // -4
        s05: number, // -3
        s06: number, // -2
        s07: number, // -1
        s08: number, // 当日
        s09: number, // +1
        s10: number, // +2
        s11: number, // +3
        s12: number, // +4
        s13: number, // +5
        s14: number, // +6
        s15: number, // +7
        s16: number, // +8
        s17: number, // +9
        s18: number, // +10
        s19: number, // +11
        s20: number, // +12
        s21: number, // +13
        s22: number, // +14
    }, i: {
        i01: number, // -7
        i02: number, // -6
        i03: number, // -5
        i04: number, // -4
        i05: number, // -3
        i06: number, // -2
        i07: number, // -1
        i08: number, // 当日
        i09: number, // +1
        i10: number, // +2
        i11: number, // +3
        i12: number, // +4
        i13: number, // +5
        i14: number, // +6
        i15: number, // +7
        i16: number, // +8
        i17: number, // +9
        i18: number, // +10
        i19: number, // +11
        i20: number, // +12
        i21: number, // +13
        i22: number, // +14
    }
} {

    const birthY_M_D = birthY + "-" + birthM + "-" + birthD;
    const tmpBirth = new Date(birthY_M_D);
    let tmpBirthUnixTimestamp = tmpBirth.getTime() / 1000; // JavaScriptはミリ秒単位なので1000で割る
    tmpBirthUnixTimestamp -= 32400; // タイムスタンプ（日本時間と世界標準時間）の調整、(9 * 60 * 60 = 32400）

    const todayY_M_D = todayY + "-" + todayM + "-" + todayD;
    const tmpStartday = new Date(todayY_M_D);
    tmpStartday.setDate(tmpStartday.getDate() - 7); // 7日前の日付に変更
    let tmpStartdayUnixTimestamp = tmpStartday.getTime() / 1000; // JavaScriptはミリ秒単位なので1000で割る
    tmpStartdayUnixTimestamp -= 32400; // タイムスタンプ（日本時間と世界標準時間）の調整、(9 * 60 * 60 = 32400）

    const UnixTimestampDiff = Math.abs(tmpBirthUnixTimestamp - tmpStartdayUnixTimestamp);	// absは、絶対値（基準からどれだけはなれているか）を求める：具体的には、「-」を消す。
    const birthTodayDiff = UnixTimestampDiff / (60 * 60 * 24);  // 誕生日と今日が、何日離れているか。
    const dayStart = birthTodayDiff;
    const dayEnd = dayStart + 21;

    /*
    console.log("誕生日：" + tmpBirth)
    console.log("誕生日のタイムスタンプ：" + tmpBirthUnixTimestamp)
    console.log("表示開始日：" + tmpStartday)
    console.log("表示開始日のタイムスタンプ：" + tmpStartdayUnixTimestamp)
    console.log("誕生日と表示開始日のタイムスタンプの差：" + UnixTimestampDiff)
    console.log("誕生日から表示開始日までの日数：" + birthTodayDiff)
    console.log("誕生日から表示開始日までの日数dayStart：" + dayStart)
    console.log("誕生日から表示終了日までの日数dayEnd：" + dayEnd)
    */

    // P（身体：緑色）
    // S（感情：桃色）
    // I（知性：青色）

    let biorhythm_count = 1;
    let answerP: { [biorhythm_count: number]: number } = {};
    let answerS: { [biorhythm_count: number]: number } = {};
    let answerI: { [biorhythm_count: number]: number } = {};


    for (let biorhythm_i = dayStart; biorhythm_i <= dayEnd; biorhythm_i++) {
        let pCalc = biorhythm_i * 2 * Math.PI / 23;
        let sCalc = biorhythm_i * 2 * Math.PI / 28;
        let iCalc = biorhythm_i * 2 * Math.PI / 33;

        let tenP = 100 - Math.round(Math.sin(pCalc) * 100);
        let tenS = 100 - Math.round(Math.sin(sCalc) * 100);
        let tenI = 100 - Math.round(Math.sin(iCalc) * 100);

        answerP[biorhythm_count] = 0 - (tenP / 100) + 1;
        answerS[biorhythm_count] = 0 - (tenS / 100) + 1;
        answerI[biorhythm_count] = 0 - (tenI / 100) + 1;

        answerP[biorhythm_count] = Math.round(answerP[biorhythm_count] * 1000) / 1000;    // 小数点以下3桁を四捨五入
        answerS[biorhythm_count] = Math.round(answerS[biorhythm_count] * 1000) / 1000;    // 小数点以下3桁を四捨五入
        answerI[biorhythm_count] = Math.round(answerI[biorhythm_count] * 1000) / 1000;    // 小数点以下3桁を四捨五入

        biorhythm_count++;

    }

    const p = {
        p01: answerP[1],    // -7
        p02: answerP[2],    // -6
        p03: answerP[3],    // -5
        p04: answerP[4],    // -4
        p05: answerP[5],    // -3
        p06: answerP[6],    // -2
        p07: answerP[7],    // -1
        p08: answerP[8],    // 当日
        p09: answerP[9],    // +1
        p10: answerP[10],    // +2
        p11: answerP[11],    // +3
        p12: answerP[12],    // +4
        p13: answerP[13],    // +5
        p14: answerP[14],    // +6
        p15: answerP[15],    // +7
        p16: answerP[16],    // +8
        p17: answerP[17],    // +9
        p18: answerP[18],    // +10
        p19: answerP[19],    // +11
        p20: answerP[20],    // +12
        p21: answerP[21],    // +13
        p22: answerP[22],    // +14
    };
    const s = {
        s01: answerS[1],    // -7
        s02: answerS[2],    // -6
        s03: answerS[3],    // -5
        s04: answerS[4],    // -4
        s05: answerS[5],    // -3
        s06: answerS[6],    // -2
        s07: answerS[7],    // -1
        s08: answerS[8],    // 当日
        s09: answerS[9],    // +1
        s10: answerS[10],    // +2
        s11: answerS[11],    // +3
        s12: answerS[12],    // +4
        s13: answerS[13],    // +5
        s14: answerS[14],    // +6
        s15: answerS[15],    // +7
        s16: answerS[16],    // +8
        s17: answerS[17],    // +9
        s18: answerS[18],    // +10
        s19: answerS[19],    // +11
        s20: answerS[20],    // +12
        s21: answerS[21],    // +13
        s22: answerS[22],    // +14
    };
    const i = {
        i01: answerI[1],    // -7
        i02: answerI[2],    // -6
        i03: answerI[3],    // -5
        i04: answerI[4],    // -4
        i05: answerI[5],    // -3
        i06: answerI[6],    // -2
        i07: answerI[7],    // -1
        i08: answerI[8],    // 当日
        i09: answerI[9],    // +1
        i10: answerI[10],    // +2
        i11: answerI[11],    // +3
        i12: answerI[12],    // +4
        i13: answerI[13],    // +5
        i14: answerI[14],    // +6
        i15: answerI[15],    // +7
        i16: answerI[16],    // +8
        i17: answerI[17],    // +9
        i18: answerI[18],    // +10
        i19: answerI[19],    // +11
        i20: answerI[20],    // +12
        i21: answerI[21],    // +13
        i22: answerI[22],    // +14
    };

    return { p, s, i };
}
