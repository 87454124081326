import React, { useEffect } from 'react';

// 英数字のみを使用するカスタムエンコード関数
const toAlphaNumeric = (buffer: ArrayBuffer): string => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const bytes = new Uint8Array(buffer);
    let result = '';
    for (let i = 0; i < bytes.length; i++) {
        result += chars[bytes[i] % chars.length];
    }
    return result;
};

// 固定のキーとIVを使用する
const fixedKey = new Uint8Array(32).fill(1); // 例として全て1の固定キー
const fixedIv = new Uint8Array(16).fill(1);  // 例として全て1の固定IV

// 暗号化処理を行う関数
const encrypt = async (value: string): Promise<string> => {
    const crypto = window.crypto;
    const key = await crypto.subtle.importKey(
        'raw',
        fixedKey,
        { name: 'AES-GCM' },
        false,
        ['encrypt']
    );

    const encoder = new TextEncoder();
    const data = encoder.encode(value);

    const algorithm = { name: 'AES-GCM', iv: fixedIv };
    const ciphertext = await crypto.subtle.encrypt(algorithm, key, data);
    return toAlphaNumeric(ciphertext);
};

// 暗号化処理を行うコンポーネント
interface EncryptUserUidProps {
    uid: string;
    onEncrypted: (encryptedUid: string) => void;
}

const EncryptUserUid: React.FC<EncryptUserUidProps> = ({ uid, onEncrypted }) => {
    useEffect(() => {
        const encryptUid = async () => {
            const result = await encrypt(uid);
            onEncrypted(result);
        };
        encryptUid();
    }, [uid, onEncrypted]);

    return null; // このコンポーネントはUIを持たない
};

export default EncryptUserUid;