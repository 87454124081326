import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import angleImg12 from '../img/angleImg12.svg';
import angleImg24 from '../img/angleImg24.svg';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    EtoCompass,
    EtoCompassRuby,
    EtoCompassDirection,
    EtoName10,
    EtoName10RubyA,
    EtoName10RubyB,
    EtoName10NinestarRuby,
    EtoName12,
    EtoName12Time,
    EtoName12Angle,
    EtoName12RubyA,
    EtoName12RubyB,
    EtoName12RubyC,
    EtoName12NinestarRuby,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Koyomi {
    date: string;           /* 日付 */
    eto10_day: number;    /* 月干支（暦：10支）： */
    eto12_day: number;    /* 月干支（暦：12支） */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
}



function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}


const pageTitle = `干支とは`
const pageTitleS = `干支とは` // ページトップのナビゲーションリンク
const uppageTitle = `暦注とは？`


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/annotation"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const todayY = new Date().getFullYear();
const todayM = ("0" + (new Date().getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
const todayD = ("0" + new Date().getDate()).slice(-2);
const todayYMD = todayY + todayM + todayD;
const todayYMDtxt = todayY + "年" + parseInt(todayM.slice(0, 2), 10) + "月" + parseInt(todayD.slice(0, 2), 10) + "日";







const Annotation: React.FC = () => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${todayY}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);

    useEffect(() => {
        fetch(calendar_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url]);
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10monthMap: { [date: string]: number } = {};
    let eto12monthMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    koyomi_data.forEach(koyomi => {
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10monthMap[koyomi.date] = koyomi.eto10_month;
        eto12monthMap[koyomi.date] = koyomi.eto12_month;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
    });


    const etoTableX = 5;
    const etoTableY = 60 / etoTableX;

    const renderCells = (i: number) => {
        return Array(etoTableX).fill(null).map((_, index) => {
            let ValueNo = (index + 1 + (i * etoTableX)).toString().padStart(1, '0');  // 二桁表示
            let value10 = (index + (i * etoTableX)) % 10; // 10で割った余りを求める
            let value12 = (index + (i * etoTableX)) % 12; // 10で割った余りを求める
            return (
                <td key={index} className='swipe_table_cell calendar_bgcolor'>
                    <span style={{ marginRight: '1rem' }}>{ValueNo}</span>
                    <span>{EtoName10[value10]}{EtoName12[value12]}</span>
                    <span>
                        （{EtoName10RubyB[value10]}{EtoName12RubyC[value12]}、
                        {EtoName10NinestarRuby[value10]}{EtoName12RubyB[value12]}）
                    </span>
                </td>
            );
        });
    };

    const etoTable = Array(etoTableY).fill(null).map((_, i: number) => (
        <tbody key={i}>
            <tr>
                {renderCells(i)}
            </tr>
        </tbody>
    ));


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支とは</h2>
                <div>
                    十干（じっかん）と十二支（じゅうにし）がある。<br />
                    十干は天干（てんかん）、十二支は地支（ちし）とも言い、十干と十二支を組み合わせたものを六十干支（ろくじっかんし）という。<br />
                </div>
                <div>
                    暦、時間、方位として使用する。六十干支は、甲子から癸亥までの60の語がある。
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>十干とは</h2>
                <div>
                    名の通り、10の語がある。
                </div>
                <ul>
                    {EtoName10.map((item, index) => (
                        <li key={index} className='inside-list-style-decimal'>
                            {item}
                            （{EtoName10RubyA[index]}、{EtoName10NinestarRuby[index]}）
                        </li>
                    ))}
                </ul>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>十二支とは</h2>
                <div>
                    名の通り、12の語（動物）がある。
                </div>
                <ul>
                    {EtoName12.map((item, index) => (
                        <li key={index} className='inside-list-style-decimal'>
                            {item}
                            （{EtoName12RubyA[index]}、{EtoName12NinestarRuby[index]}）
                        </li>
                    ))}
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>六十干支</h2>
                <div style={{ marginBottom: '1rem' }}>
                    <span>六十干支（ろくじっかんし）は、十干と十二支、全ての組み合わせはありません。</span><br />
                    <br />
                    <span>十干の「丙戊庚壬」と十二支の「子寅辰午申戌」、</span><br />
                    <span>十干の「乙丁己辛癸」と十二支の「丑卯巳未酉亥」の組み合わせのみが存在します。</span>
                </div>
                <div style={{ margin: '0rem 0rem 0rem 0rem', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon color="disabled" />
                    <ArrowLeftIcon color="disabled" />
                    <SwipeIcon fontSize="small" color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <ArrowRightIcon color="disabled" />
                    <span style={{ color: 'gray', fontSize: '0.8rem', marginLeft: '0.5rem' }}>Swipe on the table.</span>
                </div>
                <div className='table_enclosure'>
                    <table className='swipe_table'>
                        {etoTable}
                    </table>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支の暦としての使用</h2>
                <div>
                    年、月、日、其々に六十干支を割り当てて使用する。
                </div>
                <div>
                    <span>本日、{todayYMDtxt}の場合、</span>
                    <span>{EtoName10[eto10dayMap[todayYMD]]}{EtoName12[eto12dayMap[todayYMD]]}の日、</span>
                    <span>{EtoName10[eto10monthMap[todayYMD]]}{EtoName12[eto12monthMap[todayYMD]]}の月、</span>
                    <span>{EtoName10[eto10yearMap[todayYMD]]}{EtoName12[eto12yearMap[todayYMD]]}の年となる。</span>
                </div>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支の時間としての使用</h2>
                <div>
                    十干は使用せずに十二支のみを使用し、時間を表す。
                </div>
                <ul>
                    {EtoName12Time.map((item, index) => (
                        <li key={index} className='inside-list-style'>
                            {item}
                            ：{EtoName12[index]}の刻（{EtoName12RubyB[index]}のこく）
                        </li>
                    ))}
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支の方位としての使用 1</h2>
                <div style={{ marginBottom: '1rem' }}>
                    十干は使用せずに十二支のみを使用し、方位を表す。
                </div>
                <img src={angleImg12} alt="angleImg" width="300" height="300" />
                <ul>
                    {EtoName12.map((item, index) => (
                        <li key={index} className='inside-list-style-decimal'>
                            {item}
                            （{EtoName12RubyB[index]}）の方角：{EtoName12Angle[index]}前後
                        </li>
                    ))}
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>干支の方位としての使用 2</h2>
                <div>
                    十干の一部と十二支に加え、「艮 巽 坤 乾 」使用し、方位を表す。
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    稲荷暦、高島易等で使用される。
                </div>
                <img src={angleImg24} alt="angleImg" width="300" height="300" />
                <ul>

                    {EtoCompass.map((item, index) => (
                        <li key={index} className='inside-list-style-decimal'>
                            {item}
                            （{EtoCompassRuby[index]}）の方角：{EtoCompassDirection[index]}
                        </li>
                    ))}
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "AnnotationEto";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);

    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };
    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
};


const App = () => (
    <AuthProvider>
        <Annotation />
    </AuthProvider>
);

export default App;