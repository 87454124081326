// src/AppBar.tsx
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const today = new Date();
const dateY = today.getFullYear();

const SuurekiFooter: React.FC = () => {
    return (
        <div
            style={{
                bottom: 0,
                lineHeight: '1.2rem',
                color: 'white',
                width: '100%',
                height: '200px',
                backgroundColor: '#666',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <span style={{ fontSize: '0.75rem', }}>
                Copyright (C) 2014-{dateY}
            </span>
            <span style={{ fontSize: '0.75rem', }}>
                崇暦.net / suureki.net
            </span>
            <span style={{ fontSize: '0.75rem', marginLeft: 'auto', marginRight: 'auto' }}>
                All Rights Reserved.
            </span>
            <span style={{ fontSize: '0.9rem', marginTop: '0.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                <Link component={RouterLink} underline="always" key="privacy" color="primary" to="/privacy" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>プライバシーポリシー・免責事項</span>
                </Link>
                <Link component={RouterLink} underline="always" key="about" color="primary" to="/about" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>崇暦について</span>
                </Link>
            </span>
        </div>
    );
};
/*

            <span style={{ fontSize: '0.9rem', marginTop: '0.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                <Link component={RouterLink} underline="always" key="privacy" color="primary" to="/privacy" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>プライバシーポリシー・免責事項</span>
                </Link>
                <Link component={RouterLink} underline="always" key="about" color="primary" to="/about" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>崇暦について</span>
                </Link>
            </span>
            <span style={{ fontSize: '0.9rem', marginTop: '0.5rem', marginLeft: 'auto', marginRight: 'auto' }}>
                <Link component={RouterLink} underline="always" key="application" color="primary" to="/application" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                    <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>崇暦のモバイルアプリ</span>
                </Link>
            </span>
*/
export default SuurekiFooter;
