// src/pages/CalendarIndex.tsx
// import * as React from 'react';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
    siteNameS,
    siteType,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    siteTypePage,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

/* *********************************************************************************************** */
/* ***** ページ名の作成 *************************************************************************** */
/* *********************************************************************************************** */

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `年暦・月暦・日暦の検索`
let pageTitleS = `calendar`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// const Calendar: React.FC = () => {
const Calendar: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const today = new Date();
    const navigate = useNavigate();

    // dateF(Year): YYYY 
    // dateF(month): YYYY-MM
    // dateF(day):  YYYY-MM-DD

    let dateY = today.getFullYear();
    let dateM = (today.getMonth() + 1).toString().padStart(2, '0');
    let dateD = (today.getDate()).toString().padStart(2, '0');

    let dateF = dateY + "-" + dateM + "-" + dateD;


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='searchKeyY'>
                            年暦の検索：
                            <MobileDatePicker
                                views={['year']}
                                defaultValue={dayjs(dateF)}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const formattedDate = newValue.format('YYYY');
                                        navigate(`/calendar/${formattedDate}`);
                                    }
                                }}
                                format="YYYY"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2100-12-31')}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
            <Box className='content_subbox_txt'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='searchKeyM'>
                            月暦の検索：
                            <MobileDatePicker
                                views={['year', 'month']}
                                defaultValue={dayjs(dateF)}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const formattedDate = newValue.format('YYYYMM');
                                        navigate(`/calendar/${formattedDate}`);
                                    }
                                }}
                                format="YYYY/MM"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2100-12-31')}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
            <Box className='content_subbox_txt'>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                    <DemoContainer components={['MobileDatePicker']}>
                        <Box display="flex" alignItems="center" className='search_space' key='searchKeyD'>
                            日暦の検索：
                            <MobileDatePicker
                                views={['year', 'month', 'day']}
                                defaultValue={dayjs(dateF)}
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        const formattedDate = newValue.format('YYYYMMDD');
                                        navigate(`/calendar/${formattedDate}`);
                                    }
                                }}
                                format="YYYY/MM/DD"
                                minDate={dayjs('1901-01-01')}
                                maxDate={dayjs('2100-12-31')}
                                localeText={{ toolbarTitle: '日暦の年月日' }}
                                slotProps={{
                                    toolbar: {
                                        toolbarPlaceholder: '__',
                                        toolbarFormat: 'YYYY年MM月DD日',
                                        hidden: false,
                                    }
                                }}
                            />
                        </Box>
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "CalendarIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <Calendar />
    </AuthProvider>
);

export default App;