export default function canvas_kyusei_img_years(scaleX: number, scaleY: number, birthKN: string) {
    var canvas = document.getElementById('kyusei_img_years') as HTMLCanvasElement;
    // let yearAngleDataA = "164805732"; // 中心・北~北西
    let yearAngleDataB = "132648057"; // 中心・西~南西

    let yearAnglePlusA = ""; // 西
    let yearAnglePlusB = ""; // 北西
    let yearAnglePlusC = ""; // 北
    let yearAnglePlusD = ""; // 北東
    let yearAnglePlusE = ""; // 東
    let yearAnglePlusF = ""; // 南東
    let yearAnglePlusG = ""; // 南
    let yearAnglePlusH = ""; // 南西

    /*
    中心：1
    西　：3
    北西：2
    北　：6
    北東：4
    東　：8
    南東：0
    南　：5
    南西：7
    */

    for (let i = 0; i < 9; i++) {
        if (yearAngleDataB.substring(i, i + 1) === birthKN) {
            if (i === 1) { yearAnglePlusA += "本命殺　"; yearAnglePlusE += "本命的殺　"; }
            if (i === 2) { yearAnglePlusB += "本命殺　"; yearAnglePlusF += "本命的殺　"; }
            if (i === 3) { yearAnglePlusC += "本命殺　"; yearAnglePlusG += "本命的殺　"; }
            if (i === 4) { yearAnglePlusD += "本命殺　"; yearAnglePlusH += "本命的殺　"; }
            if (i === 5) { yearAnglePlusE += "本命殺　"; yearAnglePlusA += "本命的殺　"; }
            if (i === 6) { yearAnglePlusF += "本命殺　"; yearAnglePlusB += "本命的殺　"; }
            if (i === 7) { yearAnglePlusG += "本命殺　"; yearAnglePlusC += "本命的殺　"; }
            if (i === 8) { yearAnglePlusH += "本命殺　"; yearAnglePlusD += "本命的殺　"; }
        }
    }


    if (canvas) {
        var ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.scale(scaleX, scaleY);
            ctx.beginPath();
            const cx = (41 + 631) / 2;
            const cy = (195 + 785) / 2;
            // 内円と外円の半径を設定
            const innerRadius = 0;
            const outerRadius = Math.sqrt(Math.pow(631 - cx, 2) + Math.pow(785 - cy, 2)); // 中心座標から一番遠い頂点までの距離
            const gradient = ctx.createRadialGradient(cx, cy, innerRadius, cx, cy, outerRadius);
            gradient.addColorStop(0, '#FFFFFF'); // 中心の色
            gradient.addColorStop(0.8, '#F3DBB8'); // 外側の色
            gradient.addColorStop(1, '#F3DBB8'); // 外側の色
            ctx.fillStyle = gradient;
            ctx.shadowColor = "#888888";
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 2;
            ctx.shadowOffsetY = 2;
            ctx.moveTo(216, 195);
            ctx.lineTo(456, 195);
            ctx.lineTo(631, 370);
            ctx.lineTo(631, 610);
            ctx.lineTo(456, 785);
            ctx.lineTo(216, 785);
            ctx.lineTo(41, 610);
            ctx.lineTo(41, 370);
            ctx.closePath();
            ctx.fill();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.shadowColor = "#888888";
            ctx.shadowBlur = 0;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.lineWidth = 1;
            ctx.moveTo(216, 195);
            ctx.lineTo(456, 195);
            ctx.lineTo(631, 370);
            ctx.lineTo(631, 610);
            ctx.lineTo(456, 785);
            ctx.lineTo(216, 785);
            ctx.lineTo(41, 610);
            ctx.lineTo(41, 370);
            ctx.closePath();
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.lineWidth = 1;
            ctx.moveTo(240, 250);
            ctx.lineTo(432, 250);
            ctx.lineTo(576, 394);
            ctx.lineTo(576, 586);
            ctx.lineTo(432, 730);
            ctx.lineTo(240, 730);
            ctx.lineTo(96, 586);
            ctx.lineTo(96, 394);
            ctx.closePath();
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.lineWidth = 1;
            ctx.moveTo(256, 290);
            ctx.lineTo(416, 290);
            ctx.lineTo(536, 410);
            ctx.lineTo(536, 570);
            ctx.lineTo(416, 690);
            ctx.lineTo(256, 690);
            ctx.lineTo(136, 570);
            ctx.lineTo(136, 410);
            ctx.closePath();
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.lineWidth = 1;
            ctx.moveTo(216, 195);
            ctx.lineTo(456, 785);
            ctx.moveTo(456, 195);
            ctx.lineTo(216, 785);
            ctx.moveTo(631, 370);
            ctx.lineTo(41, 610);
            ctx.moveTo(631, 610);
            ctx.lineTo(41, 370);
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.lineWidth = 1;
            ctx.moveTo(296, 195);
            ctx.lineTo(304, 250);
            ctx.moveTo(376, 195);
            ctx.lineTo(368, 250);
            ctx.moveTo(514.33333333333, 253.33333333333);
            ctx.lineTo(480, 298);
            ctx.moveTo(572.66666666667, 311.66666666667);
            ctx.lineTo(528, 346);
            ctx.moveTo(631, 450);
            ctx.lineTo(576, 458);
            ctx.moveTo(631, 530);
            ctx.lineTo(576, 522);
            ctx.moveTo(572.66666666667, 668.33333333333);
            ctx.lineTo(528, 634);
            ctx.moveTo(514.33333333333, 726.66666666667);
            ctx.lineTo(480, 682);
            ctx.moveTo(376, 785);
            ctx.lineTo(368, 730);
            ctx.moveTo(296, 785);
            ctx.lineTo(304, 730);
            ctx.moveTo(157.66666666667, 726.66666666667);
            ctx.lineTo(192, 682);
            ctx.moveTo(99.333333333333, 668.33333333333);
            ctx.lineTo(144, 634);
            ctx.moveTo(41, 530);
            ctx.lineTo(96, 522);
            ctx.moveTo(41, 450);
            ctx.lineTo(96, 458);
            ctx.moveTo(99.333333333333, 311.66666666667);
            ctx.lineTo(144, 346);
            ctx.moveTo(157.66666666667, 253.33333333333);
            ctx.lineTo(192, 298);
            ctx.stroke();

            ctx.beginPath();
            ctx.strokeStyle = '#888888';
            ctx.lineWidth = 1;
            ctx.moveTo(309.33333333333, 290);
            ctx.lineTo(362.66666666667, 690);
            ctx.moveTo(362.66666666667, 290);
            ctx.lineTo(309.33333333333, 690);
            ctx.moveTo(456, 330);
            ctx.lineTo(216, 650);
            ctx.moveTo(496, 370);
            ctx.lineTo(176, 610);
            ctx.moveTo(536, 463.33333333333);
            ctx.lineTo(136, 516.66666666667);
            ctx.moveTo(536, 516.66666666667);
            ctx.lineTo(136, 463.33333333333);
            ctx.moveTo(496, 610);
            ctx.lineTo(176, 370);
            ctx.moveTo(456, 650);
            ctx.lineTo(216, 330);
            ctx.stroke();

            ctx.beginPath();
            ctx.fillStyle = '#000000';
            ctx.lineWidth = 2;
            ctx.moveTo(306, 415);
            ctx.lineTo(366, 415);
            ctx.lineTo(411, 460);
            ctx.lineTo(411, 520);
            ctx.lineTo(366, 565);
            ctx.lineTo(306, 565);
            ctx.lineTo(261, 520);
            ctx.lineTo(261, 460);
            ctx.closePath();
            ctx.fill();

            ctx.beginPath();
            ctx.globalAlpha = 1.0;
            ctx.fillStyle = 'white';
            ctx.font = 'bold 35px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillText('二黒', 336, 504);
            ctx.stroke();

            ctx.beginPath();
            ctx.globalAlpha = 1.0;
            ctx.fillStyle = 'black';
            ctx.font = 'bold 34px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillText('2043年盤 座相', 336, 60);
            ctx.stroke();

            ctx.beginPath();
            ctx.globalAlpha = 1.0;
            ctx.fillStyle = 'black';
            ctx.font = 'bold 34px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillText('二黒（暦月）癸亥（暦月）', 336, 110);
            ctx.stroke();

            ctx.beginPath();
            ctx.translate(336, 490);
            ctx.fillStyle = 'black';
            ctx.font = 'bold 26px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillText('七赤', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('五黄', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('九紫', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('一白', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('六白', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('八白', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('四緑', 0, -208);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('三碧', 0, -208);
            ctx.stroke();

            ctx.beginPath();
            ctx.translate(0, 0);
            ctx.font = 'bold 34px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'black';
            ctx.fillText('戌', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('乾', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('亥', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('壬', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('子', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('癸', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('丑', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('艮', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('寅', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('甲', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('卯', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('乙', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('辰', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('巽', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('巳', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('丙', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('午', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('丁', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('未', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('坤', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('申', 70, -252);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('庚', -70, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('酉', 0, -252);
            ctx.fillStyle = 'black';
            ctx.fillText('辛', 70, -252);
            ctx.stroke();

            ctx.beginPath();
            ctx.translate(0, 0);
            ctx.fillStyle = 'red';
            ctx.font = 'normal 34px Century Gothic';
            ctx.textAlign = 'center';
            ctx.fillText('　' + yearAnglePlusA, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　' + yearAnglePlusB, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　' + yearAnglePlusC, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　五黄殺　' + yearAnglePlusD, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　' + yearAnglePlusE, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　' + yearAnglePlusF, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　' + yearAnglePlusG, 0, -304);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('　暗剣殺　' + yearAnglePlusH, 0, -304);
            ctx.stroke();

            ctx.beginPath();
            ctx.translate(0, 0);
            ctx.fillStyle = '#11FFFF';
            ctx.font = 'bold 24px Century Gothic';
            ctx.shadowBlur = 2;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.shadowColor = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText(' ', 0, -228);
            ctx.rotate(45 * Math.PI / 180);
            ctx.fillText('恵方　　　　　', 0, -228);
            ctx.stroke();

            ctx.beginPath();
            ctx.translate(0, 0);
            ctx.fillStyle = '#00FF00';
            ctx.font = 'bold 24px Century Gothic';
            ctx.textAlign = 'center';
            ctx.rotate(90 * Math.PI / 180);
            ctx.fillText('西', 0, -46);
            ctx.rotate(90 * Math.PI / 180);
            ctx.fillText('北', 0, -46);
            ctx.rotate(90 * Math.PI / 180);
            ctx.fillText('東', 0, -46);
            ctx.rotate(90 * Math.PI / 180);
            ctx.fillText('南', 0, -46);
            ctx.stroke();

            ctx.beginPath();
            ctx.shadowBlur = 0;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;
            ctx.shadowColor = 'black';
            ctx.translate(0, 0);
            ctx.font = 'normal 20px Century Gothic';
            ctx.textAlign = 'center';
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('天道', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('黄幡', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('蚕室', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('福徳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('天徳合', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('大将', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('人道', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('歳殺', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('太歳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('太陽', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('天道', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('豹尾', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('姫金', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('月徳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('天徳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('歳枝', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('歳破', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('歳徳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('竜徳', 0, -176);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('白虎', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('巡金', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('太陰', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('病符', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('歳刑', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('歳禄', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('歳徳合', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('生気', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('人道', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('死符', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('歳馬', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'blue';
            ctx.fillText('月徳合', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('都天', 0, -157);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('都天', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('大金', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('巡金', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('巡金', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('巡金', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -138);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('劫殺', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'red';
            ctx.fillText('災殺', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -119);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -100);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.rotate(15 * Math.PI / 180);
            ctx.fillStyle = 'black';
            ctx.fillText('　', 0, -81);
            ctx.stroke();


        }
    }
}