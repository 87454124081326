import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Tab, Tabs, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import { TabContext, TabPanel } from '@mui/lab';
import {
    EtoName12RubyA,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const dateJpA: string = `明治`;
const dateJpB: string = `大正`;
const dateJpC: string = `昭和`;
const dateJpD: string = `平成`;
const dateJpE: string = `令和`;

const dateJpExactA: number = 1868;
const dateJpExactB: number = 1912;
const dateJpExactC: number = 1926;
const dateJpExactD: number = 1989;
const dateJpExactE: number = 2019;


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `満年齢早見表`
const pageTitleS = `満年齢早見表`
const uppageTitle = `情報/DB`


interface Koyomi {
    dateY: string;           /* 日付 */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
}

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const todayY = new Date().getFullYear();
const todayM = ("0" + (new Date().getMonth() + 1)).slice(-2); // JavaScriptの月は0から始まるので、1を足して調整
const todayD = ("0" + new Date().getDate()).slice(-2);
// const todayYMD = todayY + todayM + todayD;

// const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 暦月の干支一覧のjsonの呼び出し //////////////////////////////////////////////////////////////////////

    const eto_rekigetsu_json_url = "https://db.suureki.net/_db_etc/eto_all_rekigetsu.json";
    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(eto_rekigetsu_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [eto_rekigetsu_json_url]);
    let eto10yearMap: { [dateY: string]: number } = {};
    let eto12yearMap: { [dateY: string]: number } = {};

    koyomi_data.forEach(koyomi => {
        eto10yearMap[koyomi.dateY] = Number(koyomi.eto10_year);
        eto12yearMap[koyomi.dateY] = Number(koyomi.eto12_year);
    }, [todayY]);


    // 全期間の、年干支と年九星の中身作成 //////////////////////////////////////////////////////////////////////

    const tabCount = 12;    // タブの数
    const yearsPerTab = 10; // タブがカバーする年数
    const rekigetsuTab = [];
    const tabPanels = [];
    const ageChangeContents: { [key: number]: JSX.Element[] } = {};

    let tmpY = todayY;
    let ageCount = 0;
    let ageCountPrev = 0;
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    for (let i = 0; i < tabCount; i++) {
        if (!ageChangeContents[i]) {
            ageChangeContents[i] = [];
        }
        for (let ii = 0; ii < yearsPerTab; ii++) {

            /*
            let dateJpA: string = `明治`;
            let dateJpB: string = `大正`;
            let dateJpC: string = `昭和`;
            let dateJpD: string = `平成`;
            let dateJpE: string = `令和`;
            let dateJpExactA: number = 1868;
            let dateJpExactB: number = 1912;
            let dateJpExactC: number = 1926;
            let dateJpExactD: number = 1989;
            let dateJpExactE: number = 2019;
            */

            let dateJp = 0;
            let dateJpTxt = "";
            if (tmpY === dateJpExactE) {
                dateJpTxt = dateJpE + "元年/" + dateJpD + "31年";
            } else if (tmpY === dateJpExactD) {
                dateJpTxt = dateJpD + "元年/" + dateJpC + "64年";
            } else if (tmpY === dateJpExactC) {
                dateJpTxt = dateJpC + "元年/" + dateJpB + "15年";
            } else if (tmpY === dateJpExactB) {
                dateJpTxt = dateJpB + "元年/" + dateJpA + "45年";
            } else if (tmpY > 2018) {
                dateJp = Number(tmpY) - dateJpExactE + 1;
                dateJpTxt = dateJpE + dateJp + "年";
            } else if (tmpY > 1988) {
                dateJp = Number(tmpY) - dateJpExactD + 1;
                dateJpTxt = dateJpD + dateJp + "年";
            } else if (tmpY > 1925) {
                dateJp = Number(tmpY) - dateJpExactC + 1;
                dateJpTxt = dateJpC + dateJp + "年";
            } else if (tmpY > 1911) {
                dateJp = Number(tmpY) - dateJpExactB + 1;
                dateJpTxt = dateJpB + dateJp + "年";
            } else {
                dateJp = Number(tmpY) - dateJpExactA + 1;
                dateJpTxt = dateJpA + dateJp + "年";
            }


            // 該当日のDateオブジェクトを作成
            const tmpDatePrev = new Date(tmpY, Number(todayM) - 1, Number(todayD)); // 注意: Dateの月は0から始まるため、1を引きます
            // 前日を取得
            tmpDatePrev.setDate(tmpDatePrev.getDate() - 1);
            // 前日の年、月、日を取得
            // const prevY = currentDate.getFullYear();
            const prevM = tmpDatePrev.getMonth() + 1; // 注意: Dateの月は0から始まるため、1を足します
            const prevD = tmpDatePrev.getDate();

            // 該当日のDateオブジェクトを作成
            const tmpDatePost = new Date(tmpY, Number(todayM) - 1, Number(todayD)); // 注意: Dateの月は0から始まるため、1を引きます
            // 翌日を取得
            tmpDatePost.setDate(tmpDatePost.getDate() + 1);
            // 翌日日の年、月、日を取得
            // const prevY = currentDate.getFullYear();
            const postM = tmpDatePost.getMonth() + 1; // 注意: Dateの月は0から始まるため、1を足します
            const postD = tmpDatePost.getDate();

            if (tmpY === todayY) {
                ageChangeContents[i].push(
                    <div key={`menu-${i}-${tmpY}`} style={{ borderBottom: '1px solid #ddd', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                        <div style={{ color: 'green' }}>{tmpY}年生（{dateJpTxt}）：{EtoName12RubyA[eto12yearMap[tmpY]]}年生まれ</div>
                        <div style={{ marginTop: '0.5rem' }}>
                            {todayY - tmpY + 1}歳（数え年）
                        </div>
                        <div style={{ marginTop: '0.5rem' }}>
                            {ageCount}歳（満年齢）
                        </div>
                    </div>
                );
            } else {
                ageChangeContents[i].push(
                    <div key={`menu-${i}-${tmpY}`} style={{ borderBottom: '1px solid #ddd', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                        <div style={{ color: 'green' }}>{tmpY}年生（{dateJpTxt}）：{EtoName12RubyA[eto12yearMap[tmpY]]}年生まれ</div>
                        <div style={{ marginTop: '0.5rem' }}>
                            {todayY - tmpY + 1}歳（数え年）
                        </div>
                        <div style={{ marginTop: '0.5rem' }}>
                            {/* {tmpY}年{todayM}月{todayD}日~12月31日生の人：{ageCountPrev}歳 */}
                            {tmpY}年{('0' + postM).slice(-2)}月{('0' + postD).slice(-2)}日~12月31日生：{ageCountPrev}歳
                        </div>
                        <div style={{ marginBottom: '0.5rem' }}>
                            {/* {tmpY}年01月01日~{('0' + prevM).slice(-2)}月{('0' + prevD).slice(-2)}日生の人：{ageCount}歳 */}
                            {tmpY}年01月01日~{todayM}月{todayD}日生：{ageCount}歳
                        </div>
                    </div>
                );
            }
            tmpY--;
            ageCount++;
            ageCountPrev = ageCount - 1;
        }
    }


    // 全期間の、年干支と年九星の中身+外枠の作成 //////////////////////////////////////////////////////////////////////

    for (let i = 0; i < tabCount; i++) {
        const startYear = todayY - i * yearsPerTab;
        const startYearE = startYear - yearsPerTab + 1;
        // const endYear = startYear + yearsPerTab - 1;

        rekigetsuTab.push(
            <Tab label={`${startYear}-${startYearE}年生`} value={`${i + 1}`
            } key={i} />
        );

        tabPanels.push(
            <TabPanel value={`${i + 1}`} key={i}>
                {ageChangeContents[i]}
            </TabPanel>
        );
    }


    const mainContents = [
        <div key='maincontentskey'>
            <h2 className='content_subtitle'>年齢早見表切替</h2>
            <div>
                <Stack spacing={2} direction="row" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="ageLinkA" color="primary" to={`/archives/db_koyomihenkan`} onClick={handleClick}>
                        <Button variant="contained"><CheckBoxIcon style={{ marginRight: '0.5rem' }} />満年齢の年齢</Button>
                    </Link>
                    <Link component={RouterLink} underline="always" key="ageLinkB" color="primary" to={`/archives/db_countingage`} onClick={handleClick}>
                        <Button variant="outlined"><CheckBoxOutlineBlankIcon style={{ marginRight: '0.5rem' }} />数え年の年齢</Button>
                    </Link>
                </Stack>
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{todayY}年{todayM}月{todayD}日現在の満年齢早見表</h2>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                        {tabPanels}
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                    </TabContext>
                </Box>
            </Box>
        </div>
    ];

    const pageKey = "DBkoyomihenkan";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };


    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseInarticle />
                            </div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <AdSenseMultiplex />
                            </div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;