// src/pages/Archives.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    ZasouExpositionTop,
    ZasouExpositionCommentTop,
    ZasouExpositionTopColor,
    ZasouExpositionGoodA,
    ZasouExpositionGoodB,
    ZasouExpositionBadA,
    ZasouExpositionBadB,
    ZasouExpositionBadC,
    ZasouExpositionGoodCommentA,
    ZasouExpositionGoodCommentB,
    ZasouExpositionBadCommentA,
    ZasouExpositionBadCommentB,
    ZasouExpositionBadCommentC,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

// import { canvas_kyusei_img_years } from './canvasScripts/zasoudata_canvas_1901a';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

let pageTitle = `年盤座相`
const pageTitleS = `座相` // ページトップのナビゲーションリンク


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

const today = new Date();
const dateY = today.getFullYear();
const dateF = dateY + "0101";

declare const require: {
    context(directory: string, useSubdirectories: boolean, regExp: RegExp): any;
};

//// const Application: React.FC = React.memo(() => {

const AspectIndex: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const navigate = useNavigate();

    let searchWindow;
    searchWindow = [
        <Box display="flex" alignItems="center" className='search_space' key='searchKey'>
            年盤の変更：<MobileDatePicker
                views={['year']}
                defaultValue={dayjs(dateF)}
                onChange={(newValue) => {
                    if (newValue) {
                        const formattedDate = newValue.format('YYYY');
                        navigate(`/aspect/${formattedDate}`);
                    }
                }}
                format="YYYY"
                minDate={dayjs('1901-01-01')}
                maxDate={dayjs('2100-12-31')}
            />
        </Box>
    ];


    // // 画面サイズに応じてcanvasScaleを調整する関数
    // function adjustCanvasScale() {
    //     const windowWidth = window.innerWidth;
    //     if (windowWidth <= 480) {
    //         // 小さいスマホ
    //         canvasScale = 0.8;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "360px";
    //         canvastablebgcolor = "#ddd";
    //     } else if (windowWidth <= 768) {
    //         // 中サイズのデバイス
    //         canvasScale = 0.8;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "700px";
    //         canvastablebgcolor = "#ddd";
    //     } else {
    //         // それ以上
    //         canvasScale = 1.0;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "880px";
    //         canvastablebgcolor = "#fff";
    //     }
    // }

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasScale] = useState(0.65);

    const canvasWidth = 680 * canvasScale;
    const canvasheight = 830 * canvasScale;
    const canvastableheight = "550px";
    const canvastablebgcolor = "#ddd";

    useEffect(() => {
        // const filename = "./canvasScripts/zasoudata_canvas.tsx";
        // const filename = `./canvasScripts/zasoudata_canvas_2024a.tsx`;
        const filename = `./canvasScripts/zasoudata_canvas_${dateY}a.tsx`;

        const drawCanvas = async () => {
            if (!canvasRef.current) return;

            try {
                const context = require.context('./canvasScripts', false, /\.tsx$/);
                const module = await context(`./${filename.split('/').pop()}`);


                if (module.default) {
                    // module.default(canvasScale, canvasScale, canvasRef.current);
                    module.default(canvasScale, canvasScale);
                }
            } catch (err) {
                console.error("Failed to load module:", err);
            }
        };

        drawCanvas();
    }, [canvasScale, dateY, encryptedUid]);


    // <canvas id="kyusei_img_years" width={canvasWidth} height={canvasheight}></canvas>

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>今年（{dateY}年）の座相表</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    一般的な座相表の下部北方位と異なり、上部が北方位表示。枠の都合上、一部の漢字3文字を2文字表示。
                </Alert>
                <table style={{ backgroundColor: canvastablebgcolor, width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '200px' }}>
                                <div style={{
                                    width: '100%',
                                    height: canvastableheight,
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <canvas ref={canvasRef} id="kyusei_img_years" width={canvasWidth} height={canvasheight} style={{ maxWidth: 'none' }}></canvas>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>座相の各神様の意味</h2>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    <span>奏書（そうしょ）、</span><span>博士（はくし）、</span><span>月破（げっぱ）、</span><span>的殺（てきさつ）等、</span><span>他にも存在しますが、崇暦では未掲載。</span>
                </Alert>

                <div>
                    <ul>
                        <li>
                            <span style={{ color: ZasouExpositionTopColor[0] }}>{ZasouExpositionTop[0]}</span>
                            <span style={{ marginLeft: '1rem' }}>{ZasouExpositionCommentTop[0]}</span>
                        </li>
                        <ul>
                            {ZasouExpositionGoodA.map((item, index) => (
                                <li key={index} className='inside-list-style'>
                                    <span style={{ color: ZasouExpositionTopColor[0] }}>{item}</span>
                                    <span style={{ marginLeft: '1rem' }}>{ZasouExpositionGoodCommentA[index]}</span>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <span style={{ color: ZasouExpositionTopColor[1] }}>{ZasouExpositionTop[1]}</span>
                            <span style={{ marginLeft: '1rem' }}>{ZasouExpositionCommentTop[1]}</span>
                        </li>
                        <ul>
                            {ZasouExpositionGoodB.map((item, index) => (
                                <li key={index} className='inside-list-style'>
                                    <span style={{ color: ZasouExpositionTopColor[1] }}>{item}</span>
                                    <span style={{ marginLeft: '1rem' }}>{ZasouExpositionGoodCommentB[index]}</span>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <span style={{ color: ZasouExpositionTopColor[2] }}>{ZasouExpositionTop[2]}</span>
                            <span style={{ marginLeft: '1rem' }}>{ZasouExpositionCommentTop[2]}</span>
                        </li>
                        <ul>
                            {ZasouExpositionBadA.map((item, index) => (
                                <li key={index} className='inside-list-style'>
                                    <span style={{ color: ZasouExpositionTopColor[2] }}>{item}</span>
                                    <span style={{ marginLeft: '1rem' }}>{ZasouExpositionBadCommentA[index]}</span>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <span style={{ color: ZasouExpositionTopColor[3] }}>{ZasouExpositionTop[3]}</span>
                            <span style={{ marginLeft: '1rem' }}>{ZasouExpositionCommentTop[3]}</span>
                        </li>
                        <ul>
                            {ZasouExpositionBadB.map((item, index) => (
                                <li key={index} className='inside-list-style'>
                                    <span style={{ color: ZasouExpositionTopColor[3] }}>{item}</span>
                                    <span style={{ marginLeft: '1rem' }}>{ZasouExpositionBadCommentB[index]}</span>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <span style={{ color: ZasouExpositionTopColor[4] }}>{ZasouExpositionTop[4]}</span>
                            <span style={{ marginLeft: '1rem' }}>{ZasouExpositionCommentTop[4]}</span>
                        </li>
                        <ul>
                            {ZasouExpositionBadC.map((item, index) => (
                                <li key={index} className='inside-list-style'>
                                    <span style={{ color: ZasouExpositionTopColor[4] }}>{item}</span>
                                    <span style={{ marginLeft: '1rem' }}>{ZasouExpositionBadCommentC[index]}</span>
                                </li>
                            ))}
                        </ul>
                    </ul>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];


    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "AspectIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    // <AuthProvider>の直下に
    // <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
    // 
    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                            <DemoContainer components={['MobileDatePicker']}>
                                {searchWindow}
                            </DemoContainer>
                        </LocalizationProvider>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem', marginLeft: '0.5rem', fontSize: '1.0rem' }}>
                                <Link
                                    component={RouterLink} underline="always" key="aspect" color="primary" to={`/aspect/${dateY}`} onClick={handleClick}>
                                    今年（{dateY}年）の年盤座相はこちら
                                </Link>
                            </div>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});


const App = () => (
    <AuthProvider>
        <AspectIndex />
    </AuthProvider>
);

export default App;