import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Tab, Tabs, Alert, AlertTitle } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TabContext, TabPanel } from '@mui/lab';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    Ninestar,
    NinestarMini,
    EtoName12RubyA,
    EtoName10,
    EtoName12,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const today = new Date();
const todayY = today.getFullYear();
const todayYbefore = today.getFullYear() - 10;
const todayYafter = today.getFullYear() + 10;

interface Koyomi {
    dateY: string;           /* 日付 */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    kyuusei_y: number;      /* 日九星 */
}

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
const pageTitle = `年干支と年九星の一覧`
const pageTitleS = `年干支/九星一覧` // ページトップのナビゲーションリンク
const uppageTitle = `情報/DB`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/archives"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];



//const DataBase: React.FC = () => {
const DataBase: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };


    // 暦月の干支一覧のjsonの呼び出し //////////////////////////////////////////////////////////////////////

    const eto_rekigetsu_json_url = "https://db.suureki.net/_db_etc/eto_all_rekigetsu.json";
    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    useEffect(() => {
        fetch(eto_rekigetsu_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(koyomi_data => setDataKoyomi(koyomi_data))
            .catch(error => console.error('Error:', error));
    }, [eto_rekigetsu_json_url]);

    let eto10yearMap: { [dateY: string]: number } = {};
    let eto12yearMap: { [dateY: string]: number } = {};
    let kyuuseiyearMap: { [dateY: string]: number } = {};

    koyomi_data.forEach(koyomi => {
        eto10yearMap[koyomi.dateY] = Number(koyomi.eto10_year);
        eto12yearMap[koyomi.dateY] = Number(koyomi.eto12_year);
        kyuuseiyearMap[koyomi.dateY] = Number(koyomi.kyuusei_y);
    }, [todayY]);


    // 10年前から10年後迄の、年干支と年九星 //////////////////////////////////////////////////////////////////////

    const etoContentsA = [];
    for (let i = todayYbefore; i <= todayYafter; i++) {
        let yearColor = 'black';
        if (i === todayY) {
            yearColor = 'green';
        } else {
            yearColor = 'black';
        }
        // 立春以降の九星の算出
        let ii = i + 1;
        if (ii === 9) { ii = 0; }

        etoContentsA.push(
            <div key={i} style={{ borderBottom: '1px solid #ddd', color: yearColor }}>
                {i}年：
                {NinestarMini[kyuuseiyearMap[i]]}
                <span style={{ marginLeft: '0.1rem', marginRight: '0.1rem' }}>/</span>
                {Ninestar[kyuuseiyearMap[ii]]}、
                {EtoName10[eto10yearMap[i]]}
                {EtoName12[eto12yearMap[i]]}（{EtoName12RubyA[eto12yearMap[i]]}）
            </div>
        );
    }

    // 全期間の、年干支と年九星の中身作成 //////////////////////////////////////////////////////////////////////


    const tabCount = 10;    // タブの数
    const yearsPerTab = 20; // タブがカバーする年数
    const rekigetsuTab = [];
    const tabPanels = [];
    const etoContentsB: { [key: number]: JSX.Element[] } = {};

    for (let i = 1901; i <= 2100; i++) {
        const tabIndex = Math.floor((i - 1901) / yearsPerTab);

        let yearColor = 'black';
        if (i === todayY) {
            yearColor = 'green';
        } else {
            yearColor = 'black';
        }
        // 立春以降の九星の算出
        let ii = i + 1;
        if (ii === 9) { ii = 0; }

        if (!etoContentsB[tabIndex]) {
            etoContentsB[tabIndex] = [];
        }
        if (ii === 2101) { kyuuseiyearMap[ii] = 7; } // 2101年は登録されていない為、直接挿入

        etoContentsB[tabIndex].push(
            <div key={i} style={{ borderBottom: '1px solid #ddd', color: yearColor, marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                {i}年：
                {NinestarMini[kyuuseiyearMap[i]]}
                <span style={{ marginLeft: '0.1rem', marginRight: '0.1rem' }}>/</span>
                {Ninestar[kyuuseiyearMap[ii]]}、
                {EtoName10[eto10yearMap[i]]}
                {EtoName12[eto12yearMap[i]]}（{EtoName12RubyA[eto12yearMap[i]]}）
            </div>
        );
    }

    // 全期間の、年干支と年九星の中身+外枠の作成 //////////////////////////////////////////////////////////////////////

    for (let i = 0; i < tabCount; i++) {
        const startYear = 1901 + i * yearsPerTab;
        const endYear = startYear + yearsPerTab - 1;

        rekigetsuTab.push(
            <Tab label={`${startYear}~${endYear}年`} value={`${i + 1}`
            } key={i} />
        );

        tabPanels.push(
            <TabPanel value={`${i + 1}`} key={i} sx={{ marginLeft: '-1.5rem', marginRight: '0rem' }}>
                {etoContentsB[i]}
            </TabPanel>
        );
    }


    const mainContents = [
        <div key='maincontentskey'>
            <Alert severity="info" sx={{ marginTop: '1.5rem' }}>
                <AlertTitle sx={{ fontSize: '0.9rem' }}>年九星は、各年の二十四節気の立春前/立春後の表示です。</AlertTitle>
                <span style={{ color: 'green', marginTop: '0.5rem' }}>今年（{todayY}年）は緑色の文字で表しています。</span>
            </Alert>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>10年前から10年後迄</h2>
                <div>{etoContentsA}</div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>1901年から2100年迄の全期間</h2>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                        {tabPanels}
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable"
                            >
                                {rekigetsuTab}
                            </Tabs>
                        </Box>
                    </TabContext>
                </Box>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];

    const pageKey = "DBrekigetsu";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <DataBase />
    </AuthProvider>
);

export default App;