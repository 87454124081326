import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CelebritySearchIndex = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/celebrity');
    }, [navigate]);

    return (
        <div>
            {/* その他のコンテンツ */}
        </div>
    );
};

export default CelebritySearchIndex;
