import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Jump: React.FC = () => {
    const navigate = useNavigate();
    const { pageName } = useParams<Record<string, string>>();

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear().toString();
        const month = String(today.getMonth() + 1).padStart(2, '0');  // JavaScriptの月は0から始まるため、+1します
        const day = String(today.getDate()).padStart(2, '0');

        let dateString = '';
        switch (pageName) {
            case 'year':
                dateString = year;
                break;
            case 'month':
                dateString = `${year}${month}`;
                break;
            case 'day':
                dateString = `${year}${month}${day}`;
                break;
            default:
                console.error('Invalid pageName provided');
                return;
        }

        navigate(`/calendar/${dateString}`);
    }, [pageName, navigate]);  // useEffect内で使用している依存性を配列に含める

    return null;  // このコンポーネントは何もレンダリングしません
};

export default Jump;
