type MonthLastDayType = Record<string, string>;
export const MonthLastDay = {
    "190101": "31",
    "190102": "28",
    "190103": "31",
    "190104": "30",
    "190105": "31",
    "190106": "30",
    "190107": "31",
    "190108": "31",
    "190109": "30",
    "190110": "31",
    "190111": "30",
    "190112": "31",
    "190201": "31",
    "190202": "28",
    "190203": "31",
    "190204": "30",
    "190205": "31",
    "190206": "30",
    "190207": "31",
    "190208": "31",
    "190209": "30",
    "190210": "31",
    "190211": "30",
    "190212": "31",
    "190301": "31",
    "190302": "28",
    "190303": "31",
    "190304": "30",
    "190305": "31",
    "190306": "30",
    "190307": "31",
    "190308": "31",
    "190309": "30",
    "190310": "31",
    "190311": "30",
    "190312": "31",
    "190401": "31",
    "190402": "29",
    "190403": "31",
    "190404": "30",
    "190405": "31",
    "190406": "30",
    "190407": "31",
    "190408": "31",
    "190409": "30",
    "190410": "31",
    "190411": "30",
    "190412": "31",
    "190501": "31",
    "190502": "28",
    "190503": "31",
    "190504": "30",
    "190505": "31",
    "190506": "30",
    "190507": "31",
    "190508": "31",
    "190509": "30",
    "190510": "31",
    "190511": "30",
    "190512": "31",
    "190601": "31",
    "190602": "28",
    "190603": "31",
    "190604": "30",
    "190605": "31",
    "190606": "30",
    "190607": "31",
    "190608": "31",
    "190609": "30",
    "190610": "31",
    "190611": "30",
    "190612": "31",
    "190701": "31",
    "190702": "28",
    "190703": "31",
    "190704": "30",
    "190705": "31",
    "190706": "30",
    "190707": "31",
    "190708": "31",
    "190709": "30",
    "190710": "31",
    "190711": "30",
    "190712": "31",
    "190801": "31",
    "190802": "29",
    "190803": "31",
    "190804": "30",
    "190805": "31",
    "190806": "30",
    "190807": "31",
    "190808": "31",
    "190809": "30",
    "190810": "31",
    "190811": "30",
    "190812": "31",
    "190901": "31",
    "190902": "28",
    "190903": "31",
    "190904": "30",
    "190905": "31",
    "190906": "30",
    "190907": "31",
    "190908": "31",
    "190909": "30",
    "190910": "31",
    "190911": "30",
    "190912": "31",
    "191001": "31",
    "191002": "28",
    "191003": "31",
    "191004": "30",
    "191005": "31",
    "191006": "30",
    "191007": "31",
    "191008": "31",
    "191009": "30",
    "191010": "31",
    "191011": "30",
    "191012": "31",
    "191101": "31",
    "191102": "28",
    "191103": "31",
    "191104": "30",
    "191105": "31",
    "191106": "30",
    "191107": "31",
    "191108": "31",
    "191109": "30",
    "191110": "31",
    "191111": "30",
    "191112": "31",
    "191201": "31",
    "191202": "29",
    "191203": "31",
    "191204": "30",
    "191205": "31",
    "191206": "30",
    "191207": "31",
    "191208": "31",
    "191209": "30",
    "191210": "31",
    "191211": "30",
    "191212": "31",
    "191301": "31",
    "191302": "28",
    "191303": "31",
    "191304": "30",
    "191305": "31",
    "191306": "30",
    "191307": "31",
    "191308": "31",
    "191309": "30",
    "191310": "31",
    "191311": "30",
    "191312": "31",
    "191401": "31",
    "191402": "28",
    "191403": "31",
    "191404": "30",
    "191405": "31",
    "191406": "30",
    "191407": "31",
    "191408": "31",
    "191409": "30",
    "191410": "31",
    "191411": "30",
    "191412": "31",
    "191501": "31",
    "191502": "28",
    "191503": "31",
    "191504": "30",
    "191505": "31",
    "191506": "30",
    "191507": "31",
    "191508": "31",
    "191509": "30",
    "191510": "31",
    "191511": "30",
    "191512": "31",
    "191601": "31",
    "191602": "29",
    "191603": "31",
    "191604": "30",
    "191605": "31",
    "191606": "30",
    "191607": "31",
    "191608": "31",
    "191609": "30",
    "191610": "31",
    "191611": "30",
    "191612": "31",
    "191701": "31",
    "191702": "28",
    "191703": "31",
    "191704": "30",
    "191705": "31",
    "191706": "30",
    "191707": "31",
    "191708": "31",
    "191709": "30",
    "191710": "31",
    "191711": "30",
    "191712": "31",
    "191801": "31",
    "191802": "28",
    "191803": "31",
    "191804": "30",
    "191805": "31",
    "191806": "30",
    "191807": "31",
    "191808": "31",
    "191809": "30",
    "191810": "31",
    "191811": "30",
    "191812": "31",
    "191901": "31",
    "191902": "28",
    "191903": "31",
    "191904": "30",
    "191905": "31",
    "191906": "30",
    "191907": "31",
    "191908": "31",
    "191909": "30",
    "191910": "31",
    "191911": "30",
    "191912": "31",
    "192001": "31",
    "192002": "29",
    "192003": "31",
    "192004": "30",
    "192005": "31",
    "192006": "30",
    "192007": "31",
    "192008": "31",
    "192009": "30",
    "192010": "31",
    "192011": "30",
    "192012": "31",
    "192101": "31",
    "192102": "28",
    "192103": "31",
    "192104": "30",
    "192105": "31",
    "192106": "30",
    "192107": "31",
    "192108": "31",
    "192109": "30",
    "192110": "31",
    "192111": "30",
    "192112": "31",
    "192201": "31",
    "192202": "28",
    "192203": "31",
    "192204": "30",
    "192205": "31",
    "192206": "30",
    "192207": "31",
    "192208": "31",
    "192209": "30",
    "192210": "31",
    "192211": "30",
    "192212": "31",
    "192301": "31",
    "192302": "28",
    "192303": "31",
    "192304": "30",
    "192305": "31",
    "192306": "30",
    "192307": "31",
    "192308": "31",
    "192309": "30",
    "192310": "31",
    "192311": "30",
    "192312": "31",
    "192401": "31",
    "192402": "29",
    "192403": "31",
    "192404": "30",
    "192405": "31",
    "192406": "30",
    "192407": "31",
    "192408": "31",
    "192409": "30",
    "192410": "31",
    "192411": "30",
    "192412": "31",
    "192501": "31",
    "192502": "28",
    "192503": "31",
    "192504": "30",
    "192505": "31",
    "192506": "30",
    "192507": "31",
    "192508": "31",
    "192509": "30",
    "192510": "31",
    "192511": "30",
    "192512": "31",
    "192601": "31",
    "192602": "28",
    "192603": "31",
    "192604": "30",
    "192605": "31",
    "192606": "30",
    "192607": "31",
    "192608": "31",
    "192609": "30",
    "192610": "31",
    "192611": "30",
    "192612": "31",
    "192701": "31",
    "192702": "28",
    "192703": "31",
    "192704": "30",
    "192705": "31",
    "192706": "30",
    "192707": "31",
    "192708": "31",
    "192709": "30",
    "192710": "31",
    "192711": "30",
    "192712": "31",
    "192801": "31",
    "192802": "29",
    "192803": "31",
    "192804": "30",
    "192805": "31",
    "192806": "30",
    "192807": "31",
    "192808": "31",
    "192809": "30",
    "192810": "31",
    "192811": "30",
    "192812": "31",
    "192901": "31",
    "192902": "28",
    "192903": "31",
    "192904": "30",
    "192905": "31",
    "192906": "30",
    "192907": "31",
    "192908": "31",
    "192909": "30",
    "192910": "31",
    "192911": "30",
    "192912": "31",
    "193001": "31",
    "193002": "28",
    "193003": "31",
    "193004": "30",
    "193005": "31",
    "193006": "30",
    "193007": "31",
    "193008": "31",
    "193009": "30",
    "193010": "31",
    "193011": "30",
    "193012": "31",
    "193101": "31",
    "193102": "28",
    "193103": "31",
    "193104": "30",
    "193105": "31",
    "193106": "30",
    "193107": "31",
    "193108": "31",
    "193109": "30",
    "193110": "31",
    "193111": "30",
    "193112": "31",
    "193201": "31",
    "193202": "29",
    "193203": "31",
    "193204": "30",
    "193205": "31",
    "193206": "30",
    "193207": "31",
    "193208": "31",
    "193209": "30",
    "193210": "31",
    "193211": "30",
    "193212": "31",
    "193301": "31",
    "193302": "28",
    "193303": "31",
    "193304": "30",
    "193305": "31",
    "193306": "30",
    "193307": "31",
    "193308": "31",
    "193309": "30",
    "193310": "31",
    "193311": "30",
    "193312": "31",
    "193401": "31",
    "193402": "28",
    "193403": "31",
    "193404": "30",
    "193405": "31",
    "193406": "30",
    "193407": "31",
    "193408": "31",
    "193409": "30",
    "193410": "31",
    "193411": "30",
    "193412": "31",
    "193501": "31",
    "193502": "28",
    "193503": "31",
    "193504": "30",
    "193505": "31",
    "193506": "30",
    "193507": "31",
    "193508": "31",
    "193509": "30",
    "193510": "31",
    "193511": "30",
    "193512": "31",
    "193601": "31",
    "193602": "29",
    "193603": "31",
    "193604": "30",
    "193605": "31",
    "193606": "30",
    "193607": "31",
    "193608": "31",
    "193609": "30",
    "193610": "31",
    "193611": "30",
    "193612": "31",
    "193701": "31",
    "193702": "28",
    "193703": "31",
    "193704": "30",
    "193705": "31",
    "193706": "30",
    "193707": "31",
    "193708": "31",
    "193709": "30",
    "193710": "31",
    "193711": "30",
    "193712": "31",
    "193801": "31",
    "193802": "28",
    "193803": "31",
    "193804": "30",
    "193805": "31",
    "193806": "30",
    "193807": "31",
    "193808": "31",
    "193809": "30",
    "193810": "31",
    "193811": "30",
    "193812": "31",
    "193901": "31",
    "193902": "28",
    "193903": "31",
    "193904": "30",
    "193905": "31",
    "193906": "30",
    "193907": "31",
    "193908": "31",
    "193909": "30",
    "193910": "31",
    "193911": "30",
    "193912": "31",
    "194001": "31",
    "194002": "29",
    "194003": "31",
    "194004": "30",
    "194005": "31",
    "194006": "30",
    "194007": "31",
    "194008": "31",
    "194009": "30",
    "194010": "31",
    "194011": "30",
    "194012": "31",
    "194101": "31",
    "194102": "28",
    "194103": "31",
    "194104": "30",
    "194105": "31",
    "194106": "30",
    "194107": "31",
    "194108": "31",
    "194109": "30",
    "194110": "31",
    "194111": "30",
    "194112": "31",
    "194201": "31",
    "194202": "28",
    "194203": "31",
    "194204": "30",
    "194205": "31",
    "194206": "30",
    "194207": "31",
    "194208": "31",
    "194209": "30",
    "194210": "31",
    "194211": "30",
    "194212": "31",
    "194301": "31",
    "194302": "28",
    "194303": "31",
    "194304": "30",
    "194305": "31",
    "194306": "30",
    "194307": "31",
    "194308": "31",
    "194309": "30",
    "194310": "31",
    "194311": "30",
    "194312": "31",
    "194401": "31",
    "194402": "29",
    "194403": "31",
    "194404": "30",
    "194405": "31",
    "194406": "30",
    "194407": "31",
    "194408": "31",
    "194409": "30",
    "194410": "31",
    "194411": "30",
    "194412": "31",
    "194501": "31",
    "194502": "28",
    "194503": "31",
    "194504": "30",
    "194505": "31",
    "194506": "30",
    "194507": "31",
    "194508": "31",
    "194509": "30",
    "194510": "31",
    "194511": "30",
    "194512": "31",
    "194601": "31",
    "194602": "28",
    "194603": "31",
    "194604": "30",
    "194605": "31",
    "194606": "30",
    "194607": "31",
    "194608": "31",
    "194609": "30",
    "194610": "31",
    "194611": "30",
    "194612": "31",
    "194701": "31",
    "194702": "28",
    "194703": "31",
    "194704": "30",
    "194705": "31",
    "194706": "30",
    "194707": "31",
    "194708": "31",
    "194709": "30",
    "194710": "31",
    "194711": "30",
    "194712": "31",
    "194801": "31",
    "194802": "29",
    "194803": "31",
    "194804": "30",
    "194805": "31",
    "194806": "30",
    "194807": "31",
    "194808": "31",
    "194809": "30",
    "194810": "31",
    "194811": "30",
    "194812": "31",
    "194901": "31",
    "194902": "28",
    "194903": "31",
    "194904": "30",
    "194905": "31",
    "194906": "30",
    "194907": "31",
    "194908": "31",
    "194909": "30",
    "194910": "31",
    "194911": "30",
    "194912": "31",
    "195001": "31",
    "195002": "28",
    "195003": "31",
    "195004": "30",
    "195005": "31",
    "195006": "30",
    "195007": "31",
    "195008": "31",
    "195009": "30",
    "195010": "31",
    "195011": "30",
    "195012": "31",
    "195101": "31",
    "195102": "28",
    "195103": "31",
    "195104": "30",
    "195105": "31",
    "195106": "30",
    "195107": "31",
    "195108": "31",
    "195109": "30",
    "195110": "31",
    "195111": "30",
    "195112": "31",
    "195201": "31",
    "195202": "29",
    "195203": "31",
    "195204": "30",
    "195205": "31",
    "195206": "30",
    "195207": "31",
    "195208": "31",
    "195209": "30",
    "195210": "31",
    "195211": "30",
    "195212": "31",
    "195301": "31",
    "195302": "28",
    "195303": "31",
    "195304": "30",
    "195305": "31",
    "195306": "30",
    "195307": "31",
    "195308": "31",
    "195309": "30",
    "195310": "31",
    "195311": "30",
    "195312": "31",
    "195401": "31",
    "195402": "28",
    "195403": "31",
    "195404": "30",
    "195405": "31",
    "195406": "30",
    "195407": "31",
    "195408": "31",
    "195409": "30",
    "195410": "31",
    "195411": "30",
    "195412": "31",
    "195501": "31",
    "195502": "28",
    "195503": "31",
    "195504": "30",
    "195505": "31",
    "195506": "30",
    "195507": "31",
    "195508": "31",
    "195509": "30",
    "195510": "31",
    "195511": "30",
    "195512": "31",
    "195601": "31",
    "195602": "29",
    "195603": "31",
    "195604": "30",
    "195605": "31",
    "195606": "30",
    "195607": "31",
    "195608": "31",
    "195609": "30",
    "195610": "31",
    "195611": "30",
    "195612": "31",
    "195701": "31",
    "195702": "28",
    "195703": "31",
    "195704": "30",
    "195705": "31",
    "195706": "30",
    "195707": "31",
    "195708": "31",
    "195709": "30",
    "195710": "31",
    "195711": "30",
    "195712": "31",
    "195801": "31",
    "195802": "28",
    "195803": "31",
    "195804": "30",
    "195805": "31",
    "195806": "30",
    "195807": "31",
    "195808": "31",
    "195809": "30",
    "195810": "31",
    "195811": "30",
    "195812": "31",
    "195901": "31",
    "195902": "28",
    "195903": "31",
    "195904": "30",
    "195905": "31",
    "195906": "30",
    "195907": "31",
    "195908": "31",
    "195909": "30",
    "195910": "31",
    "195911": "30",
    "195912": "31",
    "196001": "31",
    "196002": "29",
    "196003": "31",
    "196004": "30",
    "196005": "31",
    "196006": "30",
    "196007": "31",
    "196008": "31",
    "196009": "30",
    "196010": "31",
    "196011": "30",
    "196012": "31",
    "196101": "31",
    "196102": "28",
    "196103": "31",
    "196104": "30",
    "196105": "31",
    "196106": "30",
    "196107": "31",
    "196108": "31",
    "196109": "30",
    "196110": "31",
    "196111": "30",
    "196112": "31",
    "196201": "31",
    "196202": "28",
    "196203": "31",
    "196204": "30",
    "196205": "31",
    "196206": "30",
    "196207": "31",
    "196208": "31",
    "196209": "30",
    "196210": "31",
    "196211": "30",
    "196212": "31",
    "196301": "31",
    "196302": "28",
    "196303": "31",
    "196304": "30",
    "196305": "31",
    "196306": "30",
    "196307": "31",
    "196308": "31",
    "196309": "30",
    "196310": "31",
    "196311": "30",
    "196312": "31",
    "196401": "31",
    "196402": "29",
    "196403": "31",
    "196404": "30",
    "196405": "31",
    "196406": "30",
    "196407": "31",
    "196408": "31",
    "196409": "30",
    "196410": "31",
    "196411": "30",
    "196412": "31",
    "196501": "31",
    "196502": "28",
    "196503": "31",
    "196504": "30",
    "196505": "31",
    "196506": "30",
    "196507": "31",
    "196508": "31",
    "196509": "30",
    "196510": "31",
    "196511": "30",
    "196512": "31",
    "196601": "31",
    "196602": "28",
    "196603": "31",
    "196604": "30",
    "196605": "31",
    "196606": "30",
    "196607": "31",
    "196608": "31",
    "196609": "30",
    "196610": "31",
    "196611": "30",
    "196612": "31",
    "196701": "31",
    "196702": "28",
    "196703": "31",
    "196704": "30",
    "196705": "31",
    "196706": "30",
    "196707": "31",
    "196708": "31",
    "196709": "30",
    "196710": "31",
    "196711": "30",
    "196712": "31",
    "196801": "31",
    "196802": "29",
    "196803": "31",
    "196804": "30",
    "196805": "31",
    "196806": "30",
    "196807": "31",
    "196808": "31",
    "196809": "30",
    "196810": "31",
    "196811": "30",
    "196812": "31",
    "196901": "31",
    "196902": "28",
    "196903": "31",
    "196904": "30",
    "196905": "31",
    "196906": "30",
    "196907": "31",
    "196908": "31",
    "196909": "30",
    "196910": "31",
    "196911": "30",
    "196912": "31",
    "197001": "31",
    "197002": "28",
    "197003": "31",
    "197004": "30",
    "197005": "31",
    "197006": "30",
    "197007": "31",
    "197008": "31",
    "197009": "30",
    "197010": "31",
    "197011": "30",
    "197012": "31",
    "197101": "31",
    "197102": "28",
    "197103": "31",
    "197104": "30",
    "197105": "31",
    "197106": "30",
    "197107": "31",
    "197108": "31",
    "197109": "30",
    "197110": "31",
    "197111": "30",
    "197112": "31",
    "197201": "31",
    "197202": "29",
    "197203": "31",
    "197204": "30",
    "197205": "31",
    "197206": "30",
    "197207": "31",
    "197208": "31",
    "197209": "30",
    "197210": "31",
    "197211": "30",
    "197212": "31",
    "197301": "31",
    "197302": "28",
    "197303": "31",
    "197304": "30",
    "197305": "31",
    "197306": "30",
    "197307": "31",
    "197308": "31",
    "197309": "30",
    "197310": "31",
    "197311": "30",
    "197312": "31",
    "197401": "31",
    "197402": "28",
    "197403": "31",
    "197404": "30",
    "197405": "31",
    "197406": "30",
    "197407": "31",
    "197408": "31",
    "197409": "30",
    "197410": "31",
    "197411": "30",
    "197412": "31",
    "197501": "31",
    "197502": "28",
    "197503": "31",
    "197504": "30",
    "197505": "31",
    "197506": "30",
    "197507": "31",
    "197508": "31",
    "197509": "30",
    "197510": "31",
    "197511": "30",
    "197512": "31",
    "197601": "31",
    "197602": "29",
    "197603": "31",
    "197604": "30",
    "197605": "31",
    "197606": "30",
    "197607": "31",
    "197608": "31",
    "197609": "30",
    "197610": "31",
    "197611": "30",
    "197612": "31",
    "197701": "31",
    "197702": "28",
    "197703": "31",
    "197704": "30",
    "197705": "31",
    "197706": "30",
    "197707": "31",
    "197708": "31",
    "197709": "30",
    "197710": "31",
    "197711": "30",
    "197712": "31",
    "197801": "31",
    "197802": "28",
    "197803": "31",
    "197804": "30",
    "197805": "31",
    "197806": "30",
    "197807": "31",
    "197808": "31",
    "197809": "30",
    "197810": "31",
    "197811": "30",
    "197812": "31",
    "197901": "31",
    "197902": "28",
    "197903": "31",
    "197904": "30",
    "197905": "31",
    "197906": "30",
    "197907": "31",
    "197908": "31",
    "197909": "30",
    "197910": "31",
    "197911": "30",
    "197912": "31",
    "198001": "31",
    "198002": "29",
    "198003": "31",
    "198004": "30",
    "198005": "31",
    "198006": "30",
    "198007": "31",
    "198008": "31",
    "198009": "30",
    "198010": "31",
    "198011": "30",
    "198012": "31",
    "198101": "31",
    "198102": "28",
    "198103": "31",
    "198104": "30",
    "198105": "31",
    "198106": "30",
    "198107": "31",
    "198108": "31",
    "198109": "30",
    "198110": "31",
    "198111": "30",
    "198112": "31",
    "198201": "31",
    "198202": "28",
    "198203": "31",
    "198204": "30",
    "198205": "31",
    "198206": "30",
    "198207": "31",
    "198208": "31",
    "198209": "30",
    "198210": "31",
    "198211": "30",
    "198212": "31",
    "198301": "31",
    "198302": "28",
    "198303": "31",
    "198304": "30",
    "198305": "31",
    "198306": "30",
    "198307": "31",
    "198308": "31",
    "198309": "30",
    "198310": "31",
    "198311": "30",
    "198312": "31",
    "198401": "31",
    "198402": "29",
    "198403": "31",
    "198404": "30",
    "198405": "31",
    "198406": "30",
    "198407": "31",
    "198408": "31",
    "198409": "30",
    "198410": "31",
    "198411": "30",
    "198412": "31",
    "198501": "31",
    "198502": "28",
    "198503": "31",
    "198504": "30",
    "198505": "31",
    "198506": "30",
    "198507": "31",
    "198508": "31",
    "198509": "30",
    "198510": "31",
    "198511": "30",
    "198512": "31",
    "198601": "31",
    "198602": "28",
    "198603": "31",
    "198604": "30",
    "198605": "31",
    "198606": "30",
    "198607": "31",
    "198608": "31",
    "198609": "30",
    "198610": "31",
    "198611": "30",
    "198612": "31",
    "198701": "31",
    "198702": "28",
    "198703": "31",
    "198704": "30",
    "198705": "31",
    "198706": "30",
    "198707": "31",
    "198708": "31",
    "198709": "30",
    "198710": "31",
    "198711": "30",
    "198712": "31",
    "198801": "31",
    "198802": "29",
    "198803": "31",
    "198804": "30",
    "198805": "31",
    "198806": "30",
    "198807": "31",
    "198808": "31",
    "198809": "30",
    "198810": "31",
    "198811": "30",
    "198812": "31",
    "198901": "31",
    "198902": "28",
    "198903": "31",
    "198904": "30",
    "198905": "31",
    "198906": "30",
    "198907": "31",
    "198908": "31",
    "198909": "30",
    "198910": "31",
    "198911": "30",
    "198912": "31",
    "199001": "31",
    "199002": "28",
    "199003": "31",
    "199004": "30",
    "199005": "31",
    "199006": "30",
    "199007": "31",
    "199008": "31",
    "199009": "30",
    "199010": "31",
    "199011": "30",
    "199012": "31",
    "199101": "31",
    "199102": "28",
    "199103": "31",
    "199104": "30",
    "199105": "31",
    "199106": "30",
    "199107": "31",
    "199108": "31",
    "199109": "30",
    "199110": "31",
    "199111": "30",
    "199112": "31",
    "199201": "31",
    "199202": "29",
    "199203": "31",
    "199204": "30",
    "199205": "31",
    "199206": "30",
    "199207": "31",
    "199208": "31",
    "199209": "30",
    "199210": "31",
    "199211": "30",
    "199212": "31",
    "199301": "31",
    "199302": "28",
    "199303": "31",
    "199304": "30",
    "199305": "31",
    "199306": "30",
    "199307": "31",
    "199308": "31",
    "199309": "30",
    "199310": "31",
    "199311": "30",
    "199312": "31",
    "199401": "31",
    "199402": "28",
    "199403": "31",
    "199404": "30",
    "199405": "31",
    "199406": "30",
    "199407": "31",
    "199408": "31",
    "199409": "30",
    "199410": "31",
    "199411": "30",
    "199412": "31",
    "199501": "31",
    "199502": "28",
    "199503": "31",
    "199504": "30",
    "199505": "31",
    "199506": "30",
    "199507": "31",
    "199508": "31",
    "199509": "30",
    "199510": "31",
    "199511": "30",
    "199512": "31",
    "199601": "31",
    "199602": "29",
    "199603": "31",
    "199604": "30",
    "199605": "31",
    "199606": "30",
    "199607": "31",
    "199608": "31",
    "199609": "30",
    "199610": "31",
    "199611": "30",
    "199612": "31",
    "199701": "31",
    "199702": "28",
    "199703": "31",
    "199704": "30",
    "199705": "31",
    "199706": "30",
    "199707": "31",
    "199708": "31",
    "199709": "30",
    "199710": "31",
    "199711": "30",
    "199712": "31",
    "199801": "31",
    "199802": "28",
    "199803": "31",
    "199804": "30",
    "199805": "31",
    "199806": "30",
    "199807": "31",
    "199808": "31",
    "199809": "30",
    "199810": "31",
    "199811": "30",
    "199812": "31",
    "199901": "31",
    "199902": "28",
    "199903": "31",
    "199904": "30",
    "199905": "31",
    "199906": "30",
    "199907": "31",
    "199908": "31",
    "199909": "30",
    "199910": "31",
    "199911": "30",
    "199912": "31",
    "200001": "31",
    "200002": "29",
    "200003": "31",
    "200004": "30",
    "200005": "31",
    "200006": "30",
    "200007": "31",
    "200008": "31",
    "200009": "30",
    "200010": "31",
    "200011": "30",
    "200012": "31",
    "200101": "31",
    "200102": "28",
    "200103": "31",
    "200104": "30",
    "200105": "31",
    "200106": "30",
    "200107": "31",
    "200108": "31",
    "200109": "30",
    "200110": "31",
    "200111": "30",
    "200112": "31",
    "200201": "31",
    "200202": "28",
    "200203": "31",
    "200204": "30",
    "200205": "31",
    "200206": "30",
    "200207": "31",
    "200208": "31",
    "200209": "30",
    "200210": "31",
    "200211": "30",
    "200212": "31",
    "200301": "31",
    "200302": "28",
    "200303": "31",
    "200304": "30",
    "200305": "31",
    "200306": "30",
    "200307": "31",
    "200308": "31",
    "200309": "30",
    "200310": "31",
    "200311": "30",
    "200312": "31",
    "200401": "31",
    "200402": "29",
    "200403": "31",
    "200404": "30",
    "200405": "31",
    "200406": "30",
    "200407": "31",
    "200408": "31",
    "200409": "30",
    "200410": "31",
    "200411": "30",
    "200412": "31",
    "200501": "31",
    "200502": "28",
    "200503": "31",
    "200504": "30",
    "200505": "31",
    "200506": "30",
    "200507": "31",
    "200508": "31",
    "200509": "30",
    "200510": "31",
    "200511": "30",
    "200512": "31",
    "200601": "31",
    "200602": "28",
    "200603": "31",
    "200604": "30",
    "200605": "31",
    "200606": "30",
    "200607": "31",
    "200608": "31",
    "200609": "30",
    "200610": "31",
    "200611": "30",
    "200612": "31",
    "200701": "31",
    "200702": "28",
    "200703": "31",
    "200704": "30",
    "200705": "31",
    "200706": "30",
    "200707": "31",
    "200708": "31",
    "200709": "30",
    "200710": "31",
    "200711": "30",
    "200712": "31",
    "200801": "31",
    "200802": "29",
    "200803": "31",
    "200804": "30",
    "200805": "31",
    "200806": "30",
    "200807": "31",
    "200808": "31",
    "200809": "30",
    "200810": "31",
    "200811": "30",
    "200812": "31",
    "200901": "31",
    "200902": "28",
    "200903": "31",
    "200904": "30",
    "200905": "31",
    "200906": "30",
    "200907": "31",
    "200908": "31",
    "200909": "30",
    "200910": "31",
    "200911": "30",
    "200912": "31",
    "201001": "31",
    "201002": "28",
    "201003": "31",
    "201004": "30",
    "201005": "31",
    "201006": "30",
    "201007": "31",
    "201008": "31",
    "201009": "30",
    "201010": "31",
    "201011": "30",
    "201012": "31",
    "201101": "31",
    "201102": "28",
    "201103": "31",
    "201104": "30",
    "201105": "31",
    "201106": "30",
    "201107": "31",
    "201108": "31",
    "201109": "30",
    "201110": "31",
    "201111": "30",
    "201112": "31",
    "201201": "31",
    "201202": "29",
    "201203": "31",
    "201204": "30",
    "201205": "31",
    "201206": "30",
    "201207": "31",
    "201208": "31",
    "201209": "30",
    "201210": "31",
    "201211": "30",
    "201212": "31",
    "201301": "31",
    "201302": "28",
    "201303": "31",
    "201304": "30",
    "201305": "31",
    "201306": "30",
    "201307": "31",
    "201308": "31",
    "201309": "30",
    "201310": "31",
    "201311": "30",
    "201312": "31",
    "201401": "31",
    "201402": "28",
    "201403": "31",
    "201404": "30",
    "201405": "31",
    "201406": "30",
    "201407": "31",
    "201408": "31",
    "201409": "30",
    "201410": "31",
    "201411": "30",
    "201412": "31",
    "201501": "31",
    "201502": "28",
    "201503": "31",
    "201504": "30",
    "201505": "31",
    "201506": "30",
    "201507": "31",
    "201508": "31",
    "201509": "30",
    "201510": "31",
    "201511": "30",
    "201512": "31",
    "201601": "31",
    "201602": "29",
    "201603": "31",
    "201604": "30",
    "201605": "31",
    "201606": "30",
    "201607": "31",
    "201608": "31",
    "201609": "30",
    "201610": "31",
    "201611": "30",
    "201612": "31",
    "201701": "31",
    "201702": "28",
    "201703": "31",
    "201704": "30",
    "201705": "31",
    "201706": "30",
    "201707": "31",
    "201708": "31",
    "201709": "30",
    "201710": "31",
    "201711": "30",
    "201712": "31",
    "201801": "31",
    "201802": "28",
    "201803": "31",
    "201804": "30",
    "201805": "31",
    "201806": "30",
    "201807": "31",
    "201808": "31",
    "201809": "30",
    "201810": "31",
    "201811": "30",
    "201812": "31",
    "201901": "31",
    "201902": "28",
    "201903": "31",
    "201904": "30",
    "201905": "31",
    "201906": "30",
    "201907": "31",
    "201908": "31",
    "201909": "30",
    "201910": "31",
    "201911": "30",
    "201912": "31",
    "202001": "31",
    "202002": "29",
    "202003": "31",
    "202004": "30",
    "202005": "31",
    "202006": "30",
    "202007": "31",
    "202008": "31",
    "202009": "30",
    "202010": "31",
    "202011": "30",
    "202012": "31",
    "202101": "31",
    "202102": "28",
    "202103": "31",
    "202104": "30",
    "202105": "31",
    "202106": "30",
    "202107": "31",
    "202108": "31",
    "202109": "30",
    "202110": "31",
    "202111": "30",
    "202112": "31",
    "202201": "31",
    "202202": "28",
    "202203": "31",
    "202204": "30",
    "202205": "31",
    "202206": "30",
    "202207": "31",
    "202208": "31",
    "202209": "30",
    "202210": "31",
    "202211": "30",
    "202212": "31",
    "202301": "31",
    "202302": "28",
    "202303": "31",
    "202304": "30",
    "202305": "31",
    "202306": "30",
    "202307": "31",
    "202308": "31",
    "202309": "30",
    "202310": "31",
    "202311": "30",
    "202312": "31",
    "202401": "31",
    "202402": "29",
    "202403": "31",
    "202404": "30",
    "202405": "31",
    "202406": "30",
    "202407": "31",
    "202408": "31",
    "202409": "30",
    "202410": "31",
    "202411": "30",
    "202412": "31",
    "202501": "31",
    "202502": "28",
    "202503": "31",
    "202504": "30",
    "202505": "31",
    "202506": "30",
    "202507": "31",
    "202508": "31",
    "202509": "30",
    "202510": "31",
    "202511": "30",
    "202512": "31",
    "202601": "31",
    "202602": "28",
    "202603": "31",
    "202604": "30",
    "202605": "31",
    "202606": "30",
    "202607": "31",
    "202608": "31",
    "202609": "30",
    "202610": "31",
    "202611": "30",
    "202612": "31",
    "202701": "31",
    "202702": "28",
    "202703": "31",
    "202704": "30",
    "202705": "31",
    "202706": "30",
    "202707": "31",
    "202708": "31",
    "202709": "30",
    "202710": "31",
    "202711": "30",
    "202712": "31",
    "202801": "31",
    "202802": "29",
    "202803": "31",
    "202804": "30",
    "202805": "31",
    "202806": "30",
    "202807": "31",
    "202808": "31",
    "202809": "30",
    "202810": "31",
    "202811": "30",
    "202812": "31",
    "202901": "31",
    "202902": "28",
    "202903": "31",
    "202904": "30",
    "202905": "31",
    "202906": "30",
    "202907": "31",
    "202908": "31",
    "202909": "30",
    "202910": "31",
    "202911": "30",
    "202912": "31",
    "203001": "31",
    "203002": "28",
    "203003": "31",
    "203004": "30",
    "203005": "31",
    "203006": "30",
    "203007": "31",
    "203008": "31",
    "203009": "30",
    "203010": "31",
    "203011": "30",
    "203012": "31",
    "203101": "31",
    "203102": "28",
    "203103": "31",
    "203104": "30",
    "203105": "31",
    "203106": "30",
    "203107": "31",
    "203108": "31",
    "203109": "30",
    "203110": "31",
    "203111": "30",
    "203112": "31",
    "203201": "31",
    "203202": "29",
    "203203": "31",
    "203204": "30",
    "203205": "31",
    "203206": "30",
    "203207": "31",
    "203208": "31",
    "203209": "30",
    "203210": "31",
    "203211": "30",
    "203212": "31",
    "203301": "31",
    "203302": "28",
    "203303": "31",
    "203304": "30",
    "203305": "31",
    "203306": "30",
    "203307": "31",
    "203308": "31",
    "203309": "30",
    "203310": "31",
    "203311": "30",
    "203312": "31",
    "203401": "31",
    "203402": "28",
    "203403": "31",
    "203404": "30",
    "203405": "31",
    "203406": "30",
    "203407": "31",
    "203408": "31",
    "203409": "30",
    "203410": "31",
    "203411": "30",
    "203412": "31",
    "203501": "31",
    "203502": "28",
    "203503": "31",
    "203504": "30",
    "203505": "31",
    "203506": "30",
    "203507": "31",
    "203508": "31",
    "203509": "30",
    "203510": "31",
    "203511": "30",
    "203512": "31",
    "203601": "31",
    "203602": "29",
    "203603": "31",
    "203604": "30",
    "203605": "31",
    "203606": "30",
    "203607": "31",
    "203608": "31",
    "203609": "30",
    "203610": "31",
    "203611": "30",
    "203612": "31",
    "203701": "31",
    "203702": "28",
    "203703": "31",
    "203704": "30",
    "203705": "31",
    "203706": "30",
    "203707": "31",
    "203708": "31",
    "203709": "30",
    "203710": "31",
    "203711": "30",
    "203712": "31",
    "203801": "31",
    "203802": "28",
    "203803": "31",
    "203804": "30",
    "203805": "31",
    "203806": "30",
    "203807": "31",
    "203808": "31",
    "203809": "30",
    "203810": "31",
    "203811": "30",
    "203812": "31",
    "203901": "31",
    "203902": "28",
    "203903": "31",
    "203904": "30",
    "203905": "31",
    "203906": "30",
    "203907": "31",
    "203908": "31",
    "203909": "30",
    "203910": "31",
    "203911": "30",
    "203912": "31",
    "204001": "31",
    "204002": "29",
    "204003": "31",
    "204004": "30",
    "204005": "31",
    "204006": "30",
    "204007": "31",
    "204008": "31",
    "204009": "30",
    "204010": "31",
    "204011": "30",
    "204012": "31",
    "204101": "31",
    "204102": "28",
    "204103": "31",
    "204104": "30",
    "204105": "31",
    "204106": "30",
    "204107": "31",
    "204108": "31",
    "204109": "30",
    "204110": "31",
    "204111": "30",
    "204112": "31",
    "204201": "31",
    "204202": "28",
    "204203": "31",
    "204204": "30",
    "204205": "31",
    "204206": "30",
    "204207": "31",
    "204208": "31",
    "204209": "30",
    "204210": "31",
    "204211": "30",
    "204212": "31",
    "204301": "31",
    "204302": "28",
    "204303": "31",
    "204304": "30",
    "204305": "31",
    "204306": "30",
    "204307": "31",
    "204308": "31",
    "204309": "30",
    "204310": "31",
    "204311": "30",
    "204312": "31",
    "204401": "31",
    "204402": "29",
    "204403": "31",
    "204404": "30",
    "204405": "31",
    "204406": "30",
    "204407": "31",
    "204408": "31",
    "204409": "30",
    "204410": "31",
    "204411": "30",
    "204412": "31",
    "204501": "31",
    "204502": "28",
    "204503": "31",
    "204504": "30",
    "204505": "31",
    "204506": "30",
    "204507": "31",
    "204508": "31",
    "204509": "30",
    "204510": "31",
    "204511": "30",
    "204512": "31",
    "204601": "31",
    "204602": "28",
    "204603": "31",
    "204604": "30",
    "204605": "31",
    "204606": "30",
    "204607": "31",
    "204608": "31",
    "204609": "30",
    "204610": "31",
    "204611": "30",
    "204612": "31",
    "204701": "31",
    "204702": "28",
    "204703": "31",
    "204704": "30",
    "204705": "31",
    "204706": "30",
    "204707": "31",
    "204708": "31",
    "204709": "30",
    "204710": "31",
    "204711": "30",
    "204712": "31",
    "204801": "31",
    "204802": "29",
    "204803": "31",
    "204804": "30",
    "204805": "31",
    "204806": "30",
    "204807": "31",
    "204808": "31",
    "204809": "30",
    "204810": "31",
    "204811": "30",
    "204812": "31",
    "204901": "31",
    "204902": "28",
    "204903": "31",
    "204904": "30",
    "204905": "31",
    "204906": "30",
    "204907": "31",
    "204908": "31",
    "204909": "30",
    "204910": "31",
    "204911": "30",
    "204912": "31",
    "205001": "31",
    "205002": "28",
    "205003": "31",
    "205004": "30",
    "205005": "31",
    "205006": "30",
    "205007": "31",
    "205008": "31",
    "205009": "30",
    "205010": "31",
    "205011": "30",
    "205012": "31",
    "205101": "31",
    "205102": "28",
    "205103": "31",
    "205104": "30",
    "205105": "31",
    "205106": "30",
    "205107": "31",
    "205108": "31",
    "205109": "30",
    "205110": "31",
    "205111": "30",
    "205112": "31",
    "205201": "31",
    "205202": "29",
    "205203": "31",
    "205204": "30",
    "205205": "31",
    "205206": "30",
    "205207": "31",
    "205208": "31",
    "205209": "30",
    "205210": "31",
    "205211": "30",
    "205212": "31",
    "205301": "31",
    "205302": "28",
    "205303": "31",
    "205304": "30",
    "205305": "31",
    "205306": "30",
    "205307": "31",
    "205308": "31",
    "205309": "30",
    "205310": "31",
    "205311": "30",
    "205312": "31",
    "205401": "31",
    "205402": "28",
    "205403": "31",
    "205404": "30",
    "205405": "31",
    "205406": "30",
    "205407": "31",
    "205408": "31",
    "205409": "30",
    "205410": "31",
    "205411": "30",
    "205412": "31",
    "205501": "31",
    "205502": "28",
    "205503": "31",
    "205504": "30",
    "205505": "31",
    "205506": "30",
    "205507": "31",
    "205508": "31",
    "205509": "30",
    "205510": "31",
    "205511": "30",
    "205512": "31",
    "205601": "31",
    "205602": "29",
    "205603": "31",
    "205604": "30",
    "205605": "31",
    "205606": "30",
    "205607": "31",
    "205608": "31",
    "205609": "30",
    "205610": "31",
    "205611": "30",
    "205612": "31",
    "205701": "31",
    "205702": "28",
    "205703": "31",
    "205704": "30",
    "205705": "31",
    "205706": "30",
    "205707": "31",
    "205708": "31",
    "205709": "30",
    "205710": "31",
    "205711": "30",
    "205712": "31",
    "205801": "31",
    "205802": "28",
    "205803": "31",
    "205804": "30",
    "205805": "31",
    "205806": "30",
    "205807": "31",
    "205808": "31",
    "205809": "30",
    "205810": "31",
    "205811": "30",
    "205812": "31",
    "205901": "31",
    "205902": "28",
    "205903": "31",
    "205904": "30",
    "205905": "31",
    "205906": "30",
    "205907": "31",
    "205908": "31",
    "205909": "30",
    "205910": "31",
    "205911": "30",
    "205912": "31",
    "206001": "31",
    "206002": "29",
    "206003": "31",
    "206004": "30",
    "206005": "31",
    "206006": "30",
    "206007": "31",
    "206008": "31",
    "206009": "30",
    "206010": "31",
    "206011": "30",
    "206012": "31",
    "206101": "31",
    "206102": "28",
    "206103": "31",
    "206104": "30",
    "206105": "31",
    "206106": "30",
    "206107": "31",
    "206108": "31",
    "206109": "30",
    "206110": "31",
    "206111": "30",
    "206112": "31",
    "206201": "31",
    "206202": "28",
    "206203": "31",
    "206204": "30",
    "206205": "31",
    "206206": "30",
    "206207": "31",
    "206208": "31",
    "206209": "30",
    "206210": "31",
    "206211": "30",
    "206212": "31",
    "206301": "31",
    "206302": "28",
    "206303": "31",
    "206304": "30",
    "206305": "31",
    "206306": "30",
    "206307": "31",
    "206308": "31",
    "206309": "30",
    "206310": "31",
    "206311": "30",
    "206312": "31",
    "206401": "31",
    "206402": "29",
    "206403": "31",
    "206404": "30",
    "206405": "31",
    "206406": "30",
    "206407": "31",
    "206408": "31",
    "206409": "30",
    "206410": "31",
    "206411": "30",
    "206412": "31",
    "206501": "31",
    "206502": "28",
    "206503": "31",
    "206504": "30",
    "206505": "31",
    "206506": "30",
    "206507": "31",
    "206508": "31",
    "206509": "30",
    "206510": "31",
    "206511": "30",
    "206512": "31",
    "206601": "31",
    "206602": "28",
    "206603": "31",
    "206604": "30",
    "206605": "31",
    "206606": "30",
    "206607": "31",
    "206608": "31",
    "206609": "30",
    "206610": "31",
    "206611": "30",
    "206612": "31",
    "206701": "31",
    "206702": "28",
    "206703": "31",
    "206704": "30",
    "206705": "31",
    "206706": "30",
    "206707": "31",
    "206708": "31",
    "206709": "30",
    "206710": "31",
    "206711": "30",
    "206712": "31",
    "206801": "31",
    "206802": "29",
    "206803": "31",
    "206804": "30",
    "206805": "31",
    "206806": "30",
    "206807": "31",
    "206808": "31",
    "206809": "30",
    "206810": "31",
    "206811": "30",
    "206812": "31",
    "206901": "31",
    "206902": "28",
    "206903": "31",
    "206904": "30",
    "206905": "31",
    "206906": "30",
    "206907": "31",
    "206908": "31",
    "206909": "30",
    "206910": "31",
    "206911": "30",
    "206912": "31",
    "207001": "31",
    "207002": "28",
    "207003": "31",
    "207004": "30",
    "207005": "31",
    "207006": "30",
    "207007": "31",
    "207008": "31",
    "207009": "30",
    "207010": "31",
    "207011": "30",
    "207012": "31",
    "207101": "31",
    "207102": "28",
    "207103": "31",
    "207104": "30",
    "207105": "31",
    "207106": "30",
    "207107": "31",
    "207108": "31",
    "207109": "30",
    "207110": "31",
    "207111": "30",
    "207112": "31",
    "207201": "31",
    "207202": "29",
    "207203": "31",
    "207204": "30",
    "207205": "31",
    "207206": "30",
    "207207": "31",
    "207208": "31",
    "207209": "30",
    "207210": "31",
    "207211": "30",
    "207212": "31",
    "207301": "31",
    "207302": "28",
    "207303": "31",
    "207304": "30",
    "207305": "31",
    "207306": "30",
    "207307": "31",
    "207308": "31",
    "207309": "30",
    "207310": "31",
    "207311": "30",
    "207312": "31",
    "207401": "31",
    "207402": "28",
    "207403": "31",
    "207404": "30",
    "207405": "31",
    "207406": "30",
    "207407": "31",
    "207408": "31",
    "207409": "30",
    "207410": "31",
    "207411": "30",
    "207412": "31",
    "207501": "31",
    "207502": "28",
    "207503": "31",
    "207504": "30",
    "207505": "31",
    "207506": "30",
    "207507": "31",
    "207508": "31",
    "207509": "30",
    "207510": "31",
    "207511": "30",
    "207512": "31",
    "207601": "31",
    "207602": "29",
    "207603": "31",
    "207604": "30",
    "207605": "31",
    "207606": "30",
    "207607": "31",
    "207608": "31",
    "207609": "30",
    "207610": "31",
    "207611": "30",
    "207612": "31",
    "207701": "31",
    "207702": "28",
    "207703": "31",
    "207704": "30",
    "207705": "31",
    "207706": "30",
    "207707": "31",
    "207708": "31",
    "207709": "30",
    "207710": "31",
    "207711": "30",
    "207712": "31",
    "207801": "31",
    "207802": "28",
    "207803": "31",
    "207804": "30",
    "207805": "31",
    "207806": "30",
    "207807": "31",
    "207808": "31",
    "207809": "30",
    "207810": "31",
    "207811": "30",
    "207812": "31",
    "207901": "31",
    "207902": "28",
    "207903": "31",
    "207904": "30",
    "207905": "31",
    "207906": "30",
    "207907": "31",
    "207908": "31",
    "207909": "30",
    "207910": "31",
    "207911": "30",
    "207912": "31",
    "208001": "31",
    "208002": "29",
    "208003": "31",
    "208004": "30",
    "208005": "31",
    "208006": "30",
    "208007": "31",
    "208008": "31",
    "208009": "30",
    "208010": "31",
    "208011": "30",
    "208012": "31",
    "208101": "31",
    "208102": "28",
    "208103": "31",
    "208104": "30",
    "208105": "31",
    "208106": "30",
    "208107": "31",
    "208108": "31",
    "208109": "30",
    "208110": "31",
    "208111": "30",
    "208112": "31",
    "208201": "31",
    "208202": "28",
    "208203": "31",
    "208204": "30",
    "208205": "31",
    "208206": "30",
    "208207": "31",
    "208208": "31",
    "208209": "30",
    "208210": "31",
    "208211": "30",
    "208212": "31",
    "208301": "31",
    "208302": "28",
    "208303": "31",
    "208304": "30",
    "208305": "31",
    "208306": "30",
    "208307": "31",
    "208308": "31",
    "208309": "30",
    "208310": "31",
    "208311": "30",
    "208312": "31",
    "208401": "31",
    "208402": "29",
    "208403": "31",
    "208404": "30",
    "208405": "31",
    "208406": "30",
    "208407": "31",
    "208408": "31",
    "208409": "30",
    "208410": "31",
    "208411": "30",
    "208412": "31",
    "208501": "31",
    "208502": "28",
    "208503": "31",
    "208504": "30",
    "208505": "31",
    "208506": "30",
    "208507": "31",
    "208508": "31",
    "208509": "30",
    "208510": "31",
    "208511": "30",
    "208512": "31",
    "208601": "31",
    "208602": "28",
    "208603": "31",
    "208604": "30",
    "208605": "31",
    "208606": "30",
    "208607": "31",
    "208608": "31",
    "208609": "30",
    "208610": "31",
    "208611": "30",
    "208612": "31",
    "208701": "31",
    "208702": "28",
    "208703": "31",
    "208704": "30",
    "208705": "31",
    "208706": "30",
    "208707": "31",
    "208708": "31",
    "208709": "30",
    "208710": "31",
    "208711": "30",
    "208712": "31",
    "208801": "31",
    "208802": "29",
    "208803": "31",
    "208804": "30",
    "208805": "31",
    "208806": "30",
    "208807": "31",
    "208808": "31",
    "208809": "30",
    "208810": "31",
    "208811": "30",
    "208812": "31",
    "208901": "31",
    "208902": "28",
    "208903": "31",
    "208904": "30",
    "208905": "31",
    "208906": "30",
    "208907": "31",
    "208908": "31",
    "208909": "30",
    "208910": "31",
    "208911": "30",
    "208912": "31",
    "209001": "31",
    "209002": "28",
    "209003": "31",
    "209004": "30",
    "209005": "31",
    "209006": "30",
    "209007": "31",
    "209008": "31",
    "209009": "30",
    "209010": "31",
    "209011": "30",
    "209012": "31",
    "209101": "31",
    "209102": "28",
    "209103": "31",
    "209104": "30",
    "209105": "31",
    "209106": "30",
    "209107": "31",
    "209108": "31",
    "209109": "30",
    "209110": "31",
    "209111": "30",
    "209112": "31",
    "209201": "31",
    "209202": "29",
    "209203": "31",
    "209204": "30",
    "209205": "31",
    "209206": "30",
    "209207": "31",
    "209208": "31",
    "209209": "30",
    "209210": "31",
    "209211": "30",
    "209212": "31",
    "209301": "31",
    "209302": "28",
    "209303": "31",
    "209304": "30",
    "209305": "31",
    "209306": "30",
    "209307": "31",
    "209308": "31",
    "209309": "30",
    "209310": "31",
    "209311": "30",
    "209312": "31",
    "209401": "31",
    "209402": "28",
    "209403": "31",
    "209404": "30",
    "209405": "31",
    "209406": "30",
    "209407": "31",
    "209408": "31",
    "209409": "30",
    "209410": "31",
    "209411": "30",
    "209412": "31",
    "209501": "31",
    "209502": "28",
    "209503": "31",
    "209504": "30",
    "209505": "31",
    "209506": "30",
    "209507": "31",
    "209508": "31",
    "209509": "30",
    "209510": "31",
    "209511": "30",
    "209512": "31",
    "209601": "31",
    "209602": "29",
    "209603": "31",
    "209604": "30",
    "209605": "31",
    "209606": "30",
    "209607": "31",
    "209608": "31",
    "209609": "30",
    "209610": "31",
    "209611": "30",
    "209612": "31",
    "209701": "31",
    "209702": "28",
    "209703": "31",
    "209704": "30",
    "209705": "31",
    "209706": "30",
    "209707": "31",
    "209708": "31",
    "209709": "30",
    "209710": "31",
    "209711": "30",
    "209712": "31",
    "209801": "31",
    "209802": "28",
    "209803": "31",
    "209804": "30",
    "209805": "31",
    "209806": "30",
    "209807": "31",
    "209808": "31",
    "209809": "30",
    "209810": "31",
    "209811": "30",
    "209812": "31",
    "209901": "31",
    "209902": "28",
    "209903": "31",
    "209904": "30",
    "209905": "31",
    "209906": "30",
    "209907": "31",
    "209908": "31",
    "209909": "30",
    "209910": "31",
    "209911": "30",
    "209912": "31",
    "210001": "31",
    "210002": "28",
    "210003": "31",
    "210004": "30",
    "210005": "31",
    "210006": "30",
    "210007": "31",
    "210008": "31",
    "210009": "30",
    "210010": "31",
    "210011": "30",
    "210012": "31",
} as MonthLastDayType;
