import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    siteName,
} from '../SuurekiVar';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `404 Not Found`
let pageTitleS = `Error` // ページトップのナビゲーションリンク

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton sx={{
            margin: '0px 0px 1px 0px',
            padding: '0px'
        }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


const NotFoundPage: React.FC = React.memo(() => {

    const mainContents = [
        <div key='maincontentskey'>
            <div key='maincontentskey'>
                <Box className='content_subbox_txt'>
                    <h2 className='content_subtitle'>{pageTitle}</h2>
                    <div>404 - ページが見つかりません</div>
                    <div>404 Not Found</div>
                    <div>404 Nicht Gefunden</div>
                    <div>404 Non Trouvé</div>
                    <div>404 No Encontrado</div>
                    <div>404 Não Encontrado</div>
                    <div>404 नहीं मिला</div>
                    <div>404 Tidak Dijumpai</div>
                    <div>404 পাওয়া যায়নি</div>
                    <div>404 未找到</div>
                    <div>404 搵唔到</div>
                    <div>404 찾을 수 없음</div>
                    <div>404 Не Найдено</div>
                </Box>
            </div>
        </div>
    ];

    const metaTitle = pageTitle + siteName;


    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ flex: 1 }}>

                    {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                    <HelmetProvider>
                        <Helmet>
                            <title>{metaTitle}</title>
                        </Helmet>
                    </HelmetProvider>

                    {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                    <div className='topspace_height'></div>
                    <h1 className='page_title'>
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </h1>

                    {/* ***** メインコンテンツ ******************************************************************* */}

                    <div className='main_space'>
                        <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{pageTitle}</span>
                        </h1>
                        <div>{mainContents}</div>
                    </div>
                </div>

                {/* ***** フッター ******************************************************************* */}
                <SuurekiFooter />
            </div>
        </>
    );
});

export default NotFoundPage;