import { useState } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { useAuth } from '../contexts/AuthContext';

export const GoogleSignIn = () => {
    const [error, setError] = useState('');
    const { signInWithGoogle } = useAuth();

    const handleGoogleSignIn = async () => {
        try {
            setError('');
            await signInWithGoogle();
            // 成功時の処理（例：リダイレクト）
        } catch (err) {
            setError('Googleログインに失敗しました');
            console.error(err);
        }
    };

    /*
            <button
            onClick={handleGoogleSignIn}
            className="flex items-center justify-center w-full bg-white text-gray-700 px-4 py-2 border rounded-lg hover:bg-gray-100"
        >
            <GoogleIcon />
            Googleでログイン
        </button>
    */
    return (
        <div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <button
                onClick={handleGoogleSignIn}
                className='inside-list-style'
                style={{
                    backgroundColor: '#d04a39',
                    color: 'white',
                    padding: '5px 25px 5px 20px',
                    margin: '1rem',
                    height: '40px',
                    width: '210px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                <GoogleIcon style={{ marginRight: '0.5rem' }} />
                <span style={{ fontSize: '1.0rem' }}>Googleでログイン</span>
            </button>
        </div>
    );
};