// src/AppBar.tsx

import * as React from 'react';
// import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from './logo_bar2_clear.svg';
import EncryptUserUid from './components/EncryptUserUid';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleSignIn } from './components/GoogleSignIn';
import { UserProfile } from './components/UserProfile';
import { useAuth } from './contexts/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || '';
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

/*
ヘッダー常に上部
<AppBar position="fixed">

ヘッダーを上部
<AppBar position="absolute">
absoluteにすると、自動広告が上部に表示される。

*/
const SuurekiAppBar: React.FC = () => {
    const userUid = useUserUid();
    // console.log('SuurekiAppBar userUid:', userUid);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="absolute">
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            flexGrow: 1,
                            color: 'inherit',
                            textDecoration: 'none',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img src={logo} alt="logob" width="170.4" height="24" style={{ marginLeft: '10px' }} />
                    </Typography>
                    <Link component={RouterLink} underline="always" key="login" color="primary" to="/mypage" onClick={handleClick} style={{ color: 'white', textDecoration: 'none' }}>
                        {userUid ? (
                            <>
                                <AccountCircleIcon style={{ verticalAlign: 'middle' }} />
                            </>
                        ) : (
                            <>
                                <NoAccountsIcon style={{ verticalAlign: 'middle' }} />
                            </>
                        )}
                        <Button color="inherit">マイページ</Button>
                    </Link>
                </Toolbar>
            </AppBar>
        </Box >
    );
};

const App = () => (
    <AuthProvider>
        <SuurekiAppBar />
    </AuthProvider>
);

export default App;