// src/pages/Archives.tsx

import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    NumberAlphabet,
    WeekName,
    Yadohachi,
    YadohachiVG,
    YadohachiG,
    YadohachiB,
    Rokki,
    RokkiMeaningG,
    RokkiMeaningB,
    Zyunityoku,
    ZyunityokuVG,
    ZyunityokuG,
    ZyunityokuB,
    ZyunityokuVB,
    SenjitsuChkS,
    ZassetsuChkS,
    SekkiAngle,
    SenjitsuMeaning,
    ZassetsuMeaning,
    HoridayName,
    MoonNameSimple,
    siteName,
    siteRobots,
} from '../SuurekiVar';

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}

interface MoonState {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    mooninfo: string; /* 新月・満月・下弦・上弦 */
    moonage: string;      /* 月齢 */
}

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

interface Etcday {
    date: string;           /* 年月日 */
    etcday_dat: string;    /* 祝日の該当数字 */
}

interface SekkiDay {
    date: string;           /* 年月日 */
    time: string;         /* 日時 */
    angle: string;    /* 祝日の該当数字 */
}

// interface monthdayend {
//     dateym: string;           /* 年月日 */
//     monlastday: string;         /* dateymの最後の日 */
// }


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `サイトマップ生成`
let pageTitleS = `サイトマップ生成` // ページトップのナビゲーションリンク
let uppageTitle = `管理ページ`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton sx={{
            margin: '0px 0px 1px 0px',
            padding: '0px'
        }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/wwwwww/Zzdgk5fk967fkdcdkuh"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


// const Zzdgk5fk967fkdcdkuhSiteMap: React.FC = () => {
const Zzdgk5fk967fkdcdkuhSiteMap: React.FC = React.memo(() => {


    let dateYM = '';
    let dateYMD = '';

    const textareaHeader = `<?xml version="1.0" encoding="UTF-8"?>\n` +
        `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">\n`;

    const textareaFooter = `</urlset>`;

    const textareaSitemap = `<?xml version="1.0" encoding="UTF-8"?>\n` +
        `<sitemapindex xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_calendar.xml</loc>\n` +
        `    </sitemap>\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_goodday.xml</loc>\n` +
        `    </sitemap>\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_buddhafair.xml</loc>\n` +
        `    </sitemap>\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_aspect.xml</loc>\n` +
        `    </sitemap>\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_birth.xml</loc>\n` +
        `    </sitemap>\n` +
        `    <sitemap>\n` +
        `        <loc>https://suureki.net/sitemap_etc.xml</loc>\n` +
        `    </sitemap>\n` +
        `</sitemapindex>`;

    let textareaCalendar = textareaHeader +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n`;


    let textareaGoodday = textareaHeader +
        `    <url>\n` +
        `        <loc>https://suureki.net/goodday</loc>\n` +
        `        <changefreq>daily</changefreq>\n` +
        `    </url>\n`;
    let textareaBuddhafair = textareaHeader +
        `    <url>\n` +
        `        <loc>https://suureki.net/buddhafair</loc>\n` +
        `        <changefreq>daily</changefreq>\n` +
        `    </url>\n`;
    let textareaAspect = textareaHeader +
        `    <url>\n` +
        `        <loc>https://suureki.net/aspect</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n`;
    let textareaBirth = textareaHeader +
        `    <url>\n` +
        `        <loc>https://suureki.net/birth</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n`;



    let textareaEtc = textareaHeader;


    for (let i = 1901; i <= 2100; i++) {
        textareaCalendar +=
            `    <url>\n` +
            `        <loc>https://suureki.net/calendar/${i}</loc>\n` +
            `        <changefreq>yearly</changefreq>\n` +
            `    </url>\n`;
        textareaAspect +=
            `    <url>\n` +
            `        <loc>https://suureki.net/aspect/${i}</loc>\n` +
            `        <changefreq>yearly</changefreq>\n` +
            `    </url>\n`;
    }

    for (let i = 1901; i <= 2100; i++) {
        for (let ii = 1; ii <= 12; ii++) {
            dateYM = i + ii.toString().padStart(2, '0');
            textareaCalendar +=
                `    <url>\n` +
                `        <loc>https://suureki.net/calendar/${dateYM}</loc>\n` +
                `        <changefreq>yearly</changefreq>\n` +
                `    </url>\n`;
            textareaGoodday +=
                `    <url>\n` +
                `        <loc>https://suureki.net/goodday/${dateYM}</loc>\n` +
                `        <changefreq>yearly</changefreq>\n` +
                `    </url>\n`;
            textareaBuddhafair +=
                `    <url>\n` +
                `        <loc>https://suureki.net/buddhafair/${dateYM}</loc>\n` +
                `        <changefreq>yearly</changefreq>\n` +
                `    </url>\n`;
        }
    }
    let count = 0;
    for (let i = 1944; i <= 2023; i++) {
        for (let ii = 1; ii <= 12; ii++) {
            for (let iii = 1; iii <= 31; iii++) {
                // Check if the date is valid
                const date = new Date(i, ii - 1, iii); // Month is 0-indexed
                if (date.getFullYear() === i && date.getMonth() === ii - 1 && date.getDate() === iii) {
                    const dateYMD = i + ii.toString().padStart(2, '0') + iii.toString().padStart(2, '0');
                    textareaBirth +=
                        `    <url>\n` +
                        `        <loc>https://suureki.net/birth/${dateYMD}</loc>\n` +
                        `        <changefreq>monthly</changefreq>\n` +
                        `    </url>\n`;
                    count++;
                }
            }
        }
    }


    // always   アクセスする度に更新されるページ
    // hourly   １時間毎に更新されるページ
    // daily    毎日更新されるページ
    // weekly   １週間毎に更新されるページ
    // monthly  １ヶ月毎に更新されるページ
    // yearly   １年毎に更新されるページ
    // never    更新のないアーカイブページ

    textareaEtc +=
        `    <url>\n` +
        `        <loc>https://suureki.net</loc>\n` +
        `        <changefreq>daily</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2025000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2026000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2027000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2028000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2029000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2030000000000000000001000000000000100000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2025000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2026000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2027000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2028000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2029000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/calendar_search/2030000000000000000000000000000001000000</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/celebrity</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1941</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1951</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1961</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1971</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1981</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/1991</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/2001</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/2011</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_horiday/2021</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_etcday</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_rekigetsu</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon</loc>\n` +
        `        <changefreq>monthly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1901</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1911</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1921</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1931</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1941</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1951</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1961</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1971</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1981</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/1991</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2001</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2011</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2021</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2031</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2041</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2051</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2061</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2071</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2081</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_moon/2091</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_koyomihenkan</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_countingage</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_adjpcalendar</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_24sekki</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_zodiacsigns</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/archives/db_school_eg</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/eto</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/28</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/12</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/24sekki</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/72</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/sz</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/annotation/sz</loc>\n` +
        `        <changefreq>never</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/update</loc>\n` +
        `        <changefreq>monthly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/privacy</loc>\n` +
        `        <changefreq>monthly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/mailmagazine</loc>\n` +
        `        <changefreq>yearly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/navi</loc>\n` +
        `        <changefreq>monthly</changefreq>\n` +
        `    </url>\n` +
        `    <url>\n` +
        `        <loc>https://suureki.net/sitemap</loc>\n` +
        `        <changefreq>monthly</changefreq>\n` +
        `    </url>\n`;

    textareaCalendar += textareaFooter;
    textareaGoodday += textareaFooter;
    textareaBuddhafair += textareaFooter;
    textareaAspect += textareaFooter;
    textareaEtc += textareaFooter;
    textareaBirth += textareaFooter;

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{pageTitle}</h2>
                <div>
                    sitemap_index.xml
                </div>
                <textarea
                    value={textareaSitemap}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    暦注カレンダー：年暦+月暦
                </div>
                <div>
                    sitemap_calendar.xml
                </div>
                <textarea
                    value={textareaCalendar}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    吉凶カレンダー
                </div>
                <div>
                    sitemap_goodday.xml
                </div>
                <textarea
                    value={textareaGoodday}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    仏様御縁日カレンダー
                </div>
                <div>
                    sitemap_buddhafair.xml
                </div>
                <textarea
                    value={textareaBuddhafair}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    座相
                </div>
                <div>
                    sitemap_aspect.xml
                </div>
                <textarea
                    value={textareaAspect}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    誕生日（1+{count}）
                </div>
                <div>
                    sitemap_birth.xml
                </div>
                <textarea
                    value={textareaBirth}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '10rem' }}
                    readOnly
                />
                <div>
                    その他ページ
                </div>
                <div>
                    sitemap_etc.xml
                </div>
                <textarea
                    value={textareaEtc}
                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '60%', height: '20rem' }}
                    readOnly
                />
            </Box>
        </div >
    ];

    // const pageKey = "Zzdgk5fk967fkdcdkuhMail";
    // const pagePath = "Zzdgk5fk967fkdcdkuh/mail";

    const metaTitle = pageTitle + siteName;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ flex: 1 }}>

                    {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                    <HelmetProvider>
                        <Helmet>
                            <title>{metaTitle}</title>
                            <meta name="robots" content={siteRobots['NG']} />
                        </Helmet>
                    </HelmetProvider>

                    {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                    <div className='topspace_height'></div>
                    <h1 className='page_title'>
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </h1>

                    {/* ***** メインコンテンツ ******************************************************************* */}

                    <div className='main_space'>
                        <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{pageTitle}</span>
                        </h1>
                        <div>{mainContents}</div>
                    </div>
                </div>

                {/* ***** フッター ******************************************************************* */}
                <SuurekiFooter />
            </div>
        </>
    );
});

export default Zzdgk5fk967fkdcdkuhSiteMap;