import React from 'react';

const ninestarAngleDATADetail: string[] = [];
const ninestarAngleDATADetailColor: string[] = [];
const ninestarAngleDATADetailSize: number[] = [];


interface SVGNinestarAngleImgProps {
    ninestarAngleDATA: string;
}

const SVGNinestarAngleImg: React.FC<SVGNinestarAngleImgProps> = ({
    ninestarAngleDATA,
}) => {

    for (let i = 0; i < 24; i++) {
        if (typeof ninestarAngleDATA === 'string') {
            ninestarAngleDATADetail[i] = ninestarAngleDATA.substring(i, i + 1);
            if (ninestarAngleDATADetail[i] == "1") { ninestarAngleDATADetailColor[i] = "blue"; } else { ninestarAngleDATADetailColor[i] = "red"; }
            if (ninestarAngleDATADetail[i] == "1") { ninestarAngleDATADetailSize[i] = 18; } else { ninestarAngleDATADetailSize[i] = 14; }

        } else {
            ninestarAngleDATADetail[i] = ''; // または適切なデフォルト値
        }
    }

    //             <text x="100" y="100" fontSize="14" fill="blue" stroke="none" textAnchor="middle" dominantBaseline="middle">「{ninestarAngleDATA}」</text>
    //                 <circle cx="100" cy="100" r="100" stroke="white" strokeWidth="1" fill="#F3DBB8"></circle>

    return (
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
            <g transform="rotate(22.5 100 100)">
                <defs>
                    <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                        <stop offset="0%" stopColor="white" stopOpacity={1} />
                        <stop offset="15%" stopColor="white" stopOpacity={1} />
                        <stop offset="100%" stopColor="#F3DBB8" stopOpacity={1} />
                    </radialGradient>
                </defs>
                <circle cx="100" cy="100" r="100" stroke="white" strokeWidth="1" fill="url(#grad1)" />
                <circle cx="100" cy="100" r="15" stroke="white" strokeWidth="1" fill="white" />
                <line x1="100" y1="0" x2="100" y2="200" stroke="white" strokeWidth="3" />{/* 縦線 */}
                <line x1="0" y1="100" x2="200" y2="100" stroke="white" strokeWidth="3" />{/* 横線 */}
                <line x1="0" y1="0" x2="200" y2="200" stroke="white" strokeWidth="3" />
                <line x1="200" y1="0" x2="0" y2="200" stroke="white" strokeWidth="3" />
            </g>
            <text x="100" y="65" fontSize="16" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">北</text>
            <text x="135" y="102" fontSize="16" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">東</text>
            <text x="100" y="135" fontSize="16" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">南</text>
            <text x="65" y="102" fontSize="16" fill="#222" stroke="none" textAnchor="middle" dominantBaseline="middle">西</text>
            {/* 北 */}
            <text x="80" y="20" fontSize={ninestarAngleDATADetailSize[0]} fill={ninestarAngleDATADetailColor[0]} stroke="none" textAnchor="middle" dominantBaseline="middle">壬</text>
            <text x="100" y="20" fontSize={ninestarAngleDATADetailSize[1]} fill={ninestarAngleDATADetailColor[1]} stroke="none" textAnchor="middle" dominantBaseline="middle">子</text>
            <text x="120" y="20" fontSize={ninestarAngleDATADetailSize[2]} fill={ninestarAngleDATADetailColor[2]} stroke="none" textAnchor="middle" dominantBaseline="middle">癸</text>
            {/* 北東 */}
            <text x="140" y="30" fontSize={ninestarAngleDATADetailSize[3]} fill={ninestarAngleDATADetailColor[3]} stroke="none" textAnchor="middle" dominantBaseline="middle">丑</text>
            <text x="155" y="45" fontSize={ninestarAngleDATADetailSize[4]} fill={ninestarAngleDATADetailColor[4]} stroke="none" textAnchor="middle" dominantBaseline="middle">艮</text>
            <text x="170" y="60" fontSize={ninestarAngleDATADetailSize[5]} fill={ninestarAngleDATADetailColor[5]} stroke="none" textAnchor="middle" dominantBaseline="middle">寅</text>
            {/* 東 */}
            <text x="180" y="80" fontSize={ninestarAngleDATADetailSize[6]} fill={ninestarAngleDATADetailColor[6]} stroke="none" textAnchor="middle" dominantBaseline="middle">甲</text>
            <text x="180" y="100" fontSize={ninestarAngleDATADetailSize[7]} fill={ninestarAngleDATADetailColor[7]} stroke="none" textAnchor="middle" dominantBaseline="middle">卯</text>
            <text x="180" y="120" fontSize={ninestarAngleDATADetailSize[8]} fill={ninestarAngleDATADetailColor[8]} stroke="none" textAnchor="middle" dominantBaseline="middle">乙</text>
            {/* 東南 */}
            <text x="170" y="140" fontSize={ninestarAngleDATADetailSize[9]} fill={ninestarAngleDATADetailColor[9]} stroke="none" textAnchor="middle" dominantBaseline="middle">辰</text>
            <text x="155" y="155" fontSize={ninestarAngleDATADetailSize[10]} fill={ninestarAngleDATADetailColor[10]} stroke="none" textAnchor="middle" dominantBaseline="middle">巽</text>
            <text x="140" y="170" fontSize={ninestarAngleDATADetailSize[11]} fill={ninestarAngleDATADetailColor[11]} stroke="none" textAnchor="middle" dominantBaseline="middle">巳</text>
            {/* 南 */}
            <text x="120" y="180" fontSize={ninestarAngleDATADetailSize[12]} fill={ninestarAngleDATADetailColor[12]} stroke="none" textAnchor="middle" dominantBaseline="middle">丙</text>
            <text x="100" y="180" fontSize={ninestarAngleDATADetailSize[13]} fill={ninestarAngleDATADetailColor[13]} stroke="none" textAnchor="middle" dominantBaseline="middle">午</text>
            <text x="80" y="180" fontSize={ninestarAngleDATADetailSize[14]} fill={ninestarAngleDATADetailColor[14]} stroke="none" textAnchor="middle" dominantBaseline="middle">丁</text>
            {/* 南西 */}
            <text x="60" y="170" fontSize={ninestarAngleDATADetailSize[15]} fill={ninestarAngleDATADetailColor[15]} stroke="none" textAnchor="middle" dominantBaseline="middle">未</text>
            <text x="45" y="155" fontSize={ninestarAngleDATADetailSize[16]} fill={ninestarAngleDATADetailColor[16]} stroke="none" textAnchor="middle" dominantBaseline="middle">坤</text>
            <text x="30" y="140" fontSize={ninestarAngleDATADetailSize[17]} fill={ninestarAngleDATADetailColor[17]} stroke="none" textAnchor="middle" dominantBaseline="middle">申</text>
            {/* 西 */}
            <text x="20" y="120" fontSize={ninestarAngleDATADetailSize[18]} fill={ninestarAngleDATADetailColor[18]} stroke="none" textAnchor="middle" dominantBaseline="middle">庚</text>
            <text x="20" y="100" fontSize={ninestarAngleDATADetailSize[19]} fill={ninestarAngleDATADetailColor[19]} stroke="none" textAnchor="middle" dominantBaseline="middle">酉</text>
            <text x="20" y="80" fontSize={ninestarAngleDATADetailSize[20]} fill={ninestarAngleDATADetailColor[20]} stroke="none" textAnchor="middle" dominantBaseline="middle">辛</text>
            {/* 西北 */}
            <text x="30" y="60" fontSize={ninestarAngleDATADetailSize[21]} fill={ninestarAngleDATADetailColor[21]} stroke="none" textAnchor="middle" dominantBaseline="middle">戌</text>
            <text x="45" y="45" fontSize={ninestarAngleDATADetailSize[22]} fill={ninestarAngleDATADetailColor[22]} stroke="none" textAnchor="middle" dominantBaseline="middle">乾</text>
            <text x="60" y="30" fontSize={ninestarAngleDATADetailSize[23]} fill={ninestarAngleDATADetailColor[23]} stroke="none" textAnchor="middle" dominantBaseline="middle">亥</text>
        </svg>
    );
}

export default SVGNinestarAngleImg;
