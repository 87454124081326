// src/pages/Archives.tsx

import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    NumberAlphabet,
    WeekName,
    Yadohachi,
    YadohachiVG,
    YadohachiG,
    YadohachiB,
    Rokki,
    RokkiMeaningG,
    RokkiMeaningB,
    Zyunityoku,
    ZyunityokuVG,
    ZyunityokuG,
    ZyunityokuB,
    ZyunityokuVB,
    SenjitsuChkS,
    ZassetsuChkS,
    SekkiAngle,
    SenjitsuMeaning,
    ZassetsuMeaning,
    HoridayName,
    MoonNameSimple,
    siteName,
    siteRobots,
} from '../SuurekiVar';

interface Koyomi {
    date: string;           /* 日付 */
    eto10_month: number;    /* 月干支（暦：10支）： */
    eto12_month: number;    /* 月干支（暦：12支） */
    eto10_year: number;     /* 年干支（暦：10支） */
    eto12_year: number;     /* 年干支（暦：12支） */
    week: string;           /* 曜日 */
    jd: number;             /* ユリウス通日 */
    xxolddate: string;      /* 旧暦 */
    xxrokki: number;        /* 六曜 */
    eto10_day: number;      /* 日干支（10支） */
    eto12_day: number;      /* 日干支（12支） */
    xxgogyo: string;        /* 五行 */
    xxyadohachi: number;    /* 二十八宿 */
    xxyadonana: string;     /* 二十七宿 */
    xxsekki: number;        /* 二十四節気 */
    xxsetuzuki: string;     /* 節月 */
    eto10_month_s: number;  /* 月干支（節：10支） */
    eto12_month_s: number;  /* 月干支（節：12支） */
    eto10_year_s: number;   /* 年干支（節：10支） */
    eto12_year_s: number;   /* 年干支（節：12支） */
    xxzyunityoku: number;   /* 十二直 */
    xxkyuusei: number;      /* 日九星 */
    xxinyo: string;         /* 陰陽 */
    xxsenjitsu: string;     /* 選日 */
    xxzassetsu: string;     /* 雑節 */
    xxnanazyuni: string;    /* 七十二候 */
    voidtime: string;       /* ボイドタイム */
    yylongitudesun: string; /*  */
    yylongitudemoon: string;    /*  */
    yyagemoon: string;      /*  */
    kyuusei_y: number;      /* 年九星 */
    kyuusei_m: number;      /* 月九星 */
    sekki_date_s: string;   /*  */
    sekki_date_e: string;   /*  */
}

interface MoonState {
    date: string;         /* 年月日 */
    time: string;         /* 日時 */
    mooninfo: string; /* 新月・満月・下弦・上弦 */
    moonage: string;      /* 月齢 */
}

interface Horiday {
    date: string;           /* 年月日 */
    holiday_dat: string;    /* 祝日の該当数字 */
}

interface Etcday {
    date: string;           /* 年月日 */
    etcday_dat: string;    /* 祝日の該当数字 */
}

interface SekkiDay {
    date: string;           /* 年月日 */
    time: string;         /* 日時 */
    angle: string;    /* 祝日の該当数字 */
}

// interface monthdayend {
//     dateym: string;           /* 年月日 */
//     monlastday: string;         /* dateymの最後の日 */
// }


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `メルマガ原稿`
let pageTitleS = `メルマガ原稿` // ページトップのナビゲーションリンク
let uppageTitle = `管理ページ`

const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton sx={{
            margin: '0px 0px 1px 0px',
            padding: '0px'
        }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Link
        component={RouterLink}
        underline="hover"
        key="2"
        color="inherit"
        to="/wwwwww/Zzdgk5fk967fkdcdkuh"
        onClick={handleClick}
    >
        {uppageTitle}
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];


// const Zzdgk5fk967fkdcdkuhMail: React.FC = () => {
const Zzdgk5fk967fkdcdkuhMail: React.FC = React.memo(() => {


    const today = new Date();
    const nextMonthDate = new Date(today);
    nextMonthDate.setMonth(today.getMonth() + 1);

    const dateY = today.getFullYear();
    const dateM = (today.getMonth() + 1).toString().padStart(2, '0');

    const dateY2 = nextMonthDate.getFullYear();
    const dateM2 = (nextMonthDate.getMonth() + 1).toString().padStart(2, '0');

    const dateY3 = nextMonthDate.getFullYear();
    const dateM3 = (nextMonthDate.getMonth() + 2).toString().padStart(2, '0');

    let dateYMD;
    const dateYM = dateY + dateM;
    const dateYM2 = dateY2 + dateM2;
    const dateYM3 = dateY3 + dateM3;
    let dateYMDJ;
    let reiwa;
    let reiwaJ;
    let textareaComment;
    // let totalling_sz_txt: { [date: string]: string } = {};


    // 今日の暦jsonの呼び出し //////////////////////////////////////////////////////////////////////

    // const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);
    // useEffect(() => {
    //     fetch(calendar_json_url)
    //         .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //         })
    //         .then(koyomi_data => setDataKoyomi(koyomi_data))
    //         .catch(error => console.error('Error:', error));
    // }, [calendar_json_url]);

    // JSONデータを日付をキーとする
    const calendar_json_url = `https://db.suureki.net/_db_calendar/calendar_${dateY}.json`;
    const calendar_json_url2 = `https://db.suureki.net/_db_calendar/calendar_${dateY + 1}.json`;

    const [koyomi_data, setDataKoyomi] = useState<Koyomi[]>([]);

    useEffect(() => {
        Promise.all([
            fetch(calendar_json_url).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. ネットワークのレスポンスが悪い。（今年）');
                }
                return response.json();
            }),
            fetch(calendar_json_url2).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok. ネットワークのレスポンスが悪い。（来年）');
                }
                return response.json();
            })
        ])
            .then(([data1, data2]) => {
                // 両方のデータを合算してセット
                setDataKoyomi([...data1, ...data2]);
            })
            .catch(error => console.error('Error:', error));
    }, [calendar_json_url, calendar_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let weekMap: { [date: string]: number } = {};
    let xxrokkiMap: { [date: string]: number } = {};
    let xxsekkiMap: { [date: string]: number } = {};
    let eto10dayMap: { [date: string]: number } = {};
    let eto12dayMap: { [date: string]: number } = {};
    let eto10yearMap: { [date: string]: number } = {};
    let eto12yearMap: { [date: string]: number } = {};
    let xxyadohachiMap: { [date: string]: number } = {};
    let xxzyunityokuMap: { [date: string]: number } = {};
    let xxolddateMap: { [date: string]: string } = {};
    let xxolddateMapS: { [date: string]: string } = {};
    let xxolddateMapJ: { [date: string]: string } = {};
    let xxsenjitsuMap: { [date: string]: string } = {};
    let xxzassetsuMap: { [date: string]: string } = {};
    koyomi_data.forEach(koyomi => {
        weekMap[koyomi.date] = Number(koyomi.week);
        xxrokkiMap[koyomi.date] = koyomi.xxrokki;
        xxsekkiMap[koyomi.date] = koyomi.xxsekki;
        eto10dayMap[koyomi.date] = koyomi.eto10_day;
        eto12dayMap[koyomi.date] = koyomi.eto12_day;
        eto10yearMap[koyomi.date] = koyomi.eto10_year;
        eto12yearMap[koyomi.date] = koyomi.eto12_year;
        xxyadohachiMap[koyomi.date] = koyomi.xxyadohachi;
        xxzyunityokuMap[koyomi.date] = koyomi.xxzyunityoku;
        xxolddateMap[koyomi.date] = koyomi.xxolddate;
        const dateParts = koyomi.xxolddate.split('-').map(part => parseInt(part, 10));
        xxolddateMapS[koyomi.date] = dateParts[1] + "/" + dateParts[2];
        xxolddateMapJ[koyomi.date] = dateParts[1] + "月" + dateParts[2] + "日";
        xxsenjitsuMap[koyomi.date] = koyomi.xxsenjitsu;
        xxzassetsuMap[koyomi.date] = koyomi.xxzassetsu;

    });

    // 二十四節気の時間jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const sekki_json_url = `https://db.suureki.net/_db_24sekki/24sekki_${dateY}.json`;
    const sekki_json_url2 = `https://db.suureki.net/_db_24sekki/24sekki_${dateY + 1}.json`;

    const [sekki_data, setDataSekki] = useState<SekkiDay[]>([]);

    useEffect(() => {
        // Fetch data from both URLs and combine them
        const fetchData = async () => {
            try {
                const sekkiResponse1 = await fetch(sekki_json_url);
                const sekkiResponse2 = await fetch(sekki_json_url2);

                if (!sekkiResponse1.ok || !sekkiResponse2.ok) {
                    throw new Error('Network response was not ok');
                }

                const sekkiData1 = await sekkiResponse1.json();
                const sekkiData2 = await sekkiResponse2.json();

                // Combine the data from both years
                const sekkiCombinedData = [...sekkiData1, ...sekkiData2];

                setDataSekki(sekkiCombinedData);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, [sekki_json_url, sekki_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let sekkidateMap: { [date: string]: string } = {};
    let sekkitimeMap: { [date: string]: string } = {};
    let sekkiangleMap: { [date: string]: string } = {};

    sekki_data.forEach(sekki => {
        sekkidateMap[sekki.date] = sekki.date;
        sekkitimeMap[sekki.date] = sekki.time;
        sekkiangleMap[sekki.date] = sekki.angle;
    });


    // 今年と来年の月（新月・満月・下弦・上弦）状態jsonの呼び出し //////////////////////////////////////////////////////////////////////


    const moonstate_json_url = `https://db.suureki.net/_db_moon/moonstate_${dateY}.json`;
    const moonstate_json_url2 = `https://db.suureki.net/_db_moon/moonstate_${dateY + 1}.json`;

    const [moonstate_data, setDataMoonState] = useState<MoonState[]>([]);

    useEffect(() => {
        // Fetch data from both URLs and combine them
        const fetchData = async () => {
            try {
                const moonResponse1 = await fetch(moonstate_json_url);
                const moonResponse2 = await fetch(moonstate_json_url2);

                if (!moonResponse1.ok || !moonResponse2.ok) {
                    throw new Error('Network response was not ok');
                }

                const moonData1 = await moonResponse1.json();
                const moonData2 = await moonResponse2.json();

                // Combine the data from both years
                const moonCombinedData = [...moonData1, ...moonData2];

                setDataMoonState(moonCombinedData);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [moonstate_json_url, moonstate_json_url2]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let moonstatetime: { [date: string]: string } = {};
    let moonstateinfo: { [date: string]: string } = {};
    let moonstateage: { [date: string]: string } = {};

    moonstate_data.forEach(moon => {
        moonstatetime[moon.date] = moon.time;
        moonstateinfo[moon.date] = moon.mooninfo;
        moonstateage[moon.date] = moon.moonage;
    });


    let moonDat: { [date: string]: string } = {};

    for (let key of Object.keys(moonstatetime)) {
        // console.log(key)
        if (dateYM === key.substring(0, 6) || dateYM2 === key.substring(0, 6)) {
            if (moonstateinfo[key] === 'fullxx-180' || moonstateinfo[key] === 'newxxx-000') {
                if (moonDat[dateYM] === undefined) { moonDat[dateYM] = ''; }
                moonDat[dateYM] += '' + Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）' + Number(moonstatetime[key].substring(0, 2)) + '時' + Number(moonstatetime[key].substring(2, 4)) + '分：' + MoonNameSimple[moonstateinfo[key]] + '\n';
            }
        }
        if (dateYM2 === key.substring(0, 6) || dateYM3 === key.substring(0, 6)) {
            if (moonstateinfo[key] === 'fullxx-180' || moonstateinfo[key] === 'newxxx-000') {
                if (moonDat[dateYM2] === undefined) { moonDat[dateYM2] = ''; }
                moonDat[dateYM2] += '' + Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）' + moonstatetime[key].substring(0, 2) + '時' + moonstatetime[key].substring(2, 4) + '分：' + MoonNameSimple[moonstateinfo[key]] + '\n';
            }
        }
    }





    // 今年の祝日（祭日）jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_all.json";
    // const horiday_json_url = "https://db.suureki.net/_db_horiday/horiday_" + `${dateY}` + ".json";

    const [horiday_data, setDataHoriday] = useState<Horiday[]>([]);
    useEffect(() => {
        fetch(horiday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(horiday_data => setDataHoriday(horiday_data))
            .catch(error => console.error('Error:', error));
    }, [horiday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let holidayMap: { [date: string]: string } = {};
    horiday_data.forEach(horiday => {
        holidayMap[horiday.date] = horiday.holiday_dat;
    });

    let holidayTxt = '';
    let holidayTxt2 = '';


    for (let key of Object.keys(holidayMap)) {
        // console.log(key); // prints the date keys, e.g., 20260314, 20260401
        // console.log(etcdayMap[key]); // prints the values associated with the keys

        if (dateYM === key.substring(0, 6) || dateYM2 === key.substring(0, 6)) {
            holidayTxt += Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）：' + HoridayName[holidayMap[key]] + '\n';
        }
        if (dateYM2 === key.substring(0, 6) || dateYM3 === key.substring(0, 6)) {
            holidayTxt2 += Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）：' + HoridayName[holidayMap[key]] + '\n';
        }

    }


    // 祝日ではない日と風習 jsonの呼び出し //////////////////////////////////////////////////////////////////////
    /*
    { "date": "20260314", "etcday_dat": "ホワイトデー" },
    { "date": "20260401", "etcday_dat": "エイプリルフール" },
    { "date": "20260501", "etcday_dat": "メーデー" },
    { "date": "20260505", "etcday_dat": "端午の節句" },
    { "date": "20260510", "etcday_dat": "母の日" },
    { "date": "20260621", "etcday_dat": "父の日" },
    { "date": "20260707", "etcday_dat": "七夕" },
    */
    const etcday_json_url = "https://db.suureki.net/_db_horiday/etcday_all.json";

    const [etcday_data, setDataEtcday] = useState<Etcday[]>([]);
    useEffect(() => {
        fetch(etcday_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(etcday_data => setDataEtcday(etcday_data))
            .catch(error => console.error('Error:', error));
    }, [etcday_json_url]);

    // JSONデータを日付をキーとするオブジェクトに変換します。
    let etcdayMap: { [date: string]: string } = {};
    etcday_data.forEach(etcday => {
        etcdayMap[etcday.date] = etcday.etcday_dat;
    });


    let etcDaytxt = '';
    let etcDaytxt2 = '';

    for (let key of Object.keys(etcdayMap)) {
        // console.log(key); // prints the date keys, e.g., 20260314, 20260401
        // console.log(etcdayMap[key]); // prints the values associated with the keys

        if (dateYM === key.substring(0, 6) || dateYM2 === key.substring(0, 6)) {
            etcDaytxt += Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）：' + etcdayMap[key] + '\n';
        }
        if (dateYM2 === key.substring(0, 6) || dateYM3 === key.substring(0, 6)) {
            etcDaytxt2 += Number(key.substring(4, 6)) + '月' + Number(key.substring(6, 8)) + '日（' + WeekName[weekMap[key]] + '）：' + etcdayMap[key] + '\n';
        }
    }



    let SenjitsuTxt = '';
    let zassetsuTxt = '';

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{dateY}年{dateM}月</h2>
                <div>
                    {Array.from({ length: 31 }, (_, ii) => {
                        let dateD = ii + 1;
                        reiwa = dateY - 2018;
                        reiwaJ = '令和' + reiwa;

                        dateYMD = dateY + dateM + dateD.toString().padStart(2, '0');
                        dateYMDJ = dateY + '年/' + reiwaJ + '年' + Number(dateM) + '月' + dateD + '日（' + WeekName[weekMap[dateYMD]] + '）';

                        if (WeekName[weekMap[dateYMD]] !== undefined) {

                            textareaComment = dateYMDJ + '\n\n';
                            textareaComment += 'おはようございます。本日は旧暦で ' + xxolddateMapJ[dateYMD] + ''
                            if (sekkiangleMap[dateYMD] !== undefined) {
                                textareaComment += '、\n二十四節気は「' + SekkiAngle[sekkiangleMap[dateYMD]] + '」です。\n\n';
                            } else {
                                textareaComment += 'です。\n\n';
                            }

                            textareaComment += '六曜は' + Rokki[xxrokkiMap[dateYMD]] + '、' + RokkiMeaningG[xxrokkiMap[dateYMD]] + RokkiMeaningB[xxrokkiMap[dateYMD]] + 'です。\n\n';

                            textareaComment += '二十八宿は' + Yadohachi[xxyadohachiMap[dateYMD]] + 'で';
                            if (YadohachiVG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、とても良い行動は' + YadohachiVG[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、良い行動は' + YadohachiG[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiB[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、悪い行動は' + YadohachiB[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiVG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、とても悪い行動は' + YadohachiVG[xxyadohachiMap[dateYMD]] + '';
                            }
                            textareaComment += '。\n\n';

                            textareaComment += '十二直宿は' + Zyunityoku[xxzyunityokuMap[dateYMD]] + 'で';
                            if (ZyunityokuVG[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、とても良い行動は' + ZyunityokuVG[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuG[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、良い行動は' + ZyunityokuG[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuB[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、悪い行動は' + ZyunityokuB[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuVB[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、とても悪い行動は' + ZyunityokuVB[xxzyunityokuMap[dateYMD]] + '';
                            }
                            textareaComment += '。\n\n';
                            textareaComment += '（今日の選日と雑節）\n\n';


                            SenjitsuTxt = '';
                            if (xxsenjitsuMap[dateYMD]) {
                                for (let iii = 0; iii < xxsenjitsuMap[dateYMD].length; iii++) {
                                    SenjitsuTxt += SenjitsuChkS[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)] + '：\n';
                                    SenjitsuTxt += SenjitsuMeaning[NumberAlphabet[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)]] + '\n\n';
                                }
                            }
                            zassetsuTxt = '';
                            if (xxzassetsuMap[dateYMD]) {
                                for (let iii = 0; iii < xxzassetsuMap[dateYMD].length; iii++) {
                                    zassetsuTxt += ZassetsuChkS[xxzassetsuMap[dateYMD].substring(iii, iii + 1)] + '：\n';
                                    zassetsuTxt += ZassetsuMeaning[NumberAlphabet[xxzassetsuMap[dateYMD].substring(iii, iii + 1)]] + '\n\n';
                                }
                            }

                            if (xxsenjitsuMap[dateYMD] !== undefined) {
                                textareaComment += SenjitsuTxt;
                            }
                            if (xxzassetsuMap[dateYMD] !== undefined) {
                                textareaComment += zassetsuTxt;
                            }
                            if (xxsenjitsuMap[dateYMD] === '' && xxzassetsuMap[dateYMD] === '') {
                                textareaComment += 'ありません\n\n';
                            }

                            textareaComment += '（今月と来月の祝日）\n\n';
                            textareaComment += holidayTxt + '\n';

                            textareaComment += '（今月と来月の祝日ではない日と風習）\n\n';
                            textareaComment += etcDaytxt + '\n';

                            textareaComment += '（今月と来月の月の状態）\n\n';
                            textareaComment += moonDat[dateYM] + '\n';

                            textareaComment += '-------------------------\n';
                            textareaComment += '発行：カレンダーの崇暦.net\n';
                            textareaComment += 'https://suureki.net/\n';
                            textareaComment += '-------------------------\n';

                        } else {
                            textareaComment = '';
                        }

                        return (
                            <Box key={`inner-${dateYMD}`}>
                                {/* 日にち */}
                                <div style={{ fontSize: '1.0rem' }}>
                                    <div style={{ marginBottom: '1rem', marginTop: '1.0rem' }}>
                                        {dateYMDJ}{dateYMD}
                                    </div>
                                    <div>

                                        {/* Conditionally render the textarea only if textareaComment is defined */}
                                        {textareaComment && (
                                            <>
                                                <input
                                                    type="text"
                                                    value={`${dateYMDJ}の暦注`}
                                                    style={{ padding: '1rem', backgroundColor: '#f8dce0', marginBottom: '1rem', width: '90%' }}
                                                    readOnly
                                                />
                                                <textarea
                                                    value={textareaComment}
                                                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '90%', height: '10rem' }}
                                                    readOnly
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Box>
                        );
                    })}
                </div>
            </Box>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{dateY2}年{dateM2}月</h2>
                <div>
                    <div style={{ paddingTop: '15rem', paddingBottom: '15rem' }}>
                        ＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊
                    </div>
                    {Array.from({ length: 31 }, (_, ii) => {
                        let dateD = ii + 1;
                        reiwa = dateY2 - 2018;
                        reiwaJ = '令和' + reiwa;

                        dateYMD = dateY2 + dateM2 + dateD.toString().padStart(2, '0');
                        dateYMDJ = dateY2 + '年/' + reiwaJ + '年' + Number(dateM2) + '月' + dateD + '日（' + WeekName[weekMap[dateYMD]] + '）';

                        if (WeekName[weekMap[dateYMD]] !== undefined) {

                            textareaComment = dateYMDJ + '\n\n';
                            textareaComment += 'おはようございます。本日は旧暦で ' + xxolddateMapJ[dateYMD] + ''
                            if (sekkiangleMap[dateYMD] !== undefined) {
                                textareaComment += '、\n二十四節気は「' + SekkiAngle[sekkiangleMap[dateYMD]] + '」です。\n\n';
                            } else {
                                textareaComment += 'です。\n\n';
                            }

                            textareaComment += '六曜は' + Rokki[xxrokkiMap[dateYMD]] + '、' + RokkiMeaningG[xxrokkiMap[dateYMD]] + RokkiMeaningB[xxrokkiMap[dateYMD]] + 'です。\n\n';

                            textareaComment += '二十八宿は' + Yadohachi[xxyadohachiMap[dateYMD]] + 'で';
                            if (YadohachiVG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、とても良い行動は' + YadohachiVG[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、良い行動は' + YadohachiG[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiB[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、悪い行動は' + YadohachiB[xxyadohachiMap[dateYMD]] + '';
                            }
                            if (YadohachiVG[xxyadohachiMap[dateYMD]] !== '-') {
                                textareaComment += '、とても悪い行動は' + YadohachiVG[xxyadohachiMap[dateYMD]] + '';
                            }
                            textareaComment += '。\n\n';

                            textareaComment += '十二直宿は' + Zyunityoku[xxzyunityokuMap[dateYMD]] + 'で';
                            if (ZyunityokuVG[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、とても良い行動は' + ZyunityokuVG[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuG[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、良い行動は' + ZyunityokuG[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuB[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、悪い行動は' + ZyunityokuB[xxzyunityokuMap[dateYMD]] + '';
                            }
                            if (ZyunityokuVB[xxzyunityokuMap[dateYMD]] !== '無し') {
                                textareaComment += '、とても悪い行動は' + ZyunityokuVB[xxzyunityokuMap[dateYMD]] + '';
                            }
                            textareaComment += '。\n\n';
                            textareaComment += '（今日の選日と雑節）\n\n';


                            SenjitsuTxt = '';
                            if (xxsenjitsuMap[dateYMD]) {
                                for (let iii = 0; iii < xxsenjitsuMap[dateYMD].length; iii++) {
                                    SenjitsuTxt += SenjitsuChkS[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)] + '：\n';
                                    SenjitsuTxt += SenjitsuMeaning[NumberAlphabet[xxsenjitsuMap[dateYMD].substring(iii, iii + 1)]] + '\n\n';
                                }
                            }
                            zassetsuTxt = '';
                            if (xxzassetsuMap[dateYMD]) {
                                for (let iii = 0; iii < xxzassetsuMap[dateYMD].length; iii++) {
                                    zassetsuTxt += ZassetsuChkS[xxzassetsuMap[dateYMD].substring(iii, iii + 1)] + '：\n';
                                    zassetsuTxt += ZassetsuMeaning[NumberAlphabet[xxzassetsuMap[dateYMD].substring(iii, iii + 1)]] + '\n\n';
                                }
                            }

                            if (xxsenjitsuMap[dateYMD] !== undefined) {
                                textareaComment += SenjitsuTxt;
                            }
                            if (xxzassetsuMap[dateYMD] !== undefined) {
                                textareaComment += zassetsuTxt;
                            }
                            if (xxsenjitsuMap[dateYMD] === '' && xxzassetsuMap[dateYMD] === '') {
                                textareaComment += 'ありません\n\n';
                            }

                            textareaComment += '（今月と来月の祝日）\n\n';
                            textareaComment += holidayTxt2 + '\n';

                            textareaComment += '（今月と来月の祝日ではない日と風習）\n\n';
                            textareaComment += etcDaytxt2 + '\n';

                            textareaComment += '（今月と来月の月の状態）\n\n';
                            textareaComment += moonDat[dateYM2] + '\n';

                            textareaComment += '-------------------------\n';
                            textareaComment += '発行：カレンダーの崇暦.net\n';
                            textareaComment += 'https://suureki.net/\n';
                            textareaComment += '-------------------------\n';

                        } else {
                            textareaComment = '';
                        }

                        return (
                            <Box key={`inner-${dateYMD}`}>
                                {/* 日にち */}
                                <div style={{ fontSize: '1.0rem' }}>
                                    <div style={{ marginBottom: '1rem', marginTop: '1.0rem' }}>
                                        {dateYMDJ}{dateYMD}
                                    </div>
                                    <div>

                                        {/* Conditionally render the textarea only if textareaComment is defined */}
                                        {textareaComment && (
                                            <>
                                                <input
                                                    type="text"
                                                    value={`${dateYMDJ}の暦注`}
                                                    style={{ padding: '1rem', backgroundColor: '#f8dce0', marginBottom: '1rem', width: '90%' }}
                                                    readOnly
                                                />
                                                <textarea
                                                    value={textareaComment}
                                                    style={{ padding: '1rem', backgroundColor: '#e1f1f8', marginBottom: '1rem', width: '90%', height: '10rem' }}
                                                    readOnly
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Box>
                        );

                    })}

                </div>
            </Box>
        </div>
    ];

    // const pageKey = "Zzdgk5fk967fkdcdkuhMail";
    // const pagePath = "Zzdgk5fk967fkdcdkuh/mail";

    const metaTitle = pageTitle + siteName;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <div style={{ flex: 1 }}>

                    {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                    <HelmetProvider>
                        <Helmet>
                            <title>{metaTitle}</title>
                            <meta name="robots" content={siteRobots['NG']} />
                        </Helmet>
                    </HelmetProvider>

                    {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                    <div className='topspace_height'></div>
                    <h1 className='page_title'>
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </h1>

                    {/* ***** メインコンテンツ ******************************************************************* */}

                    <div className='main_space'>
                        <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>{pageTitle}</span>
                        </h1>
                        <div>{mainContents}</div>
                    </div>
                </div>

                {/* ***** フッター ******************************************************************* */}
                <SuurekiFooter />
            </div>
        </>
    );
});

export default Zzdgk5fk967fkdcdkuhMail;