// src/pages/Archives.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';    // [>]のアイコン
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    WeekName,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Celebrity {
    search_no: number;
    add_id: string;
    name: string;
    birth: string;
}


function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `著名人`
let pageTitleS = `著名人`
let uppageTitle = `著名人の情報` // ページトップのナビゲーションリンク

//const Application: React.FC = () => {
const Celebrity: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    let { pageName } = useParams<Record<string, string>>();
    let processType = "";

    if (pageName === undefined) {
        // pageNameがundefinedの場合の処理
        pageName = "";
    } else if (/^01\d{7}$/.test(pageName)) {
        // 「01」から始まる9桁の数字である場合の処理
        processType = "addid";

    } else {
        // それ以外の場合の処理
        processType = "name";
    }

    // https://db.suureki.net/_db_celebrity/_addid/010/celebrity_010000000.json

    if (processType === "addid") {
    }


    const celebrity_json_url = `https://db.suureki.net/_db_celebrity/_addid/${pageName.substring(0, 3)}/celebrity_${pageName}.json`;

    const [celebrity_data, setDatacelebrity] = useState<Celebrity[]>([]);

    useEffect(() => {
        fetch(celebrity_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(celebrity_data => setDatacelebrity(celebrity_data))
            .catch(error => {
                console.error('Error:', error);
                // URLを変更して再度データをフェッチする
                fetch("https://db.suureki.net/_db_celebrity/_addid/010/celebrity_010000000.json")
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(celebrity_data => setDatacelebrity(celebrity_data))
                    .catch(error => console.error('Error:', error));
            });
    }, [celebrity_json_url]);

    let addidMap: { [search_no: number]: string } = {};
    let nameMap: { [search_no: number]: string } = {};
    let birthMap: { [search_no: number]: string } = {};

    celebrity_data.forEach(celebrity => {
        addidMap[celebrity.search_no] = celebrity.add_id;
        nameMap[celebrity.search_no] = celebrity.name;
        birthMap[celebrity.search_no] = celebrity.birth;
    });

    const addid = addidMap[0];
    const name = nameMap[0];
    const birth = birthMap[0];

    let sameNameUserIdA = "";
    let sameNameUserIdB = "";
    let sameNameUserIdC = "";
    let sameNameUserIdD = "";
    let sameNameUserIdE = "";
    let sameNameUserIdF = "";

    let sameNameUserNameA = "";
    let sameNameUserNameB = "";
    let sameNameUserNameC = "";
    let sameNameUserNameD = "";
    let sameNameUserNameE = "";
    let sameNameUserNameF = "";

    let sameNameUserBirthA = "";
    let sameNameUserBirthB = "";
    let sameNameUserBirthC = "";
    let sameNameUserBirthD = "";
    let sameNameUserBirthE = "";
    let sameNameUserBirthF = "";

    let sameNameUserChk = "";

    if (addidMap[1] !== undefined) {
        sameNameUserIdA = addidMap[1];
        sameNameUserNameA = nameMap[1] + "さん";
        sameNameUserBirthA = "（" + birthMap[1].substring(0, 4) + "年" + parseInt(birthMap[1].substring(5, 7), 10) + "月" + parseInt(birthMap[1].substring(8, 10), 10) + "日生）";
        sameNameUserBirthA = sameNameUserBirthA.replace("0000年", "");
        sameNameUserBirthA = sameNameUserBirthA.replace("0月0日", "");
        if (sameNameUserBirthA === "（生）") { sameNameUserBirthA = "（生年月日不明）"; }
        sameNameUserChk = "<hr />計2人の同じ名前の方が登録されています。";
    }
    if (addidMap[2] !== undefined) {
        sameNameUserIdB = addidMap[2];
        sameNameUserNameB = nameMap[2] + "さん";
        sameNameUserBirthB = "（" + birthMap[2].substring(0, 4) + "年" + parseInt(birthMap[2].substring(5, 7), 10) + "月" + parseInt(birthMap[2].substring(8, 10), 10) + "日生）";
        sameNameUserBirthB = sameNameUserBirthB.replace("0000年", "");
        sameNameUserBirthB = sameNameUserBirthB.replace("0月0日", "");
        if (sameNameUserBirthB === "（生）") { sameNameUserBirthB = "（生年月日不明）"; }
        sameNameUserChk = "<hr />計3人の同じ名前の方が登録されています。";
    }
    if (addidMap[3] !== undefined) {
        sameNameUserIdC = addidMap[3];
        sameNameUserNameC = nameMap[3] + "さん";
        sameNameUserBirthC = "（" + birthMap[3].substring(0, 4) + "年" + parseInt(birthMap[3].substring(5, 7), 10) + "月" + parseInt(birthMap[3].substring(8, 10), 10) + "日生）";
        sameNameUserBirthC = sameNameUserBirthC.replace("0000年", "");
        sameNameUserBirthC = sameNameUserBirthC.replace("0月0日", "");
        if (sameNameUserBirthC === "（生）") { sameNameUserBirthC = "（生年月日不明）"; }
        sameNameUserChk = "<hr />計4人の同じ名前の方が登録されています。";
    }
    if (addidMap[4] !== undefined) {
        sameNameUserIdD = addidMap[4];
        sameNameUserNameD = nameMap[4] + "さん";
        sameNameUserBirthD = "（" + birthMap[4].substring(0, 4) + "年" + parseInt(birthMap[4].substring(5, 7), 10) + "月" + parseInt(birthMap[4].substring(8, 10), 10) + "日生）";
        sameNameUserBirthD = sameNameUserBirthD.replace("0000年", "");
        sameNameUserBirthD = sameNameUserBirthD.replace("0月0日", "");
        if (sameNameUserBirthD === "（生）") { sameNameUserBirthD = "（生年月日不明）"; }
        sameNameUserChk = "<hr />計5人の同じ名前の方が登録されています。";
    }
    if (addidMap[5] !== undefined) {
        sameNameUserIdE = addidMap[5];
        sameNameUserNameE = nameMap[5] + "さん";
        sameNameUserBirthE = "（" + birthMap[5].substring(0, 4) + "年" + parseInt(birthMap[5].substring(5, 7), 10) + "月" + parseInt(birthMap[5].substring(8, 10), 10) + "日生）";
        sameNameUserBirthE = sameNameUserBirthE.replace("0000年", "");
        sameNameUserBirthE = sameNameUserBirthE.replace("0月0日", "");
        if (sameNameUserBirthE === "（生）") { sameNameUserBirthE = "（生年月日不明）"; }
        sameNameUserChk = "<hr />計6人の同じ名前の方が登録されています。";
    }
    if (addidMap[6] !== undefined) {
        sameNameUserIdF = addidMap[6];
        sameNameUserNameF = nameMap[6] + "さん";
        sameNameUserBirthF = "（" + birthMap[6].substring(0, 4) + "年" + parseInt(birthMap[6].substring(5, 7), 10) + "月" + parseInt(birthMap[6].substring(8, 10), 10) + "日生）";
        sameNameUserBirthF = sameNameUserBirthF.replace("0000年", "");
        sameNameUserBirthF = sameNameUserBirthF.replace("0月0日", "");
        if (sameNameUserBirthF === "（生）") { sameNameUserBirthF = "（生年月日不明）"; }
        sameNameUserChk = "<hr />6人の同じ名前の方が登録されています。";
    }

    const today = new Date();
    const todayY = today.getFullYear().toString(); // 年
    // const todayM = (today.getMonth() + 1).toString().padStart(2, '0'); // 月（0-11なので+1が必要）
    // const todayD = today.getDate().toString().padStart(2, '0'); // 日
    // const todayYMD = todayY + todayM + todayD;
    // const todayW = today.getDay();

    let birthJ;
    let abutAge: Number;
    let abutAgeTxt = "";
    let searchPlus = "";

    let birthLink = "";
    let birthLinkYMD = "";


    if (birth === undefined || birth === "0000-00-00") {
        birthJ = "不明";
    } else if (birth.substring(0, 4) === "0000") {
        birthJ = parseInt(birth.substring(5, 7), 10) + "月" + parseInt(birth.substring(8, 10), 10) + "日（年不明）";
        searchPlus = parseInt(birth.substring(5, 7), 10) + "月" + parseInt(birth.substring(8, 10), 10) + "日生まれ";
    } else if (birth.substring(5, 10) === "00-00") {
        birthJ = birth.substring(0, 4) + "年（月日不明）";
        searchPlus = birth.substring(0, 4) + "年生まれ";
        abutAge = Number(todayY) - Number(birth.substring(0, 4));
        abutAgeTxt = abutAge + "年前に生まれました。";
    } else {
        const birthdate = new Date(Number(birth.substring(0, 4)), (Number(birth.substring(5, 7)) - 1), Number(birth.substring(8, 10)));
        const birthdateW = birthdate.getDay();

        birthJ = birth.substring(0, 4) + "年" + parseInt(birth.substring(5, 7), 10) + "月" + parseInt(birth.substring(8, 10), 10) + "日（" + WeekName[birthdateW] + "）";
        searchPlus = birth.substring(0, 4) + "年" + parseInt(birth.substring(5, 7), 10) + "月" + parseInt(birth.substring(8, 10), 10) + "日生まれ";
        abutAge = Number(todayY) - Number(birth.substring(0, 4));
        abutAgeTxt = abutAge + "年前に生まれました。";
        birthLink = "誕生日のデータ・吉凶判断";
        birthLinkYMD = birth.substring(0, 4) + birth.substring(5, 7) + birth.substring(8, 10);
    }

    pageTitle = name + "さん";
    pageTitleS = name + "さん";

    let breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Link
            component={RouterLink}
            underline="hover"
            key="2"
            color="inherit"
            to="/celebrity"
            onClick={handleClick}
        >
            {uppageTitle}
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitleS}
        </Typography>,
    ];


    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{name}さんについて</h2>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'right' }}>suureki-ID：</td><td>{addid}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }}>名前：</td><td>{name}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }}>生年月日：</td><td>{birthJ}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }}></td><td>{abutAgeTxt}</td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right' }}></td><td>
                                <Link
                                    component={RouterLink}
                                    underline="hover"
                                    key="2"
                                    color="primary"
                                    to={`/birth/${birthLinkYMD}`}
                                    onClick={handleClick}
                                >
                                    {birthLink}
                                </Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>{sameNameUserChk && <div dangerouslySetInnerHTML={{ __html: sameNameUserChk }} />}</div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdA}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameA}{sameNameUserBirthA}
                    </Link>
                </div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdB}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameB}{sameNameUserBirthB}
                    </Link>
                </div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdC}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameC}{sameNameUserBirthC}
                    </Link>
                </div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdD}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameD}{sameNameUserBirthD}
                    </Link>
                </div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdE}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameE}{sameNameUserBirthE}
                    </Link>
                </div>
                <div>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/celebrity/${sameNameUserIdF}`}
                        onClick={handleClick}
                    >
                        {sameNameUserNameF}{sameNameUserBirthF}
                    </Link>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>名前を他のサイトで調べる</h2>
                <div>
                    <a href={`http://www.google.com/search?q=${name}+${searchPlus}`} target="_blank" rel="noopener noreferrer">Google</a>
                </div>
                <div>
                    <a href={`http://www.bing.com/search?q=${name}+${searchPlus}`} target="_blank" rel="noopener noreferrer">Bing</a>
                </div>
                <div>
                    <a href={`https://search.yahoo.co.jp/search?p=${name}+${searchPlus}`} target="_blank" rel="noopener noreferrer">Yahoo</a>
                </div>
                <div>
                    <a href={`https://duckduckgo.com/?q=${name}+${searchPlus}`} target="_blank" rel="noopener noreferrer">Duckduckgo</a>
                </div>
                <div>
                    <a href={`http://ja.wikipedia.org/wiki/Special:Search?search=${name}`} target="_blank" rel="noopener noreferrer">Wiki</a>
                </div>
                <div>
                    <a href={`http://www.youtube.com/results?search_query=${name}`} target="_blank" rel="noopener noreferrer">youtube</a>
                </div>
                <div>
                    <a href={`http://twitter.com/search?q=${name}`} target="_blank" rel="noopener noreferrer">X（旧twitter）</a>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
        </div>
    ];

    const pageKey = "Celebrity";
    const pagePath = pagePathS[pageKey] + pageName;


    const metaDescription = pageTitle + pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    console.log('pageTitle:', pageTitle);


    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid && name != undefined) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    /*
    google: "http://www.google.com/search?q=TESTSEARCH",
    bing: "http://www.bing.com/search?q=TESTSEARCH",
    yahoo: "https://search.yahoo.co.jp/search?p=TESTSEARCH",
    duckduckgo: "https://duckduckgo.com/?q=TESTSEARCH",
    amazon: "https://www.amazon.co.jp/s?k=TESTSEARCH",
    mercari: "https://jp.mercari.com/search?keyword=TESTSEARCH",
    yodobashi: "https://www.yodobashi.com/?word=TESTSEARCH",
    yahooshopping: "https://shopping.yahoo.co.jp/search?p=TESTSEARCH",
    paypaymall: "https://paypaymall.yahoo.co.jp/search?p=TESTSEARCH",
    paypayfreem: "https://paypayfleamarket.yahoo.co.jp/search/TESTSEARCH",
    yahuoku: "https://auctions.yahoo.co.jp/search/search?p=TESTSEARCH",
    calil: "https://calil.jp/search?q=TESTSEARCH",
    twitter: "http://twitter.com/#search?q=TESTSEARCH",
    wiki: "http://ja.wikipedia.org/wiki/Special:Search?search=TESTSEARCH",
    youtube: "http://www.youtube.com/results?search_query=TESTSEARCH"
    sakurachecker: "https://sakura-checker.jp/itemsearch/?word=TESTSEARCH"
    aliexpress: "https://sakura-checker.jp/itemsearch/?word=TESTSEARCH"
    instagram: "https://www.instagram.com/explore/tags/TESTSEARCH/?hl=ja"
    */

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <Celebrity />
    </AuthProvider>
);

export default App;