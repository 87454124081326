import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { birthDatDeletedUrl } from '../SuurekiVar';

const Jump: React.FC = () => {
    const navigate = useNavigate();
    const { getdata } = useParams<Record<string, string>>();

    const getdata2 = getdata ? getdata.replace(/[^-A-Za-z0-9]/g, '').slice(0, 100) : '';

    useEffect(() => {
        if (getdata2) {
            fetch(birthDatDeletedUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ uid: getdata2 }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [getdata2]);

    useEffect(() => {
        navigate(`/mypage`);
    }, [getdata, navigate]);  // useEffect内で使用している依存性を配列に含める

    return null;  // このコンポーネントは何もレンダリングしません
};

export default Jump;