import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import {
    User,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    onAuthStateChanged
} from 'firebase/auth';
import { auth } from '../lib/firebase';

type UserInfo = {
    displayName: string;
    email: string | null;
    photoURL: string | null;
    uid: string;
};

type AuthContextType = {
    user: User | null;
    userInfo: UserInfo | null;
    loading: boolean;
    signInWithGoogle: () => Promise<void>;
    logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<User | null>(null);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                // ユーザー情報を構造化して保存
                setUserInfo({
                    displayName: user.displayName || '名前なし',
                    email: user.email,
                    photoURL: user.photoURL,
                    uid: user.uid
                });
            } else {
                setUserInfo(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            // ログイン直後にもユーザー情報を更新
            setUserInfo({
                displayName: user.displayName || '名前なし',
                email: user.email,
                photoURL: user.photoURL,
                uid: user.uid
            });
        } catch (error) {
            console.error('Google Sign In Error:', error);
            throw error;
        }
    };

    const logout = async () => {
        try {
            await signOut(auth);
            setUserInfo(null);
        } catch (error) {
            console.error('Logout Error:', error);
            throw error;
        }
    };

    const value = {
        user,
        userInfo,
        loading,
        signInWithGoogle,
        logout
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};