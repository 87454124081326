// src/pages/CalendarSearchIndex.tsx
// import * as React from 'react';
import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, IconButton, Checkbox, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Alert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    EtoName12,
    SearchSenjitsu,
    SeacrhZassetsu,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

const initialData: string[] = ["二十四節気"];

// const chkboxOption = SearchSenjitsu.concat(SeacrhZassetsu).concat(EtoName12);
let chkboxOption: string[] = initialData.concat(SearchSenjitsu, SeacrhZassetsu, EtoName12);
let chkboxOptionCount = chkboxOption.length;
// console.log(chkboxOptionCountPlus);

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}
let pageTitle = `二十四節気/選日/雑節/十二支の検索`
let pageTitleS = `暦の検索`


const breadcrumbs = [
    <Link
        component={RouterLink}
        underline="hover"
        key="1"
        color="inherit"
        to="/"
        onClick={handleClick}>
        <IconButton
            aria-label="ホームページへページ移動"
            sx={{
                margin: '0px 0px 1px 0px',
                padding: '0px'
            }}>
            <HomeIcon />
        </IconButton>
    </Link>,
    <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
        {pageTitleS}
    </Typography>,
];

// const selectchk: number[] = [0, 0, 0, 0, 0];
let selectchk: number[] = Array(chkboxOptionCount).fill(0);
let sendDataZero = "0".repeat(chkboxOptionCount);

// const Calendar: React.FC = () => {
const Calendar: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    const dateY = new Date().getFullYear();
    const dateYBefore = dateY - 10;
    const dateYAfter = dateY + 11;
    let dateJpNumberD; // 和暦の算出：平成
    let dateJpNumberE; // 和暦の算出：令和
    let dateJpNumberDtxt = ""; // 和暦の算出：令和
    let dateJpNumberEtxt = ""; // 和暦の算出：令和
    let count = -10;
    let countTxt = "";

    const dateYJp: { [key: number]: string } = {};
    const todayYChk: { [key: number]: string } = {};

    for (let i = dateYBefore; i <= dateYAfter; i++) {

        dateJpNumberD = Number(i) - 1988; // 和暦の算出：平成
        dateJpNumberE = Number(i) - 2018; // 和暦の算出：令和
        dateJpNumberDtxt = '/平成' + dateJpNumberD + '年';

        if (dateJpNumberE === 1) {
            dateJpNumberEtxt = '/令和元年';
        } else if (dateJpNumberE > 1) {
            dateJpNumberEtxt = '/令和' + dateJpNumberE + '年';
            dateJpNumberDtxt = '';
        }

        if (count < 0) {
            // countTxt = Math.abs(count) + '年前'
            countTxt = '';
        } else if (count === 0) {
            countTxt = '（今年）'
        } else {
            // countTxt = count + '年後'
            countTxt = '';
        }

        dateYJp[i] = dateJpNumberDtxt + dateJpNumberEtxt;
        todayYChk[i] = countTxt + '';
        count++;

    }

    // const [checked, setChecked] = React.useState([false, false, false, false, false]);
    const [checked, setChecked] = React.useState(Array(chkboxOptionCount).fill(false));
    const [selectyear, setYear] = React.useState(dateY.toString());

    const handleChange = (event: SelectChangeEvent) => {
        setYear(event.target.value as string);
    };
    const handleChangeParent = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = Array(chkboxOptionCount).fill(event.target.checked);
        setChecked(newChecked);
    };
    const handleChangeChild = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = [...checked];
        newChecked[index] = event.target.checked;
        setChecked(newChecked);
    };
    const chkBox = (
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', ml: 3 }}>
            {Array.from({ length: chkboxOptionCount }).map((_, index) => (
                <FormControlLabel
                    key={index}
                    label={<span style={{ color: '#666' }}>{`${chkboxOption[index]}`}</span>}
                    control={<Checkbox checked={checked[index]} onChange={handleChangeChild(index)} />}
                />
            ))}
        </Box>
    );
    for (let i = 0; i < checked.length; i++) {
        selectchk[i] = checked[i] ? 1 : 0;
    }
    // let sendData = selectyear;
    let sendData = "";
    for (let i = 0; i < chkboxOptionCount; i++) {
        sendData = sendData + selectchk[i].toString();
    }
    let sendDataChk;
    if (sendData === sendDataZero) {
        sendDataChk = null;
    } else {
        sendDataChk = "ok";
    }
    sendData = selectyear + sendData;

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>検索フォーム</h2>
                <Alert severity="info" sx={{ marginBottom: '2rem' }}>
                    <Link component={RouterLink} underline="always" key="annotation-sz" color="primary" to="/annotation/sz" onClick={handleClick}>
                        選日と雑節の意味
                    </Link>
                    <div style={{ marginTop: '0.5rem' }}>
                        「巳」での検索時のみ、十干と十二支「己巳」が表示されます。
                    </div>
                </Alert>
                <Box sx={{ minWidth: 200 }}>
                    <form>
                        <FormControl fullWidth >
                            <InputLabel id="calendarsearch-label">表示する年</InputLabel>
                            <Select
                                labelId="calendarsearch-select-label"
                                id="calendarsearch-select"
                                value={selectyear}
                                defaultValue={dateY.toString()}
                                label="select year"
                                onChange={handleChange}
                            >
                                {Array.from({ length: 21 }, (_, index) => (
                                    <MenuItem key={dateYBefore + index} value={dateYBefore + index}>
                                        {dateYBefore + index}年{dateYJp[dateYBefore + index]}{todayYChk[dateYBefore + index]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: '1.0rem' }}>
                            <FormControlLabel
                                sx={{ color: 'green' }}
                                label="一括選択・解除"
                                control={
                                    <Checkbox
                                        checked={checked.every(v => v)}
                                        indeterminate={checked.some(v => v) && !checked.every(v => v)}
                                        onChange={handleChangeParent}
                                    />
                                }
                            />
                            {chkBox}
                        </div>
                        <div style={{ marginTop: '1.0rem' }}>
                            <Button
                                component={Link}
                                href={`/calendar_search/${sendData}`}
                                variant="contained"
                                endIcon={<SearchIcon />}
                                sx={{ marginLeft: '1.5rem' }}
                                disabled={!sendDataChk}
                            >
                                表示する
                            </Button>
                        </div>
                    </form>
                </Box>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>検索サンプル</h2>
                <ul>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY}100000000001100000000000000000000000`} onClick={handleClick}>
                            今年（{dateY}年）の二十四節気と節分と彼岸
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY}001000000010000000000000001000000000`} onClick={handleClick}>
                            今年（{dateY}年）の一粒万倍日、天赦日、寅の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY}000000000000000001000000000000100000`} onClick={handleClick}>
                            今年（{dateY}年）の初午と午の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY}000000000000000000000000000001000000`} onClick={handleClick}>
                            今年（{dateY}年）の巳の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY}000000000000100000000000000000000000`} onClick={handleClick}>
                            今年（{dateY}年）の彼岸の日
                        </Link>
                    </li>
                </ul>
                <ul>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY + 1}100000000001100000000000000000000000`} onClick={handleClick}>
                            来年（{dateY + 1}年）の二十四節気と節分と彼岸
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY + 1}001000000010000000000000001000000000`} onClick={handleClick}>
                            来年（{dateY + 1}年）の一粒万倍日、天赦日、寅の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY + 1}000000000000000001000000000000100000`} onClick={handleClick}>
                            来年（{dateY + 1}年）の初午と午の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY + 1}000000000000000000000000000001000000`} onClick={handleClick}>
                            来年（{dateY + 1}年）の巳の日
                        </Link>
                    </li>
                    <li className='inside-list-style'>
                        <Link component={RouterLink} underline="always" key="home" color="primary" to={`/calendar_search/${dateY + 1}000000000000100000000000000000000000`} onClick={handleClick}>
                            来年（{dateY + 1}年）の彼岸の日
                        </Link>
                    </li>
                </ul>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];

    const pageKey = "CalendarSearchIndex";
    const pagePath = pagePathS[pageKey];

    const metaDescription = pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});
// };

const App = () => (
    <AuthProvider>
        <Calendar />
    </AuthProvider>
);

export default App;