// src/pages/Archives.tsx

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Breadcrumbs, Typography, Stack, styled, IconButton, Alert } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';                    // ホームアイコン
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import EastIcon from '@mui/icons-material/East';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthIcon from '@mui/icons-material/South';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import WestIcon from '@mui/icons-material/West';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import SuurekiFooter from '../SuurekiFooter';                        // フッター
import {
    ZasouName,
    ZasouNameMeaning,
    ZasouNameColor,
    EtoCompass,
    EtoCompassDirection,
    siteName,
    siteNameS,
    siteType,
    siteTypePage,
    siteLocale,
    siteUrlHead,
    siteImg,
    siteImgW,
    siteImgH,
    twitterCard,
    twitterSite,
    pageDescription,
    pagePathS,
    siteRobots,
    publisherLogoUrl,
    datePublished,
    dateModified,
    pagelogWriteUrl,
} from '../SuurekiVar';
import EncryptUserUid from '../components/EncryptUserUid'; // 暗号化コンポーネントをインポート
import AdSenseBanner from '../components/AdSenseBanner'; // AdSenseBanner をインポート
import AdSenseInarticle from '../components/AdSenseInarticle'; // AdSenseInarticle をインポート
import AdSenseMultiplex from '../components/AdSenseMultiplex'; // AdSenseMultiplex をインポート
import { AuthProvider } from '../contexts/AuthContext';
// import { GoogleSignIn } from '../components/GoogleSignIn';
// import { UserProfile } from '../components/UserProfile';
import { useAuth } from '../contexts/AuthContext';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');  // 日本語ロケールを設定

// const useUserName = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.displayName || 'none';
// };
// const useUserEmail = () => {
//     const { userInfo } = useAuth();
//     return userInfo?.email || 'none';
// };
const useUserUid = () => {
    const { userInfo } = useAuth();
    return userInfo?.uid || 'none';
};

interface Aspect {
    zasou_year: string;          /* */
    zasou_eto10: string;         /* 年干支（10） */
    zasou_eto12: string;         /* 年干支（12） */
    zasou_daisyougun: string;    /* 大将軍 */
    zasou_toshitoku: string;     /* 歳徳神/恵方 */
    zasou_taisaishin: string;    /* 太歳神 */
    zasou_taionshin: string;     /* 太陰神 */
    zasou_saigyoushin: string;   /* 歳刑神 */
    zasou_saihashin: string;     /* 歳破神 */
    zasou_saisetsushin: string;  /* 歳殺神 */
    zasou_ouhanjin: string;      /* 黄幡神 */
    zasou_hyoubishin: string;    /* 豹尾神 */
    zasou_tendo: string;         /* 天道（2ヶ所） */
    zasou_tentoku: string;       /* 天徳 */
    zasou_tentokugou: string;    /* 天徳合 */
    zasou_gettoku: string;       /* 月徳 */
    zasou_gettokugou: string;    /* 月徳合 */
    zasou_jindo: string;         /* 人道（2ヶ所） */
    zasou_sairokujin: string;    /* 歳禄神 */
    zasou_saishitoku: string;    /* 歳枝徳 */
    zasou_toshitokugou: string;  /* 歳徳合 */
    zasou_seiki: string;         /* 生気 */
    zasou_toshibajin: string;    /* 歳馬神 */
    zasou_taiyojin: string;      /* 太陽神 */
    zasou_ryutokujin: string;    /* 竜徳神 */
    zasou_hukutokujin: string;   /* 福徳神 */
    zasou_megurikonjin: string;  /* 巡金神（2~6ヶ所） */
    zasou_himekonjin: string;    /* 姫金神 */
    zasou_daikonjin: string;     /* 大金神 */
    zasou_byouhu: string;        /* 病符 */
    zasou_shihu: string;         /* 死符 */
    zasou_byakko: string;        /* 白虎 */
    zasou_totensatsu: string;    /* 都天殺（2~4ヶ所） */
    zasou_saisatsu: string;      /* 災殺 */
    zasou_gousatsu: string;      /* 劫殺 */
    zasou_sanshitsu: string;     /* 蚕室 */
    zasou_goousatsu: string;     /* 五黄殺 */
    zasou_anensatsu: string;     /* 暗剣殺 */
    zasou_kyusei_center: string; /* 九星（中心） */
    zasou_kyusei_angle: string;  /* 九星（北から時計回り） */
    zasou_angle_0: string;
    zasou_angle_1: string;
    zasou_angle_2: string;
    zasou_angle_3: string;
    zasou_angle_4: string;
    zasou_angle_5: string;
    zasou_angle_6: string;
    zasou_angle_7: string;
    zasou_angle_8: string;
    zasou_angle_9: string;
    zasou_angle_10: string;
    zasou_angle_11: string;
    zasou_angle_12: string;
    zasou_angle_13: string;
    zasou_angle_14: string;
    zasou_angle_15: string;
    zasou_angle_16: string;
    zasou_angle_17: string;
    zasou_angle_18: string;
    zasou_angle_19: string;
    zasou_angle_20: string;
    zasou_angle_21: string;
    zasou_angle_22: string;
    zasou_angle_23: string;

}

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    console.info('You clicked a breadcrumb.');
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'linear-gradient(to right, rgba(231, 244, 255, 0.99),rgba(231, 244, 255, 0.99), rgba(243, 250, 255, 0.99))',
        color: 'black',
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid black',
    },
}));

let pageTitle = `各年の座相`
let pageTitleS = `各年の座相` // ページトップのナビゲーションリンク
let uppageTitle = `座相`


/*

const breadcrumbs = [
]

下記へ移動

*/

const today = new Date();
let dateY = today.getFullYear();

declare const require: {
    context(directory: string, useSubdirectories: boolean, regExp: RegExp): any;
};

// const Application: React.FC = React.memo(() => {

const AspectIndex: React.FC = React.memo(() => {

    const userUid = useUserUid();
    const [encryptedUid, setEncryptedUid] = React.useState('');

    const handleEncrypted = (encrypted: string) => {
        setEncryptedUid(encrypted);
    };

    // 把握 //////////////////////////////////////////////////////////////////////

    const { date } = useParams<{ date: string }>();

    let year: number | null = null;
    if (date !== undefined) {
        year = Number(date.slice(0, 4));
        if (isNaN(year)) {
            year = dateY;
        } else if (year < 1901) {
            year = 1901;
        } else if (year > 2100) {
            year = 2100;
        }
        dateY = year;
    }
    let dateF = dateY + "0101";

    pageTitle = dateY + "年の座相";
    pageTitleS = dateY + "年の座相";

    const breadcrumbs = [
        <Link
            component={RouterLink}
            underline="hover"
            key="1"
            color="inherit"
            to="/"
            onClick={handleClick}>
            <IconButton
                aria-label="ホームページへページ移動"
                sx={{
                    margin: '0px 0px 1px 0px',
                    padding: '0px'
                }}>
                <HomeIcon />
            </IconButton>
        </Link>,
        <Link
            component={RouterLink}
            underline="hover"
            key="2"
            color="inherit"
            to="/aspect"
            onClick={handleClick}
        >
            {uppageTitle}
        </Link>,
        <Typography key="3" color="text.primary" style={{ fontSize: '1.0rem' }}>
            {pageTitleS}
        </Typography>,
    ];
    // 該当年jsonの呼び出し //////////////////////////////////////////////////////////////////////

    const aspect_json_url = `https://db.suureki.net/_db_aspect/zasoudata_angle_${dateY}.json`;

    const [aspect_data, setDataAspect] = useState<Aspect[]>([]);
    useEffect(() => {
        fetch(aspect_json_url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(aspect_data => setDataAspect(aspect_data))
            .catch(error => console.error('Error:', error));
    }, [aspect_json_url]);



    let zasou_eto10: { [zasou_year: string]: any } = {};             /* 年干支（10） */
    let zasou_eto12: { [zasou_year: string]: any } = {};             /* 年干支（12） */
    let zasou_daisyougun: { [zasou_year: string]: any } = {};        /* 大将軍 */
    let zasou_toshitoku: { [zasou_year: string]: any } = {};         /* 歳徳神/恵方 */
    let zasou_taisaishin: { [zasou_year: string]: any } = {};        /* 太歳神 */
    let zasou_taionshin: { [zasou_year: string]: any } = {};         /* 太陰神 */
    let zasou_saigyoushin: { [zasou_year: string]: any } = {};       /* 歳刑神 */
    let zasou_saihashin: { [zasou_year: string]: any } = {};         /* 歳破神 */
    let zasou_saisetsushin: { [zasou_year: string]: any } = {};      /* 歳殺神 */
    let zasou_ouhanjin: { [zasou_year: string]: any } = {};          /* 黄幡神 */
    let zasou_hyoubishin: { [zasou_year: string]: any } = {};        /* 豹尾神 */
    let zasou_tendo: { [zasou_year: string]: any } = {};             /* 天道（2ヶ所） */
    let zasou_tentoku: { [zasou_year: string]: any } = {};           /* 天徳 */
    let zasou_tentokugou: { [zasou_year: string]: any } = {};        /* 天徳合 */
    let zasou_gettoku: { [zasou_year: string]: any } = {};           /* 月徳 */
    let zasou_gettokugou: { [zasou_year: string]: any } = {};        /* 月徳合 */
    let zasou_jindo: { [zasou_year: string]: any } = {};             /* 人道（2ヶ所） */
    let zasou_sairokujin: { [zasou_year: string]: any } = {};        /* 歳禄神 */
    let zasou_saishitoku: { [zasou_year: string]: any } = {};        /* 歳枝徳 */
    let zasou_toshitokugou: { [zasou_year: string]: any } = {};      /* 歳徳合 */
    let zasou_seiki: { [zasou_year: string]: any } = {};             /* 生気 */
    let zasou_toshibajin: { [zasou_year: string]: any } = {};        /* 歳馬神 */
    let zasou_taiyojin: { [zasou_year: string]: any } = {};          /* 太陽神 */
    let zasou_ryutokujin: { [zasou_year: string]: any } = {};        /* 竜徳神 */
    let zasou_hukutokujin: { [zasou_year: string]: any } = {};       /* 福徳神 */
    let zasou_megurikonjin: { [zasou_year: string]: any } = {};      /* 巡金神（2~6ヶ所） */
    let zasou_himekonjin: { [zasou_year: string]: any } = {};        /* 姫金神 */
    let zasou_daikonjin: { [zasou_year: string]: any } = {};         /* 大金神 */
    let zasou_byouhu: { [zasou_year: string]: any } = {};            /* 病符 */
    let zasou_shihu: { [zasou_year: string]: any } = {};             /* 死符 */
    let zasou_byakko: { [zasou_year: string]: any } = {};            /* 白虎 */
    let zasou_totensatsu: { [zasou_year: string]: any } = {};        /* 都天殺（2~4ヶ所） */
    let zasou_saisatsu: { [zasou_year: string]: any } = {};          /* 災殺 */
    let zasou_gousatsu: { [zasou_year: string]: any } = {};          /* 劫殺 */
    let zasou_sanshitsu: { [zasou_year: string]: any } = {};         /* 蚕室 */
    let zasou_goousatsu: { [zasou_year: string]: any } = {};         /* 五黄殺 */
    let zasou_anensatsu: { [zasou_year: string]: any } = {};         /* 暗剣殺 */
    let zasou_kyusei_center: { [zasou_year: string]: any } = {};     /* 九星（中心） */
    let zasou_kyusei_angle: { [zasou_year: string]: any } = {};      /* 九星（北から時計回り） */
    let zasou_angle_0: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_1: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_2: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_3: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_4: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_5: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_6: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_7: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_8: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_9: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_10: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_11: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_12: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_13: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_14: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_15: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_16: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_17: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_18: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_19: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_20: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_21: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_22: { [zasou_year: string]: any } = {};      /*  */
    let zasou_angle_23: { [zasou_year: string]: any } = {};      /*  */
    aspect_data.forEach(aspect => {
        zasou_eto10[aspect.zasou_year] = aspect.zasou_eto10;
        zasou_eto12[aspect.zasou_year] = aspect.zasou_eto12;
        zasou_daisyougun[aspect.zasou_year] = aspect.zasou_daisyougun;
        zasou_toshitoku[aspect.zasou_year] = aspect.zasou_toshitoku;
        zasou_taisaishin[aspect.zasou_year] = aspect.zasou_taisaishin;
        zasou_taionshin[aspect.zasou_year] = aspect.zasou_taionshin;
        zasou_saigyoushin[aspect.zasou_year] = aspect.zasou_saigyoushin;
        zasou_saihashin[aspect.zasou_year] = aspect.zasou_saihashin;
        zasou_saisetsushin[aspect.zasou_year] = aspect.zasou_saisetsushin;
        zasou_ouhanjin[aspect.zasou_year] = aspect.zasou_ouhanjin;
        zasou_hyoubishin[aspect.zasou_year] = aspect.zasou_hyoubishin;
        zasou_tendo[aspect.zasou_year] = aspect.zasou_tendo;
        zasou_tentoku[aspect.zasou_year] = aspect.zasou_tentoku;
        zasou_tentokugou[aspect.zasou_year] = aspect.zasou_tentokugou;
        zasou_gettoku[aspect.zasou_year] = aspect.zasou_gettoku;
        zasou_gettokugou[aspect.zasou_year] = aspect.zasou_gettokugou;
        zasou_jindo[aspect.zasou_year] = aspect.zasou_jindo;
        zasou_sairokujin[aspect.zasou_year] = aspect.zasou_sairokujin;
        zasou_saishitoku[aspect.zasou_year] = aspect.zasou_saishitoku;
        zasou_toshitokugou[aspect.zasou_year] = aspect.zasou_toshitokugou;
        zasou_seiki[aspect.zasou_year] = aspect.zasou_seiki;
        zasou_toshibajin[aspect.zasou_year] = aspect.zasou_toshibajin;
        zasou_taiyojin[aspect.zasou_year] = aspect.zasou_taiyojin;
        zasou_ryutokujin[aspect.zasou_year] = aspect.zasou_ryutokujin;
        zasou_hukutokujin[aspect.zasou_year] = aspect.zasou_hukutokujin;
        zasou_megurikonjin[aspect.zasou_year] = aspect.zasou_megurikonjin;
        zasou_himekonjin[aspect.zasou_year] = aspect.zasou_himekonjin;
        zasou_daikonjin[aspect.zasou_year] = aspect.zasou_daikonjin;
        zasou_byouhu[aspect.zasou_year] = aspect.zasou_byouhu;
        zasou_shihu[aspect.zasou_year] = aspect.zasou_shihu;
        zasou_byakko[aspect.zasou_year] = aspect.zasou_byakko;
        zasou_totensatsu[aspect.zasou_year] = aspect.zasou_totensatsu;
        zasou_saisatsu[aspect.zasou_year] = aspect.zasou_saisatsu;
        zasou_gousatsu[aspect.zasou_year] = aspect.zasou_gousatsu;
        zasou_sanshitsu[aspect.zasou_year] = aspect.zasou_sanshitsu;
        zasou_goousatsu[aspect.zasou_year] = aspect.zasou_goousatsu;
        zasou_anensatsu[aspect.zasou_year] = aspect.zasou_anensatsu;
        zasou_kyusei_center[aspect.zasou_year] = aspect.zasou_kyusei_center;
        zasou_kyusei_angle[aspect.zasou_year] = aspect.zasou_kyusei_angle;
        zasou_angle_0[aspect.zasou_year] = aspect.zasou_angle_0;
        zasou_angle_1[aspect.zasou_year] = aspect.zasou_angle_1;
        zasou_angle_2[aspect.zasou_year] = aspect.zasou_angle_2;
        zasou_angle_3[aspect.zasou_year] = aspect.zasou_angle_3;
        zasou_angle_4[aspect.zasou_year] = aspect.zasou_angle_4;
        zasou_angle_5[aspect.zasou_year] = aspect.zasou_angle_5;
        zasou_angle_6[aspect.zasou_year] = aspect.zasou_angle_6;
        zasou_angle_7[aspect.zasou_year] = aspect.zasou_angle_7;
        zasou_angle_8[aspect.zasou_year] = aspect.zasou_angle_8;
        zasou_angle_9[aspect.zasou_year] = aspect.zasou_angle_9;
        zasou_angle_10[aspect.zasou_year] = aspect.zasou_angle_10;
        zasou_angle_11[aspect.zasou_year] = aspect.zasou_angle_11;
        zasou_angle_12[aspect.zasou_year] = aspect.zasou_angle_12;
        zasou_angle_13[aspect.zasou_year] = aspect.zasou_angle_13;
        zasou_angle_14[aspect.zasou_year] = aspect.zasou_angle_14;
        zasou_angle_15[aspect.zasou_year] = aspect.zasou_angle_15;
        zasou_angle_16[aspect.zasou_year] = aspect.zasou_angle_16;
        zasou_angle_17[aspect.zasou_year] = aspect.zasou_angle_17;
        zasou_angle_18[aspect.zasou_year] = aspect.zasou_angle_18;
        zasou_angle_19[aspect.zasou_year] = aspect.zasou_angle_19;
        zasou_angle_20[aspect.zasou_year] = aspect.zasou_angle_20;
        zasou_angle_21[aspect.zasou_year] = aspect.zasou_angle_21;
        zasou_angle_22[aspect.zasou_year] = aspect.zasou_angle_22;
        zasou_angle_23[aspect.zasou_year] = aspect.zasou_angle_23;
    });

    const navigate = useNavigate();

    let searchWindow;
    searchWindow = [
        <Box display="flex" alignItems="center" className='search_space' key='searchKey'>
            年盤の変更：<MobileDatePicker
                views={['year']}
                defaultValue={dayjs(dateF)}
                onChange={(newValue) => {
                    if (newValue) {
                        const formattedDate = newValue.format('YYYY');
                        navigate(`/aspect/${formattedDate}`);
                    }
                }}
                format="YYYY"
                minDate={dayjs('1901-01-01')}
                maxDate={dayjs('2100-12-31')}
            />
        </Box>
    ];

    let PrevNextCalendarLink;
    PrevNextCalendarLink = [
        <div key='prevnextcalendarlinkkeyhr' className='content_prevnextcalendarlink' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/aspect/${dayjs(date).add(-1, 'year').format('YYYY')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    前の年

                </Link>
            </span>
            <span>{dateY}</span>
            <span>
                <Link
                    component={RouterLink}
                    underline="hover"
                    key="1"
                    color="primary"
                    to={`/aspect/${dayjs(date).add(1, 'year').format('YYYY')}`}
                    onClick={handleClick}
                    style={{ textDecoration: 'none' }}
                >
                    次の年
                </Link>
            </span>
        </div>
    ];

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasScale] = useState(0.65);

    const canvasWidth = 680 * canvasScale;
    const canvasheight = 830 * canvasScale;
    const canvastableheight = "550px";
    const canvastablebgcolor = "#ddd";

    // // 画面サイズに応じてcanvasScaleを調整する関数
    // function adjustCanvasScale() {
    //     const windowWidth = window.innerWidth;
    //     if (windowWidth <= 480) {
    //         // 小さいスマホ
    //         canvasScale = 0.8;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "360px";
    //         canvastablebgcolor = "#ddd";
    //     } else if (windowWidth <= 768) {
    //         // 中サイズのデバイス
    //         canvasScale = 0.8;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "700px";
    //         canvastablebgcolor = "#ddd";
    //     } else {
    //         // それ以上
    //         canvasScale = 1.0;
    //         canvasWidth = 680 * canvasScale;
    //         canvasheight = 830 * canvasScale;
    //         canvastableheight = "880px";
    //         canvastablebgcolor = "#fff";
    //     }
    // }

    useEffect(() => {
        // const filename = "./canvasScripts/zasoudata_canvas.tsx";
        // const filename = `./canvasScripts/zasoudata_canvas_2024a.tsx`;
        const filename = `./canvasScripts/zasoudata_canvas_${dateY}a.tsx`;

        const drawCanvas = async () => {
            if (!canvasRef.current) return;

            try {
                const context = require.context('./canvasScripts', false, /\.tsx$/);
                const module = await context(`./${filename.split('/').pop()}`);


                if (module.default) {
                    // module.default(canvasScale, canvasScale, canvasRef.current);
                    module.default(canvasScale, canvasScale);
                }
            } catch (err) {
                console.error("Failed to load module:", err);
            }
        };

        drawCanvas();
    }, [canvasScale, dateY, encryptedUid]);


    type ZasouAngleType = { [zasou_year: string]: any };
    let zasou_angle: Record<number, ZasouAngleType> = {
        0: zasou_angle_0,
        1: zasou_angle_1,
        2: zasou_angle_2,
        3: zasou_angle_3,
        4: zasou_angle_4,
        5: zasou_angle_5,
        6: zasou_angle_6,
        7: zasou_angle_7,
        8: zasou_angle_8,
        9: zasou_angle_9,
        10: zasou_angle_10,
        11: zasou_angle_11,
        12: zasou_angle_12,
        13: zasou_angle_13,
        14: zasou_angle_14,
        15: zasou_angle_15,
        16: zasou_angle_16,
        17: zasou_angle_17,
        18: zasou_angle_18,
        19: zasou_angle_19,
        20: zasou_angle_20,
        21: zasou_angle_21,
        22: zasou_angle_22,
        23: zasou_angle_23,
    };

    const angleInfo = Array.from({ length: 24 }, (_, index) => {
        let angleValue = zasou_angle[index] && zasou_angle[index][dateY] ? zasou_angle[index][dateY] : null;
        let content = null;

        if (angleValue) { // angleValueがundefinedでないことを確認
            if (typeof angleValue === 'string') { // angleValueがstringであることを確認
                if (angleValue.includes(',')) {
                    content = angleValue.split(',').map((word, i) => (
                        <HtmlTooltip
                            key={`tooltip-${index}-${i}`}  // ここで一意のキーを指定
                            leaveTouchDelay={5000}
                            enterTouchDelay={0}
                            title={
                                <React.Fragment>
                                    {ZasouName[word]}：<br />{ZasouNameMeaning[word]}
                                </React.Fragment>
                            }
                            placement='top-start'
                        >
                            <span key={`word-${index}-${i}`}>
                                {i > 0 && "、"}
                                <span style={{ borderBottom: '1px dotted', color: ZasouNameColor[word] }}>{ZasouName[word]}</span>
                            </span>
                        </HtmlTooltip>
                    ));
                } else {
                    content = <HtmlTooltip
                        key={`tooltip-${index}`}  // ここでも一意のキーを追加
                        leaveTouchDelay={5000}
                        enterTouchDelay={0}
                        title={
                            <React.Fragment>
                                {ZasouName[angleValue]}：<br />{ZasouNameMeaning[angleValue]}
                            </React.Fragment>
                        }
                        placement='top-start'
                    >
                        <span style={{ borderBottom: '1px dotted', color: ZasouNameColor[angleValue] }}>{ZasouName[angleValue]}</span>
                    </HtmlTooltip>;
                }
            } else {
                content = angleValue;
            }
        }

        return (
            <li key={`angle-${index}`} className='inside-list-style-sub' style={{ lineHeight: '1.6rem' }}>
                {EtoCompass[index]}：{content}<br />
            </li>
        );
    });

    const groupedAngleInfo = [];

    const directionIconMap: { [key: number]: JSX.Element } = {
        0: <NorthIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem', fontWeight: 'bold' }} />,
        3: <NorthEastIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        6: <EastIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        9: <SouthEastIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        12: <SouthIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        15: <SouthWestIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        18: <WestIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />,
        21: <NorthWestIcon className='aspect-angle-icon' sx={{ fontSize: '0.9rem' }} />
    };

    let tmp_goousatsu = "";
    let tmp_anensatsu = "";
    for (let i = 0; i < angleInfo.length; i += 3) {
        const IconComponent = directionIconMap[i] ? directionIconMap[i] : null;

        if (zasou_goousatsu[dateY] === i / 3) {
            tmp_goousatsu = "五黄殺";
        } else {
            tmp_goousatsu = "";
        }
        if (zasou_anensatsu[dateY] === i / 3) {
            tmp_anensatsu = "暗剣殺";
        } else {
            tmp_anensatsu = "";
        }
        if (tmp_anensatsu !== "" && tmp_goousatsu !== "") {
            tmp_goousatsu = "";
            tmp_anensatsu = "";
        }

        groupedAngleInfo.push(
            <ul key={i}>
                <li className='inside-list-style' style={{ backgroundColor: '#f0f0f0', padding: '0.2rem 0.3rem 0.2rem 1rem', margin: '0.4rem 0rem 0.4rem -1rem' }}>
                    <span className="text-align">{EtoCompassDirection[i + 1]}</span>
                    <span className="icon-align">{IconComponent}</span>
                    <span style={{ marginLeft: '1rem', color: 'red', backgroundColor: '#ddd', padding: '0rem 0.5rem 0rem 0.5rem' }}>{tmp_goousatsu}{tmp_anensatsu}</span>
                </li>
                <ul>
                    {angleInfo.slice(i, i + 3)}
                </ul>
            </ul>
        );
    }

    // <canvas id="kyusei_img_years" width={canvasWidth} height={canvasheight}></canvas>

    const mainContents = [
        <div key='maincontentskey'>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{dateY}年の座相表・年盤</h2>
                <div>{PrevNextCalendarLink}</div>
                <Alert severity="info" sx={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
                    一般的な座相表の下部北方位と異なり、上部が北方位表示。枠の都合上、一部の漢字3文字を2文字表示。
                </Alert>
                <table style={{ backgroundColor: canvastablebgcolor, width: '100%', tableLayout: 'fixed' }}>
                    <tbody>
                        <tr>
                            <td style={{ height: '200px' }}>
                                <div style={{
                                    width: '100%',
                                    height: canvastableheight,
                                    overflowY: 'auto',
                                    overflowX: 'auto'
                                }}>
                                    <canvas ref={canvasRef} id="kyusei_img_years" width={canvasWidth} height={canvasheight} style={{ maxWidth: 'none' }}></canvas>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseBanner />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>{dateY}年の座相一覧</h2>
                <div>{PrevNextCalendarLink}</div>
                <div>
                    {groupedAngleInfo}
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseInarticle />
            </div>
            <Box className='content_subbox_txt'>
                <h2 className='content_subtitle'>他ページへのリンク</h2>
                <div style={{ marginLeft: '1rem' }}>
                    <Link
                        component={RouterLink}
                        underline="hover"
                        key="2"
                        color="primary"
                        to={`/calendar/${dateY}`}
                        onClick={handleClick}
                    >
                        {dateY}年の年暦カレンダー
                    </Link>
                </div>
            </Box>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <AdSenseMultiplex />
            </div>
        </div>
    ];

    /*
    <meta name="description" content="This is an example page" />
    <meta property="og:site_name" content="suureki.net">
    <meta property="og:title" content="柿本実さんの情報【suureki.net | すうれきねっと】">
    <meta property="og:description" content="suureki.netとは、1901年から2100年迄を網羅した万年暦・カレンダー・こよみサイトです。様々な暦の他、著名人の情報や生年月日による吉凶運勢・吉方位・バイオリズム・カバラ数秘術の情報を提供しています。">
    <meta property="og:type" content="website">
    <meta property="og:locale" content="ja_JP">
    <meta property="og:url" content="https://suureki.net/75882/register.php?id=17630317">
    <meta property="og:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta property="og:image:width" content="1200">
    <meta property="og:image:height" content="630">
    <meta name="twitter:card" content="summary_large_image">
    <meta name="twitter:url" content="https://suureki.net/">
    <meta name="twitter:image" content="https://suureki.net/img/suureki_ogp_1200x630.png">
    <meta name="twitter:title" content="日々、役立つ暦注を！">
    <meta name="twitter:description" content="万年暦・こよみサイト。二十八宿や十二直、二十四節気/七十二候などの様々な暦注を掲載。">
    <meta name="twitter:site" content="@suureki_cal">
    */

    const pageKey = "Aspect";
    const pagePath = pagePathS[pageKey] + dateY;

    const metaDescription = dateY + pageDescription[pageKey];
    const metaTitle = pageTitle + siteName;
    const metaSitename = siteNameS;
    const metaType = siteType;
    const metaLocale = siteLocale;
    const metaUrl = siteUrlHead + pagePath;
    const metaImg = siteImg;
    const metaImgW = siteImgW;
    const metaImgH = siteImgH;
    const metaTwitterCard = twitterCard;
    const metaTwitterSite = twitterSite;

    // 全文検索ログの書き込み
    useEffect(() => {
        // console.log('暗号化前uid:', userUid);
        // console.log('encryptedUid:', encryptedUid);
        console.log(pagePath + ':', encryptedUid.substring(0, 3));

        if (pagelogWriteUrl && encryptedUid) {

            fetch(pagelogWriteUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ title: pageTitle, url: pagePath, uid: encryptedUid }).toString(),
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('Search log saved:', data);
                })
                .catch(error => {
                    // console.error('Error saving search log:', error);
                });
        }
    }, [pagelogWriteUrl, encryptedUid, pageTitle]);


    // その他のページの場合
    // （記事の場合、headline項目を追加）
    // https://suureki.net/about
    const schemaOrg = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": siteTypePage,                          // トップページはWebSite 、トップページ以外はWebPage
                "@id": metaUrl,                                 // ページURL
                "url": metaUrl,                                 // ページURL
                "isPartOf": { "@id": siteUrlHead },             // トップページURL、https://suureki.net/
                "name": pageTitle,                              // ページタイトル
                "datePublished": datePublished,                 // 公開日
                "dateModified": dateModified[pageKey],          // 更新日
                "description": metaDescription,                 // 説明文
                "image": publisherLogoUrl,                      // 画像
            }
        ]
    };
    // This is an example page

    // <AuthProvider>の直下に
    // <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
    // 

    return (
        <>
            <AuthProvider>
                <EncryptUserUid uid={userUid} onEncrypted={handleEncrypted} />
                <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <div style={{ flex: 1 }}>

                        {/* ***** HTMLヘッダー書換 ******************************************************************* */}
                        <HelmetProvider>
                            <Helmet>
                                <title>{metaTitle}</title>
                                <meta name="robots" content={siteRobots['OK']} />
                                <meta name="description" content={metaDescription} />
                                <meta property="og:site_name" content={metaSitename} />
                                <meta property="og:title" content={metaTitle} />
                                <meta property="og:description" content={metaDescription} />
                                <meta property="og:type" content={metaType} />
                                <meta property="og:locale" content={metaLocale} />
                                <meta property="og:url" content={metaUrl} />
                                <meta property="og:image" content={metaImg} />
                                <meta property="og:image:width" content={metaImgW} />
                                <meta property="og:image:height" content={metaImgH} />
                                <meta name="twitter:card" content={metaTwitterCard} />
                                <meta name="twitter:url" content={metaUrl} />
                                <meta name="twitter:image" content={metaImg} />
                                <meta name="twitter:title" content={metaTitle} />
                                <meta name="twitter:description" content={metaDescription} />
                                <meta name="twitter:site" content={metaTwitterSite} />
                                <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
                            </Helmet>
                        </HelmetProvider>

                        {/* ***** ページ上部スペース・ページ名の表示 ******************************************************************* */}
                        <div className='topspace_height'></div>
                        <h1 className='page_title'>
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </h1>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
                            <DemoContainer components={['MobileDatePicker']}>
                                {searchWindow}
                            </DemoContainer>
                        </LocalizationProvider>

                        {/* ***** メインコンテンツ ******************************************************************* */}

                        <div className='main_space'>
                            <h1 className='content_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>{pageTitle}</span>
                            </h1>
                            <div>{mainContents}</div>
                        </div>
                    </div>

                    {/* ***** フッター ******************************************************************* */}
                    <SuurekiFooter />
                </div>
            </AuthProvider>
        </>
    );
});


const App = () => (
    <AuthProvider>
        <AspectIndex />
    </AuthProvider>
);

export default App;