import { useAuth } from '../contexts/AuthContext';

export const UserProfile = () => {
    const { userInfo, logout } = useAuth();

    if (!userInfo) return null;
    /*
        <div className="p-4">
        <div className="flex items-center space-x-4">
            {userInfo.photoURL && (
                <img
                    src={userInfo.photoURL}
                    alt="Profile"
                    className="w-10 h-10 rounded-full"
                />
            )}
            <div>
                <p className="font-semibold">{userInfo.displayName}</p>
                <p className="text-sm text-gray-600">{userInfo.email}</p>
                <p className="text-xs text-gray-500">ID: {userInfo.uid}</p>
            </div>
        </div>
        <button
            onClick={logout}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
            ログアウト
        </button>
    </div>
    */
    return (
        <div>
            <button
                className='inside-list-style'
                style={{
                    backgroundColor: 'white',
                    color: '#1976d2',
                    padding: '5px 20px',
                    margin: '1rem',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Changed to center the text
                    border: '2px solid #1976d2',
                    whiteSpace: 'nowrap' // Prevent line breaks
                }}
            >
                {userInfo.email}でログイン中
            </button>
            <button
                onClick={logout}
                className='inside-list-style'
                style={{
                    backgroundColor: 'white',
                    color: '#d04a39',
                    padding: '5px 20px',
                    margin: '1rem',
                    height: '40px',
                    width: '160px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Changed to center the text
                    border: '2px solid #d04a39',
                    cursor: 'pointer'
                }}
            >
                ログアウトする
            </button>
        </div>
    );
};
